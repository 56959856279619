<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head" />
      <trashed-message v-if="socialLink.deleted_at" class="mb-6" @restore="restore"> This socialLink has been deleted. </trashed-message>
      <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="update">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.social_title" :error="form.errors.social_title" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Social Link" required="true" placeholder="i.e Facebook"/>
            <text-input v-model="form.url" :error="form.errors.url" class="pb-8 pr-6 w-full lg:w-1/2" label="URL of Social Link" required="true" placeholder="i.e https://www.facebook.com/"/>
            <text-input v-model="form.hierarchy" :error="form.errors.hierarchy" class="pb-8 pr-6 w-full lg:w-1/2" label="Hierarchy" placeholder="i.e 1" type="number" oninput="this.value = Math.abs(this.value)" min="0"/>
            <div class="pb-8 pr-6 w-full lg:w-1/2 grid grid-cols-12">
              <text-input v-model="form.icon" :error="form.errors.icon" class="col-span-11" label="Icon" placeholder="i.e 1" disabled/>
              <vue3-icon-picker v-model="form.icon" class="mt-5"/>
            </div>
            <select-input v-model="form.display" :error="form.errors.display" class="pb-8 pr-6 w-full lg:w-1/2" label="Display" >
              <option value=null >Select Display</option>
              <option value='Icon' >Icon</option>
              <option value='Text' >Text</option>
              <option value='Icon & Text' >Icon & Text</option>
            </select-input>
            <text-input v-model="form.color" :error="form.errors.color" class="pb-8 pr-6 w-full lg:w-1/2" label="Color of Social Link" required="true" placeholder="i.e " type="color"/>
            <text-input v-model="form.size" :error="form.errors.size" class="pb-8 pr-6 w-full lg:w-1/2" label="Size of Social Link" required="true" placeholder="i.e 20px"/>
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Social Link" class="pb-8 pr-6 w-full lg:w-1/2 mt-5"></status-toogle-button>
          </div>

          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
            <Button v-if="permission.edit>0" :type="type" :name="head" :url="createUrl" :loading="form.processing"></Button>
          </div>
        </form>
      </div>
    </div>
  </template>

  <script>
  import axios from 'axios';
  import { ref, toRefs, inject  } from 'vue'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import Icon from '@/Shared/Icon'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import TextareaInput from '@/Shared/TextareaInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import TrashedMessage from '@/Shared/TrashedMessage'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import DeleteIcon from '@/Shared/DeleteIcon'
  import Button from '@/Shared/Button'
  import FileInput from '@/Shared/FileInput'
  import Vue3IconPicker from '@/Shared/components/Vue3IconPicker'
  export default {
    components: {
      Head,
      Icon,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      TrashedMessage,
      Breadcrumbs,
      DeleteIcon,
      StatusToogleButton,
      Button,
      FileInput,
      Vue3IconPicker,
      TextareaInput
    },
    layout: Layout,
    props: {
      socialLink: Object,
      permission: Object,
      types: Array
    },
    remember: 'form',
    setup(props){
      const { socialLink, types, permission } = toRefs(props)
      const type = ref('edit')
      const title = ref(socialLink.value.social_title)
      const head = ref('Social Link')
      const url = ref('/backend/social-links')
      const createUrl = ref('')
      const elm = ref(this)
      const swal = inject('$swal')

      const form = useForm({
        social_title: socialLink.value.social_title,
        url: socialLink.value.url,
        hierarchy: socialLink.value.hierarchy,
        icon: socialLink.value.icon?socialLink.value.icon:'fab fa-facebook',
        display: socialLink.value.display,
        color: socialLink.value.color?socialLink.value.color:'#2a6ff8',
        size: socialLink.value.size,
        status: socialLink.value.status
      })
      const update = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}/${socialLink.value.id}`, {forceFormData: true,})
              title.value = form.social_title
              swal.fire(
                'Updated!',
                'Your infomation has been Updated.',
                'success'
              )
            }
          });
      }
      const destroy = () => {
        swal({
            title: 'Are you sure?',
            text: "Do you really want to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.delete(`${url.value}/${socialLink.value.id}`)
              swal.fire(
                'Deleted!',
                'Your infomation has been deleted.',
                'success'
              )
            }
          });
      }
      const restore = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to restore this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Resore it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${socialLink.value.id}/restore`)
              swal.fire(
                'Restored!',
                'Your infomation has been Restored.',
                'success'
              )
            }
          });
      }
      return {
        form,
        socialLink,
        type,
        title,
        head,
        url,
        createUrl,
        update,
        destroy,
        restore,
        swal,
        permission
      }
    }
  }
  </script>
