<template>
    <div>
        <vue-tags-input v-model="tag" :tags="tag_info" @tags-changed="update" :autocomplete-items="autocompleteItems" />
        <!-- @tags-changed="newTags => tags = newTags"  -->
    </div>
</template>
<script>
import { ref, toRefs, onMounted, watchEffect } from 'vue'
import axios from 'axios';
import VueTagsInput from '@sipec/vue3-tags-input';

export default {
    components: {
        VueTagsInput,
    },
    props: {
        tag_info: Array,
        editvalue: Array
    },
    setup(props, { emit }) {
        const { tag_info, editvalue } = toRefs(props)
        const tag = ref('')
        const tags = ref([])
        const autocompleteItems = ref([])
        const debounce = ref(null)
        const update = (newTags) => {
            autocompleteItems.value = [];
            let data = ref([])
            tags.value = newTags
            data.value = tags._rawValue;
            let newArr = ref([])
            emit('setUpdateTags', newArr.value)
            data.value.forEach(element => {
                newArr.value.push(element)
                emit('setUpdateTags', newArr.value)
            });
        }

        const setvalue = () => {
            tags.value = editvalue.value
        }
        return {
            tag,
            tags,
            tag_info,
            autocompleteItems,
            debounce,
            update,
            editvalue,
            setvalue
        }
    },
    created: function () {
        this.setvalue();
    }
};
</script>
