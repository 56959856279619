<template>
  <div>
    <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>

    <trashed-message v-if="active_employee.deleted_at" class="mb-6" @restore="restore"> This Service Info has been
      deleted.
    </trashed-message>
    <div class="w-full bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8 edit_form_block">
          <text-input v-model="form.first_name" :error="form.errors.first_name" class="pb-8 pr-6 w-full lg:w-1/2"
            label="First Name" required="true" placeholder="i.e John " />
          <text-input v-model="form.middle_name" :error="form.errors.middle_name" class="pb-8 pr-6 w-full lg:w-1/2"
            label="Middle Name" placeholder="i.e Alden " />
          <text-input v-model="form.last_name" :error="form.errors.last_name" class="pb-8 pr-6 w-full lg:w-1/2"
            label="Last Name" required="true" placeholder="i.e Doe" />
          <text-input v-model="form.surfix" :error="form.errors.surfix" class="pb-8 pr-6 w-full lg:w-1/2" label="Suffix"
            placeholder="i.e Jr" />
          <text-input v-model="form.join_date" :error="form.errors.join_date" class="pb-8 pr-6 w-full lg:w-1/2"
            type="date" label="Join Date" required="true" placeholder="i.e 01-01-2023" />
          <text-input v-model="form.discontinue_date" :error="form.errors.discontinue_date" type="date"
            class="pb-8 pr-6 w-full lg:w-1/2" label="Discontinue Date" placeholder="i.e 31-12-2023" />
          <select-input v-model="form.office_layer_id" :error="form.errors.office_layer_id"
            class="pb-8 pr-6 w-full lg:w-1/2" label="Choose Office
          Layer" required="true">
            <option value=''>Choose One</option>
            <option v-for="office_layer in officelayers" :value="office_layer.id">{{
              office_layer.office_layer_name }}
            </option>
          </select-input>
          <template v-if="form.office_layer_id == 1">
            <select-input v-model="form.ministry_id" :error="form.errors.ministry_id" class="pb-8 pr-6 w-full lg:w-1/2"
              label="Choose Ministry" required="true">
              <option value=''>Choose One</option>
              <option v-for="ministry in ministries" :value="ministry.id">{{
                ministry.ministry_name }}
              </option>
            </select-input>
          </template>
          <template v-if="form.office_layer_id > 1">
            <select-input-function v-model="form.region_id" :error="form.errors.region_id"
              class="pb-8 pr-6 w-full lg:w-1/2" label="Choose Region" :action="load_province" required="true">
              <option value=''>Choose One</option>
              <option v-for="region in regions" :value="region.id">{{ region.region_name }}
              </option>
            </select-input-function>
          </template>
          <template v-if="form.office_layer_id > 2">
            <select-input-function v-model="form.province_id" :error="form.errors.province_id"
              class="pb-8 pr-6 w-full lg:w-1/2" label="Choose Province" :action="load_municipality" required="true">
              <option value=''>Choose One</option>
              <option v-for="province in pre_province_list" :value="province.id">{{
                province.provience_name }}</option>
            </select-input-function>
          </template>
          <template v-if="form.office_layer_id > 3">
            <select-input-function v-model="form.municipality_id" :error="form.errors.municipality_id"
              class="pb-8 pr-6 w-full lg:w-1/2" label="Choose Municipality" :action="load_barangay" required="true">
              <option value=''>Choose One</option>
              <option v-for="municipality in pre_municipality_list" :value="municipality.id">{{
                municipality.municipality_name }}</option>
            </select-input-function>
          </template>
          <template v-if="form.office_layer_id > 4">
            <select-input v-model="form.barangay_id" :error="form.errors.barangay_id" class="pb-8 pr-6 w-full lg:w-1/2"
              label="Choose Barangay" required="true">
              <option value=''>Choose One</option>
              <option v-for="barangay in pre_barangay_list" :value="barangay.id">{{
                barangay.barangay_name }}</option>
            </select-input>
          </template>
          <select-input v-model="form.designation_id" :error="form.errors.designation_id"
            class="pb-8 pr-6 w-full lg:w-1/2" label="Choose Designation" required="true">
            <option value=''>Choose One</option>
            <option v-for="designation in designations" :value='designation.id'>{{ designation.designation_name }}
            </option>
          </select-input>
          <status-toogle-button v-model="form.status" :error="form.errors.status" label="Employee Status &nbsp;&nbsp;&nbsp;&nbsp;"
            class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button>
        </div>
        <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
          <button v-if="!active_employee.deleted_at && permission.delete > 0" class="text-red-600 hover:underline"
            tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
          <Button v-if="permission.edit > 0" :type="type" :name="head" :url="createUrl"
            :loading="form.processing"></Button>
        </div>
        <!-- <pre>{{ form }}</pre> -->

      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject, onMounted, watch } from 'vue'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import SelectInputFunction from '@/Shared/SelectInputFunction'
import LoadingButton from '@/Shared/LoadingButton'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import ContentHeaderSection from "@/Shared/ContentHeaderSection"
import InputTag from '@/Shared/InputTag'
import DeleteIcon from '@/Shared/DeleteIcon'
import Button from '@/Shared/Button'
import FileInput from '@/Shared/FileInput'
export default {
  components: {
    Head,
    Link,
    LoadingButton,
    SelectInput,
    TextInput,
    Breadcrumbs,
    StatusToogleButton,
    Button,
    ContentHeaderSection,
    FileInput,
    DeleteIcon,
    SelectInputFunction
  },
  layout: Layout,
  props: {
    designations: Array,
    regions: Array,
    officelayers: Array,
    ministries: Array,
    permission: Object,
    active_employee: Object
  },
  setup(props) {
    const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
    const { designations, regions, officelayers, ministries, permission, active_employee } = toRefs(props)
    const type = ref('edit')
    const title = ref(active_employee.value.first_name)
    const head = ref('Employee')
    const url = ref('/backend/active-employees')
    const createUrl = ref('')
    const elm = ref(this)
    const swal = inject('$swal')
    const form = useForm({
      id: active_employee.value.id,
      first_name: active_employee.value.first_name,
      middle_name: active_employee.value.middle_name,
      last_name: active_employee.value.last_name,
      surfix: active_employee.value.surfix,
      join_date: active_employee.value.join_date,
      discontinue_date: active_employee.value.discontinue_date,
      designation_id: active_employee.value.designation_id,
      office_layer_id: active_employee.value.office_layer_id,
      ministry_id: active_employee.value.ministry_id,
      region_id: active_employee.value.region_id,
      province_id: active_employee.value.province_id,
      municipality_id: active_employee.value.municipality_id,
      barangay_id: active_employee.value.barangay_id,
      status: active_employee.value.status
    })
    const update = () => {
      swal({
        title: 'Are you sure?',
        text: "You want to proceed with this action",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          form.post(`${url.value}/${active_employee.value.id}`, { forceFormData: true, })
          title.value = form.employee_name
          swal.fire(
            'Updated!',
            'Your infomation has been Updated.',
            'success'
          )
        }
      });
    }

    const destroy = () => {
      swal({
        title: 'Are you sure?',
        text: "Do you really want to delete this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          form.delete(`${url.value}/${active_employee.value.id}`)
          swal.fire(
            'Deleted!',
            'Your infomation has been deleted.',
            'success'
          )
        }
      });
    }
    const restore = () => {
      swal({
        title: 'Are you sure?',
        text: "You want to restore this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Resore it!'
      }).then((result) => {
        if (result.isConfirmed) {
          form.put(`${url.value}/${active_employee.value.id}/restore`)
          swal.fire(
            'Restored!',
            'Your infomation has been Restored.',
            'success'
          )
        }
      });
    }


    const pre_province_list = ref([])

    const load_province = async (req_for=1) => {
      //console.log('Load Pro',req_for);
      let data = {
        geo_region_id: req_for == 2 ? form.region_id : form.region_id
      }
      await axios.post(`/${backendPrefix.value}/provinces/activeprovinces`, data)
        .then(function (response) {
          pre_province_list.value = response.data
          if (req_for != 2) {
           // form.province_id = ''
           // form.municipality_id = ''
           // form.barangay_id = ''
            if (form.province_id) load_municipality()
          } else {
            if (form.province_id) load_municipality(2)
          }
        }.bind(this));
    }
    const pre_municipality_list = ref([])
    const load_municipality = async (req_for=1) => {
      let data = {
        geo_provience_id: req_for == 2 ? form.province_id : form.province_id
      }
      await axios.post(`/${backendPrefix.value}/municipalities/activemunicipalities`, data)
        .then(function (response) {
         // form.municipality_id = ''
         // form.barangay_id = ''
          pre_municipality_list.value = response.data
          if (form.municipality_id) load_barangay(2)
        }.bind(this));
    }

    const pre_barangay_list = ref([])
    const load_barangay = async (req_for=1) => {
      let data = {
        geo_municipality_id: req_for == 2 ? form.municipality_id : form.municipality_id
      }
      await axios.post(`/${backendPrefix.value}/barangays/activebarangays`, data)
        .then(function (response) {
         // form.barangay_id = ''
          pre_barangay_list.value = response.data
        }.bind(this));
    }
    onMounted(() => {
      load_province(2)
      load_municipality(2)
      load_barangay(2)
    })
    return {
      active_employee, designations, regions, officelayers, ministries, pre_province_list, load_province, pre_municipality_list, load_municipality, pre_barangay_list, load_barangay,
      form,
      type,
      title,
      head,
      url,
      createUrl,
      update,
      destroy,
      restore,
      swal,
      permission
    }
  },
}
</script>
<style lang="scss" scoped>
$button_height: 36;

.edit_form_block {
  background-color: #ffffff80;

  textarea,
  select {
    width: 100%;
    border: 1px solid #ddd;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .item_block:hover>div i {
    display: flex !important;
  }

  i {
    width: 30px;
    text-align: center;
    transition: all 0.4s;
    position: absolute;
    margin-right: 0px !important;
    top: 15px;
    font-size: 10px;
    display: none;
    cursor: pointer;

    &.active {
      display: flex !important;
    }

    &.del {
      position: absolute;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border-radius: 50%;
      box-shadow: 0 0 5px #999;
      border: 1px solid #ddd;
      cursor: pointer;
      top: -5px;
      right: -5px;
    }
  }

  .err_msg {
    border-color: #CD0000 !important
  }

  .c-list {
    background: #666;
    color: #fff;
    border-radius: 15px;
    font-size: 11px;
    max-height: 24px;
  }
}
</style>
