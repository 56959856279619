<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
      <div class="flex justify-start mb-8 max-w-3xl">
        <img v-if="ministries.logo" class="block ml-4 w-8 h-8 rounded-full" :src="ministries.logo" />
      </div>
      <trashed-message v-if="ministries.deleted_at" class="mb-6" @restore="restore"> This Region has been deleted. </trashed-message>
      <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="update">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.ministry_name" :error="form.errors.ministry_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Ministry" required="true" placeholder="i.e Ministry of Interior and local Ministry of Interior and Local Governess" />
            <text-input v-model="form.name_eng_short" :error="form.errors.name_eng_short" class="pb-8 pr-6 w-full lg:w-1/2" label="Short Name" placeholder="i.e MILG" />
            <text-input v-model="form.reference_code" :error="form.errors.reference_code" class="pb-8 pr-6 w-full lg:w-1/2" label=" Code" placeholder="i.e 1001"/>
            <file-input v-model="form.logo" :error="form.errors.logo" class="pb-8 pr-6 w-full lg:w-1/2" type="file" accept="image/*" label="Logo" />
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Ministry" class="pb-8 mt-8 pr-6 w-full lg:w-1/2" :checked="statusCheck"></status-toogle-button>
          </div>
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
            <button v-if="!ministries.deleted_at && permission.edit>0" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
            <Button v-if="permission.edit>0" :type="type" :name="head" :url="createUrl" :loading="form.processing"></Button>
          </div>
        </form>
      </div>
    </div>
  </template>

  <script>
  import { ref, toRefs, inject } from 'vue'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import Icon from '@/Shared/Icon'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import TrashedMessage from '@/Shared/TrashedMessage'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import DeleteIcon from '@/Shared/DeleteIcon'
  import SelectInputFunction from '@/Shared/SelectInputFunction'
  import Button from '@/Shared/Button'
  import FileInput from '@/Shared/FileInput'
  export default {
    components: {
      Head,
      Icon,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      TrashedMessage,
      StatusToogleButton,
      Breadcrumbs,
      DeleteIcon,
      SelectInputFunction,
      Button,
      FileInput
    },
    layout: Layout,
    props: {
      ministries: Object,
      permission: Object,
    },
    remember: 'form',
    setup(props){
      const { ministries, permission} = toRefs(props)
      const type = ref('edit')
      const title = ref(ministries.value.ministry_name)
      const head = ref('Ministry')
      const url = ref('/backend/ministries')
      const createUrl = ref('')
      const swal = inject('$swal')
      const elm = ref(this)
      const form = useForm({
        ministry_name: ministries.value.ministry_name,
        name_eng_short: ministries.value.name_eng_short,
        reference_code: ministries.value.reference_code,
        status: ministries.value.status,
        logo:null,
      })
      const update = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}/${ministries.value.id}`, {forceFormData: true,})
              title.value = form.ministry_name
              swal.fire(
                'Updated!',
                'Your infomation has been Updated.',
                'success'
              )
            }
          });
      }
      const destroy = () => {
        swal({
            title: 'Are you sure?',
            text: "Do you really want to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.delete(`${url.value}/${ministries.value.id}`)
              swal.fire(
                'Deleted!',
                'Your infomation has been deleted.',
                'success'
              )
            }
          });
      }
      const restore = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to restore this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Resore it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${ministries.value.id}/restore`)
              swal.fire(
                'Restored!',
                'Your infomation has been Restored.',
                'success'
              )
            }
          });
      }
      return {
        form,
        ministries,
        type,
        title,
        head,
        url,
        createUrl,
        update,
        destroy,
        restore,
        swal,
        permission
      }
    }
  }
  </script>
