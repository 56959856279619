<template>
    <div class="file_upload_block">
        <div :class="['upload_block_area', { preview_mode: preview_mode }]">
            <p>Upload maximum file size <em>{{ max_file_size }} MB</em> <sup>*</sup></p>
            <div v-if="file_size_exceeded"
                class="flex flex-row items-center gap-2 text-red-700 px-3 py-2 my-3 bg-red-50 rounded-md err_msg">
                <i class="fa fa-check-circle"></i>
                <span>File size exceeded</span>
            </div>
            <div v-else-if="file_type_invalid"
                class="flex flex-row items-center gap-2 text-red-700 px-3 py-1 mt-2 bg-red-100 rounded-md err_msg">
                <i class="fa fa-check-circle"></i>
                <span>Only allowed PDF, JPG, JPEG, PNG</span>
            </div>
            <div class="upload_box mt-4" id="drop_area">
                <div v-if="formData.upload_file_info" class="content_preview_area">
                    <embed v-if="formData.file_type == 'application/pdf'" :src="formData.upload_file_info" width="100%"
                        height="280" type="application/pdf" />
                    <img v-else :src="formData.upload_file_info" />
                    <span class="change_btn" @click="upload_photo" tabindex="0" role="button">Change</span>
                </div>
                <div v-else class="browse_area" @click="upload_photo">
                    <div class="icon"><i class="fa fa-arrow-up"></i></div>
                    <p class="my-3">Drag file to upload or choose</p>
                    <p><span>Choose</span></p>
                </div>
                <div class="drag_here">
                    <div class="flex items-center justify-center font-bold text-green-600 mb-3">Drop Here</div>
                </div>
                <input type="file" ref="file_input_form" class="hidden" @change="load_file"
                    accept="application/pdf,image/jpg,image/jpeg,image/png" />
            </div>
            <div class="flex flex-row items-center gap-3 mt-4 file_name_block">
                <label>File Name <sup>*</sup></label>
                <input class="text-[12px]" type="text" v-model="formData.file_name" placeholder="e.g. file_name" />
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue'
import PhotoPopupBlock from '@/Shared/popup/PhotoPopup'
const props = defineProps({
    getPhoto: String,
    index: String
})
const emit = defineEmits(['SetPhoto'])
const max_file_size = 25
const preloader = ref(false)
const formData = ref({
    file_name: '',
    file_type: '',
    file_size: '',
    upload_file_info: ''
})
const file_size_exceeded = ref(false)
const allow_file_ext = ref(['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'])
const file_type_invalid = ref(false)
const submit_btn_allow = computed(async () => formData.value.upload_file_info ? true : false)
const file_input_form = ref('')

const dropArea = ref('')
onMounted(() => {
    dropArea.value = document.getElementById('drop_area')
        ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            dropArea.value.addEventListener(eventName, preventDefaults, false)
        })
        ;['dragenter', 'dragover'].forEach(eventName => {
            dropArea.value.addEventListener(eventName, highlight, false)
        })

        ;['dragleave', 'drop'].forEach(eventName => {
            dropArea.value.addEventListener(eventName, unhighlight, false)
        })

    dropArea.value.addEventListener('drop', load_file, false)
    if (props.getPhoto) {
        formData.value.upload_file_info = props.getPhoto;
        let name = props.getPhoto.split("/");

        formData.value.file_name = name[name.length - 1];
        get_file_name_onload();
    }

    // get_file_name(props.getPhoto)
})
const preventDefaults = (e) => {
    e.preventDefault()
    e.stopPropagation()
}
const highlight = () => {
    dropArea.value.classList.add('highlight')
}
const unhighlight = () => {
    dropArea.value.classList.remove('highlight')
}
const upload_photo = () => {
    file_input_form.value.click();
}
const reset_form = () => {
    file_size_exceeded.value = false
    file_type_invalid.value = false
    formData.value = {
        file_name: '',
        file_type: '',
        file_size: '',
        upload_file_info: ''
    }
}
const load_file = (e) => {

    reset_form()

    var files = e.target.files || e.dataTransfer.files
    if (!files.length) return
    // console.log('File info', files[0])

    // File type checking
    if (allow_file_ext.value.indexOf(files[0].type) == -1) {
        file_type_invalid.value = true
        return false
    }

    formData.value.file_size = files[0].size;
    if (formData.value.file_size / 1024 > max_file_size.value * 1024) {
        file_size_exceeded.value = true
        return false
    }
    get_file_name(files[0].name)
    get_file_type(files[0].type)
    CreateFile(files[0])
}
const get_file_type = (type) => {
    formData.value.file_type = type
}
const get_file_name = (file) => {
    formData.value.file_name = file.split('.').slice(0, -1).join('.')
}
const get_file_name_onload = (file) => {
    let name = formData.value.file_name.split(".");
    if (name[name.length - 1] == 'pdf') {
        formData.value.file_type = 'application/pdf'
        formData.value.file_name = name[0];
    }

}
const CreateFile = (file) => {
    // formData.value.upload_file_info = URL.createObjectURL(file)
    var reader = new FileReader();

    reader.onload = (e) => {
        formData.value.upload_file_info = e.target.result
        emit('SetPhoto', e.target.result, props.index)
    }

    reader.readAsDataURL(file)
}

</script>
<style lang="scss" scoped>
.file_upload_block {
    border: 1px solid #eee;
    box-shadow: 2px 2px 3px #ccc;
    padding: 20px;

    .upload_block_area {
        &.preview_mode {
            box-shadow: none;

            .upload_file_info {
                padding: 15px;
                height: 250px;
                border: 5px dashed #efefef;
                border-radius: 5px;

                &>img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .govt_issue_id_type_list {
            label {
                display: block;
                color: #999;
                margin-bottom: 5px;
            }
        }

        &>p {
            font-size: 13px;

            &>em {
                font-size: 14px;
                color: #CD0000
            }
        }

        .upload_box {
            display: block;
            position: relative;
            padding: 20px;
            border: 2px dashed #ddd;
            border-radius: 5px;
            cursor: pointer;

            &>.drag_here {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: #ffffff;
                opacity: 0;
                z-index: 1;
                transition: all 0.6s;

                &>div {
                    position: absolute;
                    height: 60px;
                    width: 100%;
                }

                &>img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    padding: 15%;
                    opacity: 0.2;
                }
            }

            &.highlight {
                border-color: rgb(24, 158, 0);

                &>.drag_here {
                    opacity: 1.0;
                }
            }

            &>.content_preview_area {
                position: relative;
                // padding: 20px;
                // border: 1px dashed #ddd;
                text-align: center;
                border-radius: 5px;

                &>img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                &>.change_btn {
                    position: absolute;
                    display: inline-block;
                    background-color: #09986780;
                    color: #fff;
                    font-size: 12px;
                    padding: 3px 20px;
                    border-radius: 25px;
                    cursor: pointer;
                    transition: all 0.4s;
                    bottom: 20px;
                    left: 35%;
                    z-index: 5;

                    &:hover {
                        background-color: #099867;
                    }
                }
            }

            &>.browse_area {
                position: relative;
                padding: 20px;
                border: 1px dashed #ddd;
                text-align: center;
                border-radius: 5px;
                z-index: 5;

                &>.icon {
                    display: inline-block;
                    width: 80px;
                    height: 80px;
                    line-height: 80px;
                    text-align: center;
                    color: #099867;
                    font-size: 26px;
                    border: 1px solid #ddd;
                    border-radius: 50%;
                }

                &>p>span {
                    font-size: 14px;
                    color: #099867
                }
            }
        }

        .file_name_block {
            label {
                width: 100px
            }
        }
    }

    input,
    select {
        width: 100%;
        height: 26px;
        padding: 2px 10px;
        border-radius: 5px;
        border: 1px solid #ddd;
        outline: none;
    }

    .submit-btn {
        width: 100%;
        height: 50px;
        font-weight: 400;
        padding: 0 25px;
        background-color: #019966;
        font-size: 12px;
        color: #fff;
        opacity: 0.4;
        border-radius: 3px;
        transition: all 0.4s;

        &.active {
            opacity: 1.0;
        }
    }
}
</style>
