<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
        <trashed-message v-if="contents.deleted_at" class="mb-6" @restore="restore"> This Content has been deleted.
        </trashed-message>
        <div class="flex justify-start mb-8 max-w-3xl">
            <img v-if="contents.photo" class="block ml-4 w-8 h-8 rounded-full" :src="contents.photo" />
        </div>
        <div class="max-w-5xl bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="update">
                <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                    <text-input v-model="form.title" :error="form.errors.title" class="pb-8 pr-6 w-full lg:w-1/1"
                        @keyup="slugBuild" label="Content Headline" required="true"
                        placeholder="i.e BANGSAMORO BUDGET FORUM F.Y. 2023" />
                    <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Slug" required="true" placeholder="i.e bangsamoro-budget-forum" />
                    <text-input v-model="form.video_url" :error="form.errors.video_url"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Video Url" required="true" placeholder="i.e " />
                    <div class="pb-8 pr-6 w-full lg:w-1/1">
                        <label class="form-label" for="details">Description</label>
                        <vue-editor v-model="form.details" />
                    </div>

                    <file-input v-model="form.photo" :error="form.errors.photo" class="pb-8 pr-6 w-full lg:w-1/1"
                        type="file" accept="image/*" label="Photo" />
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Content Status"
                        class="pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>
                </div>
                <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
                    <button v-if="!contents.deleted_at && permission.edit > 0" class="text-red-600 hover:underline"
                        tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
                    <Button v-if="permission.edit > 0" :type="type" :name="head" :url="createUrl"
                        :loading="form.processing"></Button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from '@/Shared/Breadcrumbs'
import Button from '@/Shared/Button'
import DeleteIcon from '@/Shared/DeleteIcon'
import FileInput from '@/Shared/FileInput'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import TextareaInput from '@/Shared/TextareaInput'
import TextInput from '@/Shared/TextInput'
import TrashedMessage from '@/Shared/TrashedMessage'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
import { Quill, VueEditor } from "vue3-editor"
export default {
    components: {
        Head,
        Icon,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        TrashedMessage,
        StatusToogleButton,
        Breadcrumbs,
        DeleteIcon,
        Button,
        TextareaInput,
        Quill,
        VueEditor,
        FileInput
    },
    layout: Layout,
    props: {
        contents: Object,
        permission: Object,
    },
    remember: 'form',
    setup(props) {
        const { contents, permission } = toRefs(props)
        const type = ref('edit')
        const title = ref(contents.value.title)
        const head = ref('Content')
        const url = ref('/backend/queue-contents')
        const createUrl = ref('')
        const elm = ref(this)
        const swal = inject('$swal')
        const form = useForm({
            title: contents.value.title,
            slug: contents.value.slug,
            details: contents.value.details,
            video_url: contents.value.video_url,
            status: contents.value.status,
            photo: null,
        })
        const update = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.post(`${url.value}/${contents.value.id}`, { forceFormData: true, })
                    title.value = form.title
                    swal.fire(
                        'Updated!',
                        'Your infomation has been Updated.',
                        'success'
                    )
                }
            });
        }
        const destroy = () => {
            swal({
                title: 'Are you sure?',
                text: "Do you really want to delete this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.delete(`${url.value}/${contents.value.id}`)
                    swal.fire(
                        'Deleted!',
                        'Your infomation has been deleted.',
                        'success'
                    )
                }
            });
        }
        const restore = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to restore this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Resore it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${contents.value.id}/restore`)
                    swal.fire(
                        'Restored!',
                        'Your infomation has been Restored.',
                        'success'
                    )
                }
            });
        }
        const statusCheck = () => {
            if (contents.value.status == 1) {
                form.status = true;
                return true;
            } else {
                form.status = false;
                return false;
            }
        }
        const slugBuild = () => {
            form.slug = form.title.replace(/\s/g, '-').toLowerCase()
        }
        return {
            form,
            contents,
            type,
            title,
            head,
            url,
            createUrl,
            update,
            destroy,
            restore,
            statusCheck,
            slugBuild,
            swal,
            permission
        }
    },
    created: function () {
        this.statusCheck();
    }

}
</script>
