<template>
  <div>
    <Breadcrumbs :type="type" :header="title" :url="url" />
    <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="store">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <text-input v-model="form.lang_title" :error="form.errors.lang_title" class="pb-8 pr-6 w-full lg:w-1/2"
            label="Name of Host Language" required="true" placeholder="i.e English" />
          <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/2"
            label="Name of Language" required="true" placeholder="i.e english" />
          <text-input v-model="form.hierarchy" :error="form.errors.hierarchy" class="pb-8 pr-6 w-full lg:w-1/2"
            label="Hierarchy" placeholder="i.e 1" type="number" oninput="this.value = Math.abs(this.value)" min="0" />
          <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Host Language"
            class="pb-8 pr-6 w-full lg:w-1/2 mt-6"></status-toogle-button>
        </div>
        <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
      </form>
    </div>
  </div>

</template>

<script>
import axios from 'axios';
import { ref, toRefs, inject } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Button from '@/Shared/Button'
import FileInput from '@/Shared/FileInput'
export default {
  props: {

  },
  components: {
    Head,
    Link,
    LoadingButton,
    SelectInput,
    TextInput,
    Breadcrumbs,
    StatusToogleButton,
    Button,
    FileInput
  },
  layout: Layout,
  remember: 'form',
  setup(props) {
    const form = useForm({
      lang_title: null,
      slug: null,
      hierarchy: null,
      status: true
    })
    // const regions = ref([])
    const type = ref('create')
    const title = ref('Host Language')
    const url = ref('/backend/host-languages')
    const createUrl = ref('')
    const swal = inject('$swal')
    const store = () => {
      swal({
        title: 'Are you sure?',
        text: "You want to proceed with this action",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          form.post(`${url.value}`, { forceFormData: true, })
          swal.fire(
            'Added!',
            'Your infomation has been Added.',
            'success'
          )
        }
      });

    }
    return {
      form,
      type,
      title,
      url,
      createUrl,
      store
    }
  }
}
</script>
