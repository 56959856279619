<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head" />
      <div class="flex justify-start mb-8 max-w-3xl">
        <img v-if="region.logo" class="block ml-4 w-8 h-8 rounded-full" :src="region.logo" />
      </div>
      <trashed-message v-if="region.deleted_at" class="mb-6" @restore="restore"> This Region has been deleted. </trashed-message>
      <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="update">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.region_name" :error="form.errors.region_name" class="pb-8 pr-6 w-full lg:w-1/1" label="Name of Region"  required="true" placeholder="i.e BARMM"/>
            <text-input v-model="form.code" :error="form.errors.code" class="pb-8 pr-6 w-full lg:w-1/1" label="Code of Region"  placeholder="i.e 1001" />
            <file-input v-model="form.logo" :error="form.errors.logo" class="pb-8 pr-6 w-full lg:w-1/1" type="file" accept="image/*" label="Logo" />
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Region" class="pb-8 pr-6 w-full lg:w-1/1" :checked="statusCheck"></status-toogle-button>
          </div>
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
            <!-- <button v-if="!region.deleted_at" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button> -->
            <loading-button :loading="form.processing" class="btn-indigo ml-auto" type="submit"><i class="fa fa-check-circle"></i> Update Region</loading-button>
          </div>
        </form>
      </div>
    </div>
  </template>

  <script>
  import axios from 'axios';
  import { ref, toRefs, inject } from 'vue'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import Icon from '@/Shared/Icon'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import TrashedMessage from '@/Shared/TrashedMessage'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import DeleteIcon from '@/Shared/DeleteIcon'

  import VueSweetalert2 from 'vue-sweetalert2';
  import FileInput from '@/Shared/FileInput'
  export default {
    components: {
      Head,
      Icon,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      TrashedMessage,
      StatusToogleButton,
      Breadcrumbs,
      DeleteIcon,
      FileInput,
      VueSweetalert2
    },
    layout: Layout,
    props: {
      region: Object,
    },
    remember: 'form',
    setup(props){
      const { region } = toRefs(props)
      // const regions = ref([])
      const type = ref('edit')
      const title = ref(region.value.region_name)
      const head = ref('Region')
      const url = ref('/backend/regions')
      const createUrl = ref('')
      const elm = ref(this)
      const swal = inject('$swal')
      const form = useForm({
        region_name: region.value.region_name,
        geo_region_id: region.value.geo_region_id,
        code: region.value.code,
        status: region.value.status,
        logo:null,
      })
      const update = () => {
        swal({
            title: 'Are you sure  ?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}/${region.value.id}`, {forceFormData: true,})
              title.value = form.region_name
              swal.fire(
                'Updated!',
                'Your infomation has been Updated.',
                'success'
              )
            }
          });
      }
      const destroy = () => {
        swal({
            title: 'Are you sure?',
            text: "Do you really want to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.delete(`${url.value}/${region.value.id}`)
              swal.fire(
                'Deleted!',
                'Your infomation has been deleted.',
                'success'
              )
            }
          });
      }
      const restore = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to restore this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Resore it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${region.value.id}/restore`)
              swal.fire(
                'Restored!',
                'Your infomation has been Restored.',
                'success'
              )
            }
          });
      }
      return {
        form,
        type,
        title,
        head,
        url,
        createUrl,
        update,
        destroy,
        restore
      }
    }
  }
  </script>
