<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
      <trashed-message v-if="news.deleted_at" class="mb-6" @restore="restore"> This News has been deleted. </trashed-message>
      <div class="flex justify-start mb-8 max-w-3xl">
        <img v-if="news.photo" class="block ml-4 w-8 h-8 rounded-full" :src="news.photo" />
      </div>
      <div class="max-w-5xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="update">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.title" :error="form.errors.title" class="pb-8 pr-6 w-full lg:w-1/1" @keyup="slugBuild" label="News Headline" required="true" placeholder="i.e BANGSAMORO BUDGET FORUM F.Y. 2023"/>
            <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/2" label="Slug" required="true" placeholder="i.e bangsamoro-budget-forum"/>
            <select-input v-model="form.cat_id" :error="form.errors.cat_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Category" required="true">
              <option :value=null>Select Category</option>
              <option v-for="newsC in newsCat" :key="newsC.id" :value='newsC.id'>{{ newsC.title }}</option>
            </select-input>
            <select-input v-model="form.on_headline" :error="form.errors.on_headline" class="pb-8 pr-6 w-full lg:w-1/2" label="Show in headline">
              <option value=0>No</option>
              <option value=1>Yes</option>
            </select-input>
            <status-toogle-button v-model="form.is_external" :error="form.errors.is_external" label="External Link" class="pb-8 pr-6 mt-7 w-full lg:w-1/2"></status-toogle-button>
            <text-input v-if="form.is_external==1" v-model="form.external_url" :error="form.errors.external_url" class="pb-8 pr-6 w-full lg:w-1/1" label="External Link" placeholder="i.e https://bangsamoro.gov.ph/news/latest-news/barmm-provides-p150-k-aid-to-100-cooperatives"/>
            <div class="pb-8 pr-6 w-full lg:w-1/1" v-if="form.is_external==0">
              <label class="form-label" for="news_dtl">Description</label>
              <vue-editor v-model="form.news_dtl" />
            </div>
            <file-input v-if="form.is_external==0" v-model="form.photo" :error="form.errors.photo" class="pb-8 pr-6 w-full lg:w-1/1" type="file" accept="image/*" label="Photo" />
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="News Status" class="pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>
          </div>
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
            <button v-if="!news.deleted_at && permission.edit>0" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
            <Button v-if="permission.edit>0" :type="type" :name="head" :url="createUrl" :loading="form.processing"></Button>
          </div>
        </form>
      </div>
    </div>
  </template>

  <script>
  import Breadcrumbs from '@/Shared/Breadcrumbs'
import Button from '@/Shared/Button'
import DeleteIcon from '@/Shared/DeleteIcon'
import FileInput from '@/Shared/FileInput'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import TextareaInput from '@/Shared/TextareaInput'
import TextInput from '@/Shared/TextInput'
import TrashedMessage from '@/Shared/TrashedMessage'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
import { Quill, VueEditor } from "vue3-editor"
  export default {
    components: {
      Head,
      Icon,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      TrashedMessage,
      StatusToogleButton,
      Breadcrumbs,
      DeleteIcon,
      Button,
      TextareaInput,
      Quill,
      VueEditor,
      FileInput
    },
    layout: Layout,
    props: {
      news: Object,
      newsCat: Array,
      permission: Object,
    },
    remember: 'form',
    setup(props){
      const { news,newsCat,permission} = toRefs(props)
      const type = ref('edit')
      const title = ref(news.value.title)
      const head = ref('News')
      const url = ref('/backend/newses')
      const createUrl = ref('')
      const elm = ref(this)
      const swal = inject('$swal')
      const form = useForm({
        title:news.value.title,
        slug:news.value.slug,
        news_dtl: news.value.news_dtl,
        is_external: news.value.is_external,
        on_headline:news.value.on_headline,
        cat_id:news.value.cat_id,
        external_url:news.value.external_url,
        status: news.value.status,
        photo: null,
      })
      const update = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}/${news.value.id}`, {forceFormData: true,})
              title.value = form.title
              swal.fire(
                'Updated!',
                'Your infomation has been Updated.',
                'success'
              )
            }
          });
      }
      const destroy = () => {
        swal({
            title: 'Are you sure?',
            text: "Do you really want to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.delete(`${url.value}/${news.value.id}`)
              swal.fire(
                'Deleted!',
                'Your infomation has been deleted.',
                'success'
              )
            }
          });
      }
      const restore = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to restore this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Resore it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${news.value.id}/restore`)
              swal.fire(
                'Restored!',
                'Your infomation has been Restored.',
                'success'
              )
            }
          });
      }
      const statusCheck= () => {
        if(news.value.status==1){
          form.status=true;
          return true;
        }else{
          form.status=false;
          return false;
        }
      }
      const slugBuild = () => {
            form.slug = form.title.replace(/\s/g,'-').toLowerCase()
        }
      return {
        form,
        news,
        type,
        title,
        head,
        url,
        createUrl,
        update,
        destroy,
        restore,
        statusCheck,
        slugBuild,
        swal,
        permission
      }
    },
    created: function(){
      this.statusCheck();
    }

  }
  </script>
