<template>
  <div>
    <Breadcrumbs :type="type" :header="title" :url="url" :head="head" />
    <div class="flex justify-start mb-8 max-w-3xl">
      <img v-if="language.image_path" class="block ml-4 w-8 h-8 rounded-full" :src="language.logo" />
    </div>
    <trashed-message v-if="language.deleted_at" class="mb-6" @restore="restore"> This Language has been deleted.
    </trashed-message>
    <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <text-input v-model="form.lang_title" :error="form.errors.lang_title" class="pb-8 pr-6 w-full lg:w-1/2"
            label="Name of Host Language" required="true" placeholder="i.e English" />
          <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/2"
            label="Name of english" required="true" placeholder="i.e english" />
          <text-input v-model="form.hierarchy" :error="form.errors.hierarchy" class="pb-8 pr-6 w-full lg:w-1/2"
            label="Hierarchy" placeholder="i.e 1" type="number" oninput="this.value = Math.abs(this.value)" min="0" />
          <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Host Language"
            class="pb-8 pr-6 w-full lg:w-1/2 mt-6"></status-toogle-button>
        </div>
        <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
          <!-- <button v-if="!language.deleted_at && permission.delete>0" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button> -->
          <Button v-if="permission.edit > 0" :type="type" :name="head" :url="createUrl"
            :loading="form.processing"></Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, toRefs, inject } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import DeleteIcon from '@/Shared/DeleteIcon'
import Button from '@/Shared/Button'
import FileInput from '@/Shared/FileInput'
export default {
  components: {
    Head,
    Icon,
    Link,
    LoadingButton,
    SelectInput,
    TextInput,
    TrashedMessage,
    Breadcrumbs,
    DeleteIcon,
    StatusToogleButton,
    Button,
    FileInput
  },
  layout: Layout,
  props: {
    language: Object,
    permission: Object,

  },
  remember: 'form',
  setup(props) {
    const { language, permission } = toRefs(props)
    const type = ref('edit')
    const title = ref(language.value.lang_title)
    const head = ref('Language')
    const url = ref('/backend/host-languages')
    const createUrl = ref('')
    const elm = ref(this)
    const swal = inject('$swal')
    const form = useForm({
      lang_title: language.value.lang_title,
      slug: language.value.slug,
      status: language.value.status,
      hierarchy: language.value.hierarchy,
      image_path: null,
    })
    const update = () => {
      swal({
        title: 'Are you sure?',
        text: "You want to proceed with this action",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          form.post(`${url.value}/${language.value.id}`, { forceFormData: true, })
          title.value = form.lang_title
          swal.fire(
            'Updated!',
            'Your infomation has been Updated.',
            'success'
          )
        }
      });
    }
    const destroy = () => {
      swal({
        title: 'Are you sure?',
        text: "Do you really want to delete this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          form.delete(`${url.value}/${language.value.id}`)
          swal.fire(
            'Deleted!',
            'Your infomation has been deleted.',
            'success'
          )
        }
      });
    }
    const restore = () => {
      swal({
        title: 'Are you sure?',
        text: "You want to restore this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Resore it!'
      }).then((result) => {
        if (result.isConfirmed) {
          form.put(`${url.value}/${language.value.id}/restore`)
          swal.fire(
            'Restored!',
            'Your infomation has been Restored.',
            'success'
          )
        }
      });
    }
    return {
      form,
      language,
      type,
      title,
      head,
      url,
      createUrl,
      update,
      destroy,
      restore,
      swal,
      permission
    }
  }
}
</script>
