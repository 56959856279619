<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="store">
                <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                    <text-input v-model="form.decision_title" :error="form.errors.decision_title"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Title" required="true" placeholder="i.e Approved" />
                    <text-input v-model="form.decision_title_citizen" :error="form.errors.decision_title_citizen"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Citizen Title" required="true"
                        placeholder="i.e Request Approved" />
                    <status-toogle-button v-model="form.has_notification" :error="form.errors.has_notification"
                        label="Has Notification" class="pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>
                    <text-input v-if="form.has_notification == true" v-model="form.sms" :error="form.errors.sms"
                        class="pb-8 pr-6 w-full lg:w-1/1" label="SMS" required="true"
                        placeholder="i.e Your request has been approved" />
                    <text-input v-if="form.has_notification == true" v-model="form.email" :error="form.errors.email"
                        class="pb-8 pr-6 w-full lg:w-1/1" label="Email" required="true"
                        placeholder="i.e Your request has been approved" />
                    <select-input v-model="form.type" :error="form.errors.d_type" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Decision Type" required="true">
                        <option value=null>Select Decision Type</option>
                        <option value="1">Forward</option>
                        <option value="2">Return</option>
                        <option value="3">Reject</option>
                        <option value="4">Complete</option>
                        <option value="5">Resubmit</option>
                        <option value="6">Print</option>
                        <option value="7">Payment Request</option>
                        <option value="8">Payment Success</option>
                        <option value="9">Payment Resubmit</option>
                        <option value="10">Received</option>
                        <option value="11">In-Progress</option>
                        <option value="12">Generate Certificate</option>
                        <option value="13">Cancel</option>
                        <option value="15">Document Request</option>
                        <option value="16">Document Received</option>
                        <option value="17">Document Success</option>
                        <option value="18">Document Resubmit</option>
                        <option value="21">Reappointment</option>
                    </select-input>
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Decision "
                        class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button>
                    <status-toogle-button v-model="form.is_resolve" :error="form.errors.is_resolve" label="Is Resolve"
                        class="pb-8 pr-6 w-full lg:w-1/2"></status-toogle-button>
                    <status-toogle-button v-model="form.is_open" :error="form.errors.is_open" label="Is Open"
                        class="pb-8 pr-6 w-full lg:w-1/2"></status-toogle-button>
                    <status-toogle-button v-model="form.permanent_reject" :error="form.errors.permanent_reject"
                        label="Is Permanent Reject" class="pb-8 pr-6 w-full lg:w-1/2"></status-toogle-button>

                </div>
                <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
            </form>
        </div>
    </div>
</template>

<script>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Button from '@/Shared/Button'
export default {
    components: {
        Head,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        Breadcrumbs,
        StatusToogleButton,
        Button
    },
    layout: Layout,
    remember: 'form',
    setup() {
        const form = useForm({
            decision_title: null,
            decision_title_citizen: null,
            sms: null,
            email: null,
            type: null,
            has_notification: true,
            is_resolve: true,
            is_open: false,
            permanent_reject: false,
            status: true
        })
        const type = ref('create')
        const title = ref('Decision')
        const url = ref('/backend/decisionsteps')
        const createUrl = ref('')
        const swal = inject('$swal')
        const store = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.post(`${url.value}`, { forceFormData: true, })
                    swal.fire(
                        'Added!',
                        'Your infomation has been Added.',
                        'success'
                    )
                }
            });

        }
        return {
            form,
            type,
            title,
            url,
            createUrl,
            store
        }
    }
}
</script>
