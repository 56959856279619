<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
      <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="store">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.designation_name" :error="form.errors.designation_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Designation"  required="true" placeholder="i.e Mayor"/>
            <text-input v-model="form.designation_short_name" :error="form.errors.designation_short_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Short Designation"  required="true" placeholder="i.e BPLO"/>
            <text-input v-model="form.designation_order" :error="form.errors.designation_order" class="pb-8 pr-6 w-full lg:w-1/2" label="Designation Order"  required="true" placeholder="i.e 1"/>
            <select-input v-model="form.reporting_designation" :error="form.errors.reporting_designation" class="pb-8 pr-6 w-full lg:w-1/2" label="Reporting Designation" >
              <option value='0' >Select Reporting Designation</option>
              <option v-for="sindesignation in designations" :key="sindesignation.id" :value='sindesignation.id'>{{ sindesignation.designation_name }}</option>
            </select-input>
            <select-input v-model="form.section_id" :error="form.errors.section_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Divison" required="true">
              <option value='0' >Select Section</option>
              <option v-for="officeSection in officeSections" :key="officeSection.id" :value='officeSection.id'>{{ officeSection.section_name }}</option>
            </select-input>
            <select-input v-model="form.type" :error="form.errors.type" class="pb-8 pr-6 w-full lg:w-1/2" label="Type" required="true">
              <option value=null >Select type</option>
              <option value='1' >Senior</option>
              <option value='2' >Others</option>
            </select-input>
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Designation" class="pb-8 pr-6 w-full lg:w-1/1" :checked="statusCheck"></status-toogle-button>
          </div>
          <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>

        </form>
      </div>
    </div>
  </template>

  <script>
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import { ref, toRefs, inject } from 'vue'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Button from '@/Shared/Button'
  export default {
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      Breadcrumbs,
      StatusToogleButton,
      Button
    },
    layout: Layout,
    remember: 'form',
    props:{
      officeSections: Array,
      designations: Array,
    },
    setup(props){
      const { officeSections } = toRefs(props)
      const form = useForm({
        designation_name: null,
        designation_short_name: null,
        code: null,
        section_id: 0,
        code: null,
        type: null,
        status: true,
        designation_order: '',
        reporting_designation: 0
      })
      const type = ref('create')
      const title = ref('Designation')
      const url = ref('/backend/designations')
      const createUrl = ref('')
      const swal = inject('$swal')
      const store = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}`, {forceFormData: true,})
              swal.fire(
                'Added!',
                'Your infomation has been Added.',
                'success'
              )
            }
          });

      }
      return {
        form,
        type,
        title,
        url,
        createUrl,
        store,
        officeSections
      }
    }
  }
  </script>
