<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
      <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="store">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.name" :error="form.errors.name" class="pb-8 pr-6 w-full lg:w-1/2"  label="Name" required="true" placeholder="i.e Techzone Philippines"/>
            <text-input v-model="form.phone" :error="form.errors.phone" class="pb-8 pr-6 w-full lg:w-1/2" label="Phone" required="true" placeholder="i.e 02 8817 0389 "/>
            <text-input v-model="form.address" :error="form.errors.address" class="pb-8 pr-6 w-full lg:w-1/2" label="Address" required="true" placeholder="i.e Techzone, Malugay, Makati, Metro Manila, Philippines"/>
            <text-input v-model="form.latitude" :error="form.errors.latitude" class="pb-8 pr-6 w-full lg:w-1/2" label="Latitude" required="true" placeholder="i.e 14.578352150200645"/>
            <text-input v-model="form.longitude" :error="form.errors.longitude" class="pb-8 pr-6 w-full lg:w-1/2" label="Longitude" required="true" placeholder="i.e 121.00917410468901"/>
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status" class="pb-8 pr-6 w-full lg:w-1/2 mt-5"></status-toogle-button>
          </div>
          <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
        </form>
      </div>
    </div>
  </template>

  <script>
  import Breadcrumbs from '@/Shared/Breadcrumbs'
import Button from '@/Shared/Button'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import TextInput from '@/Shared/TextInput'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
  export default {
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      Breadcrumbs,
      StatusToogleButton,
      Button
    },
    layout: Layout,
    remember: 'form',

    setup(props){

      const form = useForm({
        name:null,
        phone: null,
        address: null,
        latitude: null,
        longitude: null,
        status: true
      })
      const type = ref('create')
      const title = ref('Digital Center')
      const url = ref('/backend/digitalcenters')
      const createUrl = ref('')
      const swal = inject('$swal')
      const store = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}`, {forceFormData: true,})
              swal.fire(
                'Added!',
                'Your infomation has been Added.',
                'success'
              )
            }
          });

      }

      return {
        form,
        type,
        title,
        url,
        createUrl,
        store
      }
    }
  }
  </script>
