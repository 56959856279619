<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
        <trashed-message v-if="config.deleted_at" class="mb-6" @restore="restore"> This Municipality Config has been
            deleted.
        </trashed-message>
        <form @submit.prevent="update">
            <div class="gap-2 grid grid-cols-2 justify-between">
                <div class="g-white rounded-md shadow overflow-hidden">
                    <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                        <select-input-function v-model="form.service_info_id" :error="form.errors.service_info_id"
                            class="pb-8 pr-6 w-full lg:w-1/1" label="Name of Service" required="true"
                            :action="load_config">
                            <option value=''>Select Service</option>
                            <option v-for="service in serviceInfo" :key="service.id" :value='service.id'>{{
                                service.service_name }} - {{ service.ser_name }}</option>
                        </select-input-function>
                        <select-input-function v-model="form.config_id" :error="form.errors.config_id"
                            class="pb-8 pr-6 w-full lg:w-1/1" label="Name of Config" required="true"
                            :action="load_config_details">
                            <option value=''>Select Config</option>
                            <option v-for="config in config_list" :key="config.id" :value='config.id'>{{
                                config.name }}</option>
                        </select-input-function>

                        <template v-if="employeeData.length !== 0">
                            <select-input-function v-model="form.geo_region_id" :error="form.errors.geo_region_id"
                                class="pb-8 pr-6 w-full lg:w-1/1" label="Name of Region" :action="load_province"
                                required="true">
                                <option value=''>Select Region</option>
                                <option v-for="region in regions" :key="region.id" :value='region.id'>{{
                                    region.region_name
                                }}</option>
                            </select-input-function>
                            <select-input-function v-model="form.geo_provience_id" :error="form.errors.geo_provience_id"
                                class="pb-8 pr-6 w-full lg:w-1/1" label="Name of Province" :action="load_municipality"
                                required="true">
                                <option value=''>Select Provience</option>
                                <option v-for="province in province_list" :key="province.id" :value='province.id'>{{
                                    province.provience_name }}</option>
                            </select-input-function>
                            <select-input-function v-model="form.geo_municipality_id"
                                :error="form.errors.geo_municipality_id" class="pb-8 pr-6 w-full lg:w-1/1"
                                label="Name of Municipality" required="true" :action="load_barangay">
                                <option value=''>Select Municipality</option>
                                <option v-for="municipal in municipality_list" :key="municipal.id"
                                    :value='municipal.id'>{{
                                        municipal.municipality_name }}</option>
                            </select-input-function>
                        </template>
                        <text-input v-model="form.amount" :error="form.errors.amount" class="pb-8 pr-6 w-full lg:w-1/1"
                            label="Amount" required="true" placeholder="i.e 100" />
                        <textarea-input v-model="form.description" :error="form.errors.description"
                            class="pb-8 pr-6 w-full lg:w-1/1" label="Description" required="true"
                            placeholder="i.e Approved" />
                        <status-toogle-button v-model="form.status" :error="form.errors.status" label="Config Status"
                            class="pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>
                    </div>
                </div>
                <div class="flex flex-col g-white overflow-hidden rounded-md shadow">
                    <div class="border-b-2 caption_header_block flex gap-2 items-center p-4 px-4">
                        <i class="fa fa-cogs"></i>
                        <span>Others</span>
                        <Button :name="'Add'" :icon="'far fa-plus-circle'" :method="open_detail_popup"></Button>
                    </div>
                    <div class="sm:col-span-3 gap-y-2 gap-x-1 mb-1">
                        <div class="gap-2 grid grid-cols-2 m-2">
                            <div class="bg-white border-2 max-w-lg p-2 relative rounded-lg shadow-md w-full"
                                v-for="(detail, index) in form.details" :key="index">
                                <i class="fa-light fa-xmark absulate absolute -right-1.5 -top-1.5 flex h-5 w-5 cursor-pointer items-center justify-center rounded-full border border-gray-300 bg-white text-red-700 shadow-[0_0_5px_#999] p-1"
                                    @click="removeDetails(index)"></i>
                                <!-- Top Header -->
                                <div class="p2 mb-2 flex justify-between border-b">
                                    <div class=" " v-if="variable_list.length > 0">
                                        <h5 class="text-xl font-bold text-gray-800">{{
                                            variable_title(detail.variable_id) }}
                                        </h5>
                                    </div>
                                    <!-- Amount Section -->
                                    <div class="flex items-center">
                                        <p class="font-bold text-green-600" v-if="detail.amount">{{ detail.amount
                                            }}<template v-if="detail.type == 'percentage'">%</template></p>
                                        <p class="font-bold text-green-600" v-else>{{ detail.recurring_amount
                                            }}<template v-if="detail.type == 'percentage'">%</template>
                                        </p>
                                    </div>
                                </div>
                                <!-- Card Body (Other Information) -->
                                <div class="space-y-4">
                                    <!-- Recurring -->
                                    <div class="flex items-center gap-2" v-if="detail.recurring">
                                        <span class="text-gray-600">Recurring : </span>
                                        <span class="text-gray-900">{{ detail.recurring }}</span>
                                    </div>
                                    <!-- Recurring Type -->
                                    <div class="flex items-center gap-2"
                                        v-if="detail.recurring && detail.recurring_type">
                                        <span class="text-gray-600">Recurring Type:</span>
                                        <span class="text-gray-900">{{ detail.recurring_type }}</span>
                                    </div>
                                    <!-- Number of Recurring -->
                                    <div class="flex items-center gap-2"
                                        v-if="detail.recurring && detail.recurring_type">
                                        <span class="text-gray-600">Number of Recurring:</span>
                                        <span class="text-gray-900">{{ detail.recurring_amount }}</span>
                                    </div>
                                    <!-- Recurring Amount -->
                                </div>
                            </div>
                        </div>
                        <div class="col-span-2">
                            <detail :details="form.details" :detailopen="detailopen"
                                @UpdateDetailPopup="update_detail_popup" @UpdateDetail="update_detail">
                            </detail>
                        </div>
                        <div v-if="form.errors.details" class="form-error">Details required.
                        </div>
                    </div>
                </div>
                <div class="g-white rounded-md shadow overflow-hidden col-span-2 text-right">
                    <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject, onMounted, watch } from 'vue'
import Layout from '@/Shared/Layout'
import TextareaInput from '@/Shared/TextareaInput'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Button from '@/Shared/Button'
import Detail from './components/Details'
import Icon from '@/Shared/Icon'
import TrashedMessage from '@/Shared/TrashedMessage'
import DeleteIcon from '@/Shared/DeleteIcon'
import SelectLoder from '@/Shared/SelectLoder'
import SelectInputFunction from '@/Shared/SelectInputFunction'
export default {
    components: {
        Icon,
        TrashedMessage,
        DeleteIcon,
        Head,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        Breadcrumbs,
        StatusToogleButton,
        Button,
        TextareaInput,
        Detail,
        SelectInputFunction,
        SelectLoder
    },
    layout: Layout,
    props: {
        config: Object,
        permission: Object,
        serviceInfo: Array,
        regions: Array,
        employee: Object
    },
    remember: 'form',
    setup(props) {
        const { config, permission, serviceInfo, regions, employee } = toRefs(props)
        const type = ref('edit')
        const title = ref(config.value.name)
        const head = ref('Municipality Config')
        const url = ref('/backend/payment-config-goes')
        const createUrl = ref('')
        const swal = inject('$swal')
        const elm = ref(this)
        const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
        const detailopen = ref(false)
        const employeeData = ref({})
        const form = useForm({
            config_id: config.value.config_id,
            description: config.value.description,
            amount: config.value.amount,
            service_info_id: config.value.service_info_id,
            status: config.value.status,
            geo_region_id: config.value.geo_region_id,
            geo_provience_id: config.value.geo_provience_id,
            geo_municipality_id: config.value.geo_municipality_id,
            details: config.value.details
        })
        const update = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${config.value.id}`)
                    title.value = form.name
                    swal.fire(
                        'Updated!',
                        'Your infomation has been Updated.',
                        'success'
                    )
                }
            });
        }
        const destroy = () => {
            swal({
                title: 'Are you sure?',
                text: "Do you really want to delete this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.delete(`${url.value}/${config.value.id}`)
                    swal.fire(
                        'Deleted!',
                        'Your infomation has been deleted.',
                        'success'
                    )
                }
            });
        }
        const restore = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to restore this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Resore it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${config.value.id}/restore`)
                    swal.fire(
                        'Restored!',
                        'Your infomation has been Restored.',
                        'success'
                    )
                }
            });
        }
        const statusCheck = () => {
            if (config.value.status == 1) {
                form.status = true;
                return true;
            } else {
                form.status = false;
                return false;
            }
        }

        const update_detail_popup = (val) => {
            detailopen.value = val
        }
        const open_detail_popup = () => {

            detailopen.value = true
        }
        const update_detail = (val) => {
            let obj = form.details.find(o => o.variable_id === val.variable_id);
            if (obj === undefined) {
                form.details.push(val)
            } else {
                alert('You have already add that type');
            }

        }

        const type_list = ref([
            {
                id: 'fixed',
                title: 'Fixed'
            },
            {
                id: 'percentage',
                title: 'Percentage'
            }
        ])
        const recurring_list = ref([
            {
                id: 'yes',
                title: 'Yes'
            },
            {
                id: 'no',
                title: 'No'
            }
        ])

        const recurring_type_list = ref([
            {
                id: 'day',
                title: 'Day'
            },
            {
                id: 'week',
                title: 'Week'
            },
            {
                id: 'month',
                title: 'Month'
            },
            {
                id: 'year',
                title: 'Year'
            }
        ])

        const variable_list = ref([])
        const load_type = async () => {
            await axios.post(`/${backendPrefix.value}/payment-variables/activity-variables`)
                .then(function (response) {
                    ////console.log(response);
                    variable_list.value = response.data
                }.bind(this));
        }


        const variable_title = (value) => {
            const index = variable_list.value.findIndex(p => p.id == value);
            return variable_list.value[index].title;
        }
        const type_title = (value) => {
            const index = type_list.value.findIndex(p => p.id == value);
            return type_list.value[index].title;

        }
        const recurring_title = (value) => {
            const index = recurring_list.value.findIndex(p => p.id == value);
            return recurring_list.value[index].title;

        }
        const recurring_type_title = (value) => {
            const index = recurring_type_list.value.findIndex(p => p.id == value);
            return recurring_type_list.value[index].title;

        }
        const slugBuild = () => {
            form.slug = form.name.replace(/\s/g, '-').toLowerCase()
        }

        const setdata = async () => {

            if (form.geo_region_id > 0) {
                await load_province();
                await load_municipality();
                await load_barangay();

            }
            if (form.service_info_id > 0) {
                await load_config();
            }
            if (form.config_id > 0) {
                // await load_config_details();
            }

        }

        onMounted(() => {
            load_type();
            setdata();
        })


        const province_list = ref([])
        const load_province = async () => {

            province_list.value = []
            let data = {
                geo_region_id: form.geo_region_id
            }
            await axios.post(`/${backendPrefix.value}/provinces/activeprovinces`, data)
                .then(function (response) {
                    province_list.value = response.data
                    // form.geo_provience_id = ''
                    // form.geo_municipality_id = ''
                    // form.geo_barangay_id = ''
                    if (form.geo_provience_id) load_municipality(1)
                }.bind(this));

        }

        const municipality_list = ref([])
        const load_municipality = async () => {
            municipality_list.value = []
            let data = {
                geo_provience_id: form.geo_provience_id
            }
            await axios.post(`/${backendPrefix.value}/municipalities/activemunicipalities`, data)
                .then(function (response) {
                    // form.geo_municipality_id = ''
                    // form.geo_barangay_id = ''
                    municipality_list.value = response.data
                    if (form.geo_municipality_id) load_barangay(1)
                }.bind(this));
        }

        const barangay_list = ref([])
        const load_barangay = async () => {
            barangay_list.value = []
            let data = {
                geo_municipality_id: form.geo_municipality_id
            }
            await axios.post(`/${backendPrefix.value}/barangays/activebarangays`, data)
                .then(function (response) {
                    // form.geo_barangay_id = ''
                    barangay_list.value = response.data
                }.bind(this));
        }

        const removeDetails = (index, id = null) => {
            if (index !== -1) {
                if (id != null) {
                    let data = {
                        id: id
                    }
                    console.log('data:', data);
                    axios.post(`/${backendPrefix.value}/payment-config-goes/destroy-details`, data).then(async (response) => {
                    }).catch(error => {
                    })
                }
                form.details.splice(index, 1)
            } else {
                console.log('Here');
            }
        }

        const config_list = ref([])
        const load_config = async () => {
            config_list.value = []
            let data = {
                service_info_id: form.service_info_id
            }
            await axios.post(`/${backendPrefix.value}/payment-configs/get-list`, data)
                .then(function (response) {
                    config_list.value = response.data.data
                }.bind(this));
        }
        const config_data = ref([])
        const load_config_details = async () => {
            config_data.value = []
            let data = {
                id: form.config_id
            }
            await axios.post(`/${backendPrefix.value}/payment-configs/get-details/` + form.config_id, data)
                .then(function (response) {
                    form.description = response.data.data.description
                    form.amount = response.data.data.amount
                    let details = response.data.data.details;
                    form.details.config_id = details.config_id
                    form.details.variable_id = details.variable_id
                    form.details.recurring = details.recurring
                    form.details.recurring_type = details.recurring_type
                    form.details.recurring_count = details.recurring_count
                    form.details.amount_type = details.amount_type
                    form.details.recurring_amount = details.recurring_amount
                    form.details.amount = details.amount
                    form.details.status = details.status
                }.bind(this));
        }
        return {
            form,
            config,
            type,
            title,
            head,
            url,
            createUrl,
            update,
            destroy,
            restore,
            statusCheck,
            swal,
            permission,
            serviceInfo,
            type_list,
            recurring_list,
            recurring_type_list,
            load_type,
            variable_list,
            variable_title,
            type_title,
            recurring_title,
            recurring_type_title,
            detailopen, open_detail_popup, update_detail_popup, update_detail,
            backendPrefix,
            slugBuild,
            removeDetails,
            regions,
            province_list,
            load_province,
            municipality_list,
            load_municipality,
            barangay_list,
            load_barangay,
            employee,
            employeeData,
            setdata,
            config_list,
            load_config,
            config_data,
            load_config_details
        }
    },
    created: function () {
        this.statusCheck();
    }
}
</script>
