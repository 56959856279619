<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
      <trashed-message v-if="officelayers.deleted_at" class="mb-6" @restore="restore"> This Region has been deleted. </trashed-message>
      <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="update">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.office_layer_name" :error="form.errors.office_layer_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Layer"  required="true" placeholder="i.e Layer One"  />
            <text-input v-model="form.name_eng_short" :error="form.errors.name_eng_short" class="pb-8 pr-6 w-full lg:w-1/2" label="Short Name" placeholder="i.e LO"/>
            <text-input v-model="form.reference_code" :error="form.errors.reference_code" class="pb-8 pr-6 w-full lg:w-1/2" label="Code" placeholder="i.e 1001"/>
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Layer" class="mt-8 pb-8 pr-6 w-full lg:w-1/2" :checked="statusCheck"></status-toogle-button>
          </div>
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
            <button v-if="!officelayers.deleted_at && permission.edit>0" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>

            <Button v-if="permission.edit>0" :type="type" :name="head" :url="createUrl" :loading="form.processing"></Button>
          </div>
        </form>
      </div>
    </div>
  </template>

  <script>
  import { ref, toRefs, inject } from 'vue'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import Icon from '@/Shared/Icon'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import TrashedMessage from '@/Shared/TrashedMessage'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import DeleteIcon from '@/Shared/DeleteIcon'
  import SelectInputFunction from '@/Shared/SelectInputFunction'
  import Button from '@/Shared/Button'
  export default {
    components: {
      Head,
      Icon,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      TrashedMessage,
      StatusToogleButton,
      Breadcrumbs,
      DeleteIcon,
      SelectInputFunction,
      Button
    },
    layout: Layout,
    props: {
      officelayers: Object,
      permission: Object,
    },
    remember: 'form',
    setup(props){
      const { officelayers,permission} = toRefs(props)
      const type = ref('edit')
      const title = ref(officelayers.value.office_layer_name)
      const head = ref('Layer')
      const url = ref('/backend/officelayers')
      const createUrl = ref('')
      const swal = inject('$swal')
      const elm = ref(this)
      const form = useForm({
        office_layer_name: officelayers.value.office_layer_name,
        name_eng_short: officelayers.value.name_eng_short,
        reference_code: officelayers.value.reference_code,
        status: officelayers.value.status,
        layer_level: officelayers.value.layer_level
      })
      const update = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${officelayers.value.id}`)
              title.value = form.office_layer_name
              swal.fire(
                'Updated!',
                'Your infomation has been Updated.',
                'success'
              )
            }
          });
      }

      const destroy = () => {
        swal({
            title: 'Are you sure?',
            text: "Do you really want to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.delete(`${url.value}/${officelayers.value.id}`)
              swal.fire(
                'Deleted!',
                'Your infomation has been deleted.',
                'success'
              )
            }
          });
      }
      const restore = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to restore this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Resore it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${officelayers.value.id}/restore`)
              swal.fire(
                'Restored!',
                'Your infomation has been Restored.',
                'success'
              )
            }
          });
      }
      const statusCheck = () => {
        if(officelayers.value.status==1){
          form.status=true;
          return true;
        }else{
          form.status=false;
          return false;
        }
      }
      return {
        form,
        officelayers,
        type,
        title,
        head,
        url,
        createUrl,
        update,
        destroy,
        restore,
        statusCheck,
        swal,
        permission
      }
    },
    created: function(){
      this.statusCheck();
    }
  }
  </script>
