<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="flex items-center justify-between mb-6">
            <search-filter v-model="form.search" class="mr-4 w-full max-w-xl" @reset="reset">
                <template v-slot:filter>
                    <select v-model="form.trashed" class="form-select  w-28">
                        <option :value="null">Filter</option>
                        <option value="with">With Trashed</option>
                        <option value="only">Only Trashed</option>
                    </select>
                </template>
                <template v-slot:page>
                    <select v-model="form.length" class="form-select  w-20">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                    </select>
                </template>
                <template v-slot:status>
                    <select v-model="form.status" class="form-select w-32">
                        <option :value="null">Status</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                    </select>
                </template>
            </search-filter>
            <Button :type="type" :name="title" :url="createUrl" v-if="permission.add > 0"></Button>
        </div>
        <div class="bg-white rounded-md shadow ">
            <table class="w-full whitespace-nowrap">
                <thead>
                    <tr class="text-left font-bold">
                        <th class="pb-2 pt-3 px-3" width="20">Sl.</th>
                        <th class="pb-2 pt-3 px-3">Info</th>
                        <th class="pb-2 pt-3 px-3 text-center">Status</th>
                        <th class="pb-2 pt-3 px-3 text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(queue_office, index) in queue_offices.data" :key="queue_office.id"
                        class="hover:bg-gray-100 focus-within:bg-gray-100">
                        <td class="border-t pb-1 pt-1 px-3 text-center">
                            {{ (index + queue_offices.from) }}
                        </td>
                        <td class="border-t pb-2 pt-3 px-3">
                            <div class="flex align-middle ">
                                <div>
                                    <p class="flex align-middle ">
                                    <div><b>{{ queue_office.office_name }}</b> &nbsp;</div>
                                    </p>
                                    <p>Code: {{ queue_office.code }}</p>
                                    <p>Office: {{ queue_office.office }},
                                        <span v-if="queue_office.office_layer_id == 1">{{
                                            queue_office.ministry.ministry_name
                                        }}</span>
                                        <span v-if="queue_office.office_layer_id == 2">{{
                                            queue_office.geo_region.region_name
                                        }}</span>
                                        <span v-if="queue_office.office_layer_id == 3">{{
                                            queue_office.geo_provience.provience_name }}</span>
                                        <span v-if="queue_office.office_layer_id == 4">{{
                                            queue_office.geo_municipality.municipality_name }}</span>
                                        <span v-if="queue_office.office_layer_id == 5">{{
                                            queue_office.geo_barangay.barangay_name }}</span>
                                    </p>
                                </div>
                            </div>
                            <icon v-if="queue_office.deleted_at" name="trash"
                                class="flex-shrink-0 ml-2 w-3 h-3 fill-gray-400" />
                        </td>
                        <td class="border-t pb-2 pt-3 px-3 text-center">
                            <status-level :status="queue_office.status"></status-level>
                        </td>
                        <td class="w-px border-t text-center">
                            <div class="flex items-center px-2" v-if="permission.edit > 0">
                                <Link class="px-4 py-2" :href="`/backend/queue-offices/${queue_office.id}/edit`"
                                    tabindex="-1">
                                <i class="fa fa-edit"></i>
                                </Link>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="queue_offices.data.length === 0">
                        <td class="border-t items-center px-6 py-4 text-center" colspan="4">No Queue Office found.</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination class="mt-6" :links="queue_offices.links" />
    </div>
</template>

<script>
import { ref, toRefs, inject } from 'vue'
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import SearchFilter from '@/Shared/SearchFilter'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import StatusLevel from '@/Shared/StatusLevel'
import Button from '@/Shared/Button'

export default {
    components: {
        Head,
        Icon,
        Link,
        Pagination,
        SearchFilter,
        Breadcrumbs,
        StatusLevel,
        Button
    },
    layout: Layout,
    props: {
        filters: Object,
        permission: Object,
        queue_offices: Object,
    },
    setup(props) {
        const { filters, queue_offices, permission } = toRefs(props)
        const form = ref({
            search: filters.value.search,
            trashed: filters.value.trashed,
            length: filters.value.length ? filters.value.length : 15,
            status: filters.value.status,
        })
        const type = ref('index')
        const title = ref('Queue Office')
        const url = ref('')
        const createUrl = ref('/backend/queue-offices/create')
        const reset = () => {
            form.value = mapValues(form, () => null)
        }
        return {
            filters,
            queue_offices,
            form,
            type,
            title,
            url,
            createUrl,
            reset,
            permission
        }
    },
    watch: {
        form: {
            deep: true,
            handler: throttle(function () {
                this.$inertia.get('/backend/queue-offices', pickBy(this.form), { preserveState: true })
            }, 150),
        },
    },

}
</script>
