<template>
  <div>
    <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
    <div class="flex justify-start mb-8 max-w-3xl">
      <img v-if="categoryGroup.image_path" class="block ml-4 w-8 h-8 rounded-full" :src="categoryGroup.image_path" />
    </div>
    <trashed-message v-if="categoryGroup.deleted_at" class="mb-6" @restore="restore"> This categoryGroup has been
      deleted. </trashed-message>
    <div class="max-w-lg bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <text-input v-model="form.group_title" :error="form.errors.group_title" class="pb-8 pr-6 w-full lg:w-1/1"
            label="Name of Group Title" required="true" placeholder="i.e Parks" />
          <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/1"
            label="Name of Slug" required="true" placeholder="i.e park" />
          <text-input v-model="form.hierarchy" :error="form.errors.hierarchy" class="pb-8 pr-6 w-full lg:w-1/1"
            label="Hierarchy" placeholder="i.e 1" type="number" oninput="this.value = Math.abs(this.value)" min="0" />
          <file-input v-model="form.image_path" :error="form.errors.image_path" class="pb-8 pr-6 w-full lg:w-1/1"
            type="file" accept="image/*" label="Image" />
          <status-toogle-button v-model="form.status" :error="form.errors.status" label="Group Title Status"
            class="pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>
        </div>
        <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
          <!-- <button v-if="!categoryGroup.deleted_at && permission.edit>0" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button> -->
          <Button v-if="permission.edit > 0" :type="type" :name="head" :url="createUrl"
            :loading="form.processing"></Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, inject } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import DeleteIcon from '@/Shared/DeleteIcon'
import Button from '@/Shared/Button'
import FileInput from '@/Shared/FileInput'
export default {
  components: {
    Head,
    Icon,
    Link,
    LoadingButton,
    SelectInput,
    TextInput,
    TrashedMessage,
    StatusToogleButton,
    Breadcrumbs,
    DeleteIcon,
    Button,
    FileInput
  },
  layout: Layout,
  props: {
    categoryGroup: Object,
    permission: Object,
  },
  remember: 'form',
  setup(props) {
    const { categoryGroup, permission } = toRefs(props)
    const type = ref('edit')
    const title = ref(categoryGroup.value.group_title)
    const head = ref('Category Group')
    const url = ref('/backend/category-groups')
    const createUrl = ref('')
    const swal = inject('$swal')
    const elm = ref(this)
    const form = useForm({
      group_title: categoryGroup.value.group_title,
      slug: categoryGroup.value.slug,
      hierarchy: categoryGroup.value.hierarchy,
      status: categoryGroup.value.status,
      image_path: null
    })
    const update = () => {
      swal({
        title: 'Are you sure?',
        text: "You want to proceed with this action",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          ////console.log(form);
          form.post(`${url.value}/${categoryGroup.value.id}`, { forceFormData: true, })
          title.value = form.group_title
          swal.fire(
            'Updated!',
            'Your infomation has been Updated.',
            'success'
          )
        }
      });
    }
    const destroy = () => {
      swal({
        title: 'Are you sure?',
        text: "Do you really want to delete this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          form.delete(`${url.value}/${categoryGroup.value.id}`)
          swal.fire(
            'Deleted!',
            'Your infomation has been deleted.',
            'success'
          )
        }
      });
    }
    const restore = () => {
      swal({
        title: 'Are you sure?',
        text: "You want to restore this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Resore it!'
      }).then((result) => {
        if (result.isConfirmed) {
          form.put(`${url.value}/${categoryGroup.value.id}/restore`)
          swal.fire(
            'Restored!',
            'Your infomation has been Restored.',
            'success'
          )
        }
      });
    }
    const statusCheck = () => {
      if (categoryGroup.value.status == 1) {
        form.status = true;
        return true;
      } else {
        form.status = false;
        return false;
      }
    }
    return {
      form,
      categoryGroup,
      type,
      title,
      head,
      url,
      createUrl,
      update,
      destroy,
      restore,
      statusCheck,
      swal,
      permission
    }
  },
  created: function () {
    this.statusCheck();
  }

}
</script>
