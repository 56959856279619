<template>
    <div class="profile_edit_content_block">
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="w-full bg-white rounded-md shadow overflow-hidden edit_form_block">
            <form @submit.prevent="store">
                <div class="grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6 mt-3 p-4">
                    <text-input v-model="form.office_name" :error="form.errors.office_name" class="sm:col-span-2"
                        label="Office Name" required="true" placeholder="i.e. Lgu Office" />
                    <text-input v-model="form.code" :error="form.errors.code" class="sm:col-span-2" label="Code"
                        required="true" placeholder="i.e 0100" />
                    <text-input v-model="form.user_name" :error="form.errors.user_name" class="sm:col-span-2"
                        label="User Name" required="true" placeholder="i.e lgubutig" />
                    <text-input v-model="form.password" :error="form.errors.password" class="sm:col-span-2"
                        label="Password" required="true" placeholder="i.e *****" />
                    <select-input-function v-model="form.office_layer_id" :error="form.errors.office_layer_id"
                        class="sm:col-span-2" label="Name of Office Layer" :action="loadOffice">
                        <option value=null>Select Office Layer</option>
                        <option v-for="officelayer in officelayers" :key="officelayer.id" :value='officelayer.id'>{{
                            officelayer.office_layer_name }}</option>
                    </select-input-function>
                    <template v-if="showministry">
                        <select-input-function v-model="form.ministry_id" :error="form.errors.ministry_id"
                            class="sm:col-span-2" label="Ministry" :action="loadOffice">
                            <option value=null>Select Ministry</option>
                            <option v-for="ministry in ministries" :key="ministry.id" :value='ministry.id'>{{
                                ministry.ministry_name }}</option>
                        </select-input-function>
                    </template>
                    <template v-if="showregion == true">
                        <select-input-function v-model="form.region_id" :error="form.errors.region_id"
                            class="sm:col-span-2" label="Name of Region" :action="load_province">
                            <option value=null>Select Region</option>
                            <option v-for="region in regions" :key="region.id" :value='region.id'>{{ region.region_name
                                }}</option>
                        </select-input-function>
                    </template>
                    <template v-if="showprovience == true">
                        <select-input-function v-model="form.province_id" :error="form.errors.province_id"
                            class="sm:col-span-2" label="Name of Province" :action="load_municipality">
                            <option value=null>Select Province</option>
                            <option v-for="province in province_list" :key="province.id" :value='province.id'>{{
                                province.provience_name }}</option>
                        </select-input-function>
                    </template>
                    <template v-if="showmunicipality == true">
                        <select-input-function v-model="form.municipality_id" :error="form.errors.municipality_id"
                            class="sm:col-span-2" label="Name of Municipality" :action="load_barangay">
                            <option value=null>Select Municipality</option>
                            <option v-for="municipal in municipality_list" :key="municipal.id" :value='municipal.id'>{{
                                municipal.municipality_name }}</option>
                        </select-input-function>
                    </template>
                    <template v-if="showbarangay == true">
                        <select-input-function v-model="form.barangay_id" :error="form.errors.barangay_id"
                            class="sm:col-span-2" label="Name of Barangay" id="barangay_id" :action="loadOffice">
                            <option value=null>Select Barangay</option>
                            <option v-for="barangay in barangay_list" :key="barangay.id" :value='barangay.id'>{{
                                barangay.barangay_name }}</option>
                        </select-input-function>
                    </template>
                    <select-input-function v-model="form.office_id" :error="form.errors.office_id" class="sm:col-span-2"
                        label="Name of Office">
                        <option value=null>Select Office</option>
                        <option v-for="office in offices" :value='office.id'>{{ office.office_name }}</option>
                    </select-input-function>
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Office Status"
                        class="sm:col-span-2 "></status-toogle-button>
                </div>
                <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>

            </form>
        </div>
    </div>
</template>

<script>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject, onMounted, watch } from 'vue'
import axios from 'axios';
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import TextareaInput from '@/Shared/TextareaInput'
import SelectInput from '@/Shared/SelectInput'
import SelectInputFunction from '@/Shared/SelectInputFunction'
import LoadingButton from '@/Shared/LoadingButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Button from '@/Shared/Button'
export default {
    components: {
        Head,
        Link,
        LoadingButton,
        SelectInput,
        SelectInputFunction,
        TextInput,
        Breadcrumbs,
        StatusToogleButton,
        Button,
        TextareaInput
    },
    props: {
        officelayers: Array,
        ministries: Array,
        regions: Array,
        permission: Array,
        owners: Array,
    },
    layout: Layout,
    remember: 'form',
    setup(props) {
        const { officelayers, ministries, permission, regions } = toRefs(props)
        const form = useForm({
            office_name: null,
            code: null,
            hierarchy: null,
            office_layer_id: null,
            office_id: null,
            ministry_id: null,
            region_id: null,
            province_id: null,
            municipality_id: null,
            barangay_id: null,
            user_name: null,
            password: null,
            status: null
        })
        const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
        const type = ref('create')
        const title = ref('Queue Office')
        const url = ref('/backend/queue-offices')
        const createUrl = ref('')
        const swal = inject('$swal')

        const showministry = ref(false)
        const showregion = ref(false)
        const showprovience = ref(false)
        const showmunicipality = ref(false)
        const showbarangay = ref(false)
        const offices = ref([])

        const store = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.post(`${url.value}`, { forceFormData: true, })
                    swal.fire(
                        'Added!',
                        'Your infomation has been Added.',
                        'success'
                    )
                }
            });

        }

        const province_list = ref([])
        const load_province = async () => {
            if (form.region_id > 0) {
                province_list.value = []
                let data = {
                    geo_region_id: form.region_id
                }
                await axios.post('../provinces/activeprovinces', data)
                    .then(function (response) {
                        province_list.value = response.data
                        form.municipality_id = null
                        form.barangay_id = null
                        if (form.province_id) load_municipality(1)
                    }.bind(this));
            }


        }
        const municipality_list = ref([])
        const load_municipality = async () => {
            if (form.province_id > 0) {
                municipality_list.value = []
                let data = {
                    geo_provience_id: form.province_id
                }
                await axios.post('../municipalities/activemunicipalities', data)
                    .then(function (response) {
                        form.barangay_id = null
                        municipality_list.value = response.data
                        if (form.municipality_id) load_barangay(2)
                    }.bind(this));
            }

        }

        const barangay_list = ref([])
        const load_barangay = async () => {
            if (form.municipality_id > 0) {
                barangay_list.value = []
                let data = {
                    geo_municipality_id: form.municipality_id
                }
                await axios.post('../barangays/activebarangays', data)
                    .then(function (response) {
                        barangay_list.value = response.data
                    }.bind(this));
            }

        }


        watch(() => form.office_layer_id, () => {
            if (form.office_layer_id == 1) {
                showministry.value = true
                showregion.value = false
                showprovience.value = false
                showmunicipality.value = false
                showbarangay.value = false
            } else if (form.office_layer_id == 2) {
                showministry.value = false
                showregion.value = true
                showprovience.value = false
                showmunicipality.value = false
                showbarangay.value = false
            } else if (form.office_layer_id == 3) {
                showministry.value = false
                showregion.value = true
                showprovience.value = true
                showmunicipality.value = false
                showbarangay.value = false
            } else if (form.office_layer_id == 4) {
                showministry.value = false
                showregion.value = true
                showprovience.value = true
                showmunicipality.value = true
                showbarangay.value = false
                load_municipality(1)
            } else if (form.office_layer_id == 5) {
                showministry.value = false
                showregion.value = true
                showprovience.value = true
                showmunicipality.value = true
                showbarangay.value = true
                load_barangay(1)
            }
        }, { deep: true })

        watch(() => form, async () => {
            let data = {
                office_layer_id: form.office_layer_id,
                ministry_id: form.ministry_id,
                geo_region_id: form.region_id,
                geo_province_id: form.province_id,
                geo_municipality_id: form.municipality_id,
                geo_barangay_id: form.barangay_id,
            }
            await axios.post(`/${backendPrefix.value}/geomapping/load-offices`, { data: data })
                .then(function (response) {
                    offices.value = response.data
                })
        }, { deep: true })

        onMounted(() => {
            load_province()
        })

        return {
            form,
            type,
            title,
            url,
            createUrl,
            store,
            regions,
            permission,
            province_list,
            load_province,
            municipality_list,
            load_municipality,
            barangay_list,
            load_barangay,
            showministry,
            showregion,
            showprovience,
            showmunicipality,
            showbarangay,
            offices,
            officelayers,
            ministries,
            backendPrefix
        }
    }
}
</script>
