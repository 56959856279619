<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
      <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="store">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.en_short_name" :error="form.errors.en_short_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Country" required="true" placeholder="i.e Afghanistan"/>
            <text-input v-model="form.nationality" :error="form.errors.nationality" class="pb-8 pr-6 w-full lg:w-1/2" label="Nationality" required="true" placeholder="i.e Afghan"/>
            <text-input v-model="form.alpha_2_code" :error="form.errors.alpha_2_code" class="pb-8 pr-6 w-full lg:w-1/2" label="Code Length Two" required="true" placeholder="i.e AF"/>
            <text-input v-model="form.alpha_3_code" :error="form.errors.alpha_3_code" class="pb-8 pr-6 w-full lg:w-1/2" label="Code Length Three" required="true" placeholder="i.e AFG"/>
            <text-input v-model="form.num_code" :error="form.errors.num_code" class="pb-8 pr-6 w-full lg:w-1/2" label="Code in Number" required="true" placeholder="i.e 4"/>
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Barangay" class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button>
          </div>
          <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
        </form>
      </div>
    </div>
  </template>

  <script>
  import { ref, toRefs, inject } from 'vue'
  import axios from 'axios';
  import { Head, Link,useForm } from '@inertiajs/inertia-vue3'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import SelectInputFunction from '@/Shared/SelectInputFunction'
  import LoadingButton from '@/Shared/LoadingButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Button from '@/Shared/Button'
  export default {
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      SelectInputFunction,
      TextInput,
      Breadcrumbs,
      StatusToogleButton,
      Button,
    },
    layout: Layout,
    remember: 'form',
    props: {
    },
    setup(props){
      const form = useForm({
        num_code: null,
        alpha_2_code: null,
        alpha_3_code: null,
        en_short_name: null,
        nationality: null,
        status: true,
      })

      const municipality = ref([])
      const type = ref('create')
      const title = ref('Country')
      const url = ref('/backend/countries')
      const createUrl = ref('')
      const swal = inject('$swal')
      const store = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}`, {forceFormData: true,})
              swal.fire(
                'Added!',
                'Your infomation has been Added.',
                'success'
              )
            }
          });

      }

      return {
        form,
        type,
        title,
        url,
        createUrl,
        store
      }
    },

  }
  </script>
