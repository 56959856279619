<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
        <trashed-message v-if="chat.deleted_at" class="mb-6" @restore="restore"> This chat has been deleted.
        </trashed-message>
        <div class="max-w-5xl bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="update">
                <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                    <text-input v-model="form.question" :error="form.errors.question" class="pb-8 pr-6 w-full lg:w-1/1"
                        label="Title" required="true" placeholder="i.e How to use this website?" />
                    <div class="pb-8 pr-6 w-full lg:w-1/1">
                        <label class="form-label">Tags</label>
                        <template v-if="form.tag">
                            <input-tag-select :tag_info="form.tag" @setUpdateTags="set_update_tags" />
                        </template>
                    </div>
                    <div class="pb-8 pr-6 w-full lg:w-1/1">
                        <label class="form-label" for="Answer">Answer<span class="custom-error">*</span></label>
                        <vue-editor v-model="form.answer" />
                    </div>
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status"
                        class="pb-8 pr-6 w-full lg:w-1/2"></status-toogle-button>
                </div>
                <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
                    <button v-if="!chat.deleted_at && permission.edit > 0" class="text-red-600 hover:underline"
                        tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
                    <Button v-if="permission.edit > 0" :type="type" :name="head" :url="createUrl"
                        :loading="form.processing"></Button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from '@/Shared/Breadcrumbs'
import Button from '@/Shared/Button'
import DeleteIcon from '@/Shared/DeleteIcon'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import InputTagSelect from '@/Shared/InputTagSelect'
import TextareaInput from '@/Shared/TextareaInput'
import TextInput from '@/Shared/TextInput'
import TrashedMessage from '@/Shared/TrashedMessage'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
import { Quill, VueEditor } from "vue3-editor"
export default {
    components: {
        Head,
        Icon,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        TrashedMessage,
        StatusToogleButton,
        Breadcrumbs,
        DeleteIcon,
        Button,
        TextareaInput,
        Quill,
        VueEditor,
        InputTagSelect
    },
    layout: Layout,
    props: {
        chat: Object,
        permission: Object,
    },
    remember: 'form',
    setup(props) {
        const { chat, permission } = toRefs(props)
        const type = ref('edit')
        const title = ref(chat.value.question)
        const head = ref('Chat')
        const url = ref('/backend/chats')
        const swal = inject('$swal')
        const createUrl = ref('')
        const elm = ref(this)
        const form = useForm({
            question: chat.value.question,
            tag: chat.value.tag ? chat.value.tag : [],
            answer: chat.value.answer,
            status: chat.value.status
        })
        const update = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${chat.value.id}`)
                    title.value = form.title
                    swal.fire(
                        'Updated!',
                        'Your infomation has been Updated.',
                        'success'
                    )
                }
            });
        }

        const destroy = () => {
            swal({
                title: 'Are you sure?',
                text: "Do you really want to delete this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.delete(`${url.value}/${chat.value.id}`)
                    swal.fire(
                        'Deleted!',
                        'Your infomation has been deleted.',
                        'success'
                    )
                }
            });
        }
        const restore = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to restore this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Resore it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${chat.value.id}/restore`)
                    swal.fire(
                        'Restored!',
                        'Your infomation has been Restored.',
                        'success'
                    )
                }
            });
        }
        const set_update_tags = (obj) => {
            form.tag = obj
        }
        const statusCheck = () => {
            if (chat.value.status == 1) {
                form.status = true;
                return true;
            } else {
                form.status = false;
                return false;
            }
        }
        return {
            form,
            chat,
            type,
            title,
            head,
            url,
            createUrl,
            update,
            destroy,
            restore,
            statusCheck,
            swal,
            permission,
            set_update_tags
        }
    },
    created: function () {
        this.statusCheck();
    }

}
</script>
