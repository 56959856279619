<template>
    <div>
        <div class="flex items-center justify-around border-b-2">
            <slot name="drag"></slot>
            <button @click="toggleAccordion()"
                class="group relative flex w-[calc(100%-75px)] items-center rounded-t-[15px] border-0 bg-white px-2 py-2 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none"
                :aria-expanded="isOpen" :aria-controls="`collapse${_uid}`">
                <slot name="title" />
                <span
                    class="ml-auto h-2 w-5 shrink-0 rotate-[-0deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none ">
                    <svg class="w-3 transition-all duration-200 transform" :class="{
                        'rotate-0': isOpen,
                        'rotate-180': !isOpen,
                    }" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10"
                        aria-hidden="true">
                        <path d="M15 1.2l-7 7-7-7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
            </button>
            <slot name="clone" />
        </div>
        <div v-show="isOpen" :id="`collapse${_uid}`" class="px-2 py-2">
            <slot name="content" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isOpen: false,
        };
    },

    methods: {
        toggleAccordion() {
            this.isOpen = !this.isOpen;
        },
    },
};
</script>
