<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" />
      <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="store">
          {{ form.errors }}
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.category_name" :error="form.errors.category_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Category" required="true" placeholder="i.e "/>
            <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Slug" required="true" placeholder="i.e "/>
            <select-input v-model="form.parent_id" :error="form.errors.parent_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Parent" >
              <option value=null >Select Parent</option>
              <option v-for="mediaCategory in mediaCategorys" :key="mediaCategory.id" :value='mediaCategory.id'>{{ mediaCategory.category_name  }}</option>
            </select-input>
            <div class="pb-8 pr-6 w-full lg:w-1/2 grid grid-cols-12">
              <text-input v-model="form.icon" :error="form.errors.icon" class="col-span-11" label="Icon" placeholder="i.e 1" disabled/>
              <vue3-icon-picker v-model="form.icon" class="mt-5"/>
            </div>

            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Category" class="pb-8 pr-6 w-full lg:w-1/2"></status-toogle-button>
            <div class="sm:col-span-2">
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Pictute </label>
              <div class="mt-1 flex flex-wrap justify-center rounded-md border-2 border-dashed border-gray-300 dark:border-black dark:bg-white dark:text-black px-6 pt-5 pb-6">
                  <div class="space-y-1 text-center">
                    <PhotoBlock :getPhoto="form.banner_image" @SetPhoto="set_photo" />
                  </div>
              </div>
            </div>
          </div>
          <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
        </form>
      </div>
    </div>

  </template>

  <script>
  import axios from 'axios';
  import { ref, toRefs,inject } from 'vue'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Button from '@/Shared/Button'
  import FileInput from '@/Shared/FileInput'
  import Vue3IconPicker from '@/Shared/components/Vue3IconPicker'
  import PhotoBlock from './Photo';
  export default {
    props: {
      amenityGroups: Array,
    },
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      Breadcrumbs,
      StatusToogleButton,
      Button,
      FileInput,
      Vue3IconPicker,
      PhotoBlock
    },
    layout: Layout,
    remember: 'form',
    setup(props){
      const { amenityGroups } = toRefs(props)
      const form = useForm({
        parent_id: null,
        slug: null,
        category_name: null,
        icon: 'fas fa-upload',
        status: true,
        banner_image:null
      })
      // const regions = ref([])
      const type = ref('create')
      const title = ref('Category')
      const url = ref('/backend/media-categories')
      const createUrl = ref('')
      const swal = inject('$swal')
      const store = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}`, {forceFormData: true,})
              swal.fire(
                'Added!',
                'Your infomation has been Added.',
                'success'
              )
            }
          });

      }
      const set_photo = (val) => {
            form.banner_image = val
        }
      return {
        form,
        amenityGroups,
        type,
        title,
        url,
        createUrl,
        store,
        set_photo
      }
    }
  }
  </script>
