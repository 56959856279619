<template>
    <div class="office_layer_map_block">
        <ContentHeaderSection :title="'Designation Setup'" :icon="'fa fa-cogs'" />
        <div class="p-5 office_layer_setup_block">
            <form @submit.prevent="updateMapping">
                <select-input-function v-model="orgform.office_layer_id" :error="orgform.errors.office_layer_id" class="pb-8 pr-6 w-full lg:w-1/1" label="Office layer" :action="loadOffice">
                    <option value='0' >Select Office Layer</option>
                    <option v-for="officelayer in officelayers" :key="officelayer.id" :value='officelayer.id'>{{ officelayer.office_layer_name }}</option>
                </select-input-function>
                <template v-if="(orgform.office_layer_id==1)">
                    <select-input v-model="orgform.ministry_id" :error="orgform.errors.ministry_id" class="pb-8 pr-6 w-full lg:w-1/1" label="Ministry">
                        <option value='0' >Select Ministry</option>
                        <option v-for="ministry in ministries" :key="ministry.id" :value='ministry.id'>{{ ministry.ministry_name }}</option>
                    </select-input>
                </template>
                <template v-else-if="(orgform.office_layer_id>1)">
                    <select-input-function v-model="orgform.geo_region_id" :error="orgform.errors.geo_region_id" class="pb-8 pr-6 w-full lg:w-1/1" label="Name Of Region"  :action="loadProvinces">
                        <option value='0' >Select Region</option>
                        <option v-for="region in regions" :key="region.id" :value='region.id'>{{ region.region_name }}</option>
                    </select-input-function>
                    <select-input-function v-model="orgform.geo_provience_id" :error="orgform.errors.geo_provience_id" class="pb-8 pr-6 w-full lg:w-1/1" label="Name Of Province" :action="loadMunicipality">
                        <option value='0' >Select Province</option>
                        <option v-for="province in provinces" :key="province.id" :value='province.id'>{{ province.provience_name }}</option>
                    </select-input-function>
                    <select-input-function v-model="orgform.geo_municipality_id" :error="orgform.errors.geo_municipality_id" class="pb-8 pr-6 w-full lg:w-1/1" label="Name Of Municipality" :action="loadBarangay" v-if="(municipal==true)">
                        <option value='0' >Select Municipality</option>
                        <option v-for="municipal in municipality" :key="municipal.id" :value='municipal.id'>{{ municipal.municipality_name }}</option>
                    </select-input-function>
                    <select-input v-model="orgform.barangay_id" :error="orgform.errors.barangay_id" class="pb-8 pr-6 w-full lg:w-1/1" label="Barangay"  v-if="(barangay==true)">
                        <option value='0' >Select Barangay</option>
                        <option v-for="barangay in barangays" :key="barangay.id" :value='barangay.id'>{{ barangay.barangay_name }}</option>
                    </select-input>
                    <select-input-function v-model="orgform.office_id" :error="orgform.errors.office_id" class="pb-8 pr-6 w-full lg:w-1/1" label="Office" v-if="(offi==true)" :action="searchbuttonstatus">
                        <option value='0' >Select Office</option>
                        <option v-for="office1 in offices" :key="office1.id" :value='office1.id'>{{ office1.office_name }}</option>
                    </select-input-function>
                </template>
                <FloatingActionBtn v-if="(searchbutton==true)" :loader="preloader" :icon="'fa fa-search'" @dataSubmit="search_data" />
           </form>
          
        </div>
        
    </div>
</template>
<script>
    import axios from "axios"
    import { ref, toRefs } from "vue"
    import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
    import ContentHeaderSection from "@/Shared/ContentHeaderSection"
    import FloatingActionBtn from "@/Shared/Buttons/FloatingActionBtn"
    import SelectInputFunction from '@/Shared/SelectInputFunction'
    import SelectInput from '@/Shared/SelectInput'
    export default {
        name: "OfficeLayerBlock",
        props: {
            regions: Array,
            provinces: Array,
            ministries: Array,
            officelayers: Array,
            //offices: Array,
            employee_id: Number
        },
        components: {
            ContentHeaderSection,
            FloatingActionBtn,
            Head,
            Link,
            SelectInputFunction,
            SelectInput
        },
        setup(props){
            const { regions, ministries, provinces,officelayers,employee_id } = toRefs(props)
            const orgform = useForm({
                geo_region_id: null,
                geo_provience_id:null,
                geo_municipality_id:null,
                barangay_id:null,
                office_layer_id:null,
                office_id:null
            })
            const preloader = ref(false) 
            const offid = ref(null)
            const municipal = ref(false)  
            const barangay = ref(false) 
            const searchbutton = ref(false)             
            const offi = ref(false)             
            const municipality = ref([])
            const barangays = ref([])
            const offices = ref([])
            const organogrs = ref([])
            const search_data = () => {                
                let data={ 
                    office_id:offid.value
                } 
                //console.log(data);
                axios.post('../../offices/office-organogram', data)
                .then(function (response) {
                    //console.log(response);
                    organogrs.value=response;
                }.bind(this));                  
            }
            const updateMapping = () => {
                //console.log('Office layer data', sel_layers)
                let formData = {
                    office_id: office_id.value,
                    office_layers: sel_layers.value
                }
                preloader.value = true
                axios.post('/backend/office-layer-map', {
                    data: formData
                }).then( async (response) => {
                    //console.log(response)                    

                    preloader.value = false
                }).catch(error => {
                    preloader.value = false
                    //console.log(error.response.data.error)
                })
            }
            const loadProvinces= async (e) =>{
                if(e.target.value>0){
                let data={
                    geo_region_id:e.target.value
                }
                await axios.post('../../provinces/activeprovinces', data)
                .then(function (response) {
                    provinces.value = response.data;                    
                }.bind(this));
                }else{
                    provinces.value =[];                    
                }
            }
            const loadMunicipality= async (e) =>{
                if(e.target.value>0){
                let data={
                    geo_provience_id:e.target.value
                }
                await axios.post('../../municipalities/activemunicipalities', data)
                .then(function (response) {
                    municipality.value = response.data;                    
                    municipal.value = true;
                }.bind(this));
                }else{
                    municipality.value =[];
                    municipal.value = false;
                    barangay.value = false;
                }
            }
            const loadBarangay= async (e) =>{
                if(e.target.value>0){
                let data={
                    geo_municipality_id:e.target.value
                }
                await axios.post('../../barangays/activebarangays', data)
                .then(function (response) {
                    barangays.value = response.data;
                    barangay.value = true;
                }.bind(this));   
                }else{
                    barangays.value =[];
                    barangay.value = false;
                }
            }            
            const loadOffice= async (e) =>{
                if(e.target.value>0){
                let data={
                    office_layer_id:e.target.value
                }
                await axios.post('../../offices/activelayeroffices', data)
                .then(function (response) {
                    ////console.log(response);
                    offices.value = response.data;
                    offi.value = true;
                }.bind(this));   
                }else{                   
                    offices.value =[];
                    offi.value = false;
                }
            }            
            const searchbuttonstatus= async (e) =>{
                if(e.target.value>0){
                    offid.value=e.target.value;
                    searchbutton.value=true;
                }else{
                    searchbutton.value=false;
                }
            }            
            return {
                regions,
                ministries,
                provinces,
                municipality,
                officelayers,
                offices,
                employee_id,
                updateMapping,
                orgform,
                loadProvinces,
                loadMunicipality,
                loadBarangay,
                barangays,
                loadOffice,
                municipal,
                barangay,
                offi,
                searchbutton,
                searchbuttonstatus,
                search_data,
                offid,
                organogrs
            }
        }
    }
</script>
<style lang="scss" scoped>
    $checkbox_size: 20;
    .office_layer_map_block{
        display: block;
        position: relative;
        padding-bottom: 50px;
        .office_layer_setup_block{
            display: block;

            & > div{
                display: flex;
                gap: 10px;
                align-items: center;
                .checkbox_block{
                    display: inline-block;
                    width: #{$checkbox_size}px; height: #{$checkbox_size}px;
                    line-height: #{$checkbox_size}px; text-align: center; 
                    font-size: 10px;
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: all 0.4s;

                    & > i{
                        display: none;
                        opacity: 0; line-height: #{$checkbox_size}px;
                        transition: all 0.4s;
                    }

                    &:hover{
                        color: #ccc;
                        border-color: #ccc;

                        & > i.checked{
                            display: block;
                            opacity: 1;
                        }
                    }
                    &.active{
                        color: #006699;
                        border-color: #006699;
                        & > i.checked{
                            display: block;
                            opacity: 1;
                        }

                        &:hover{
                            color: #CD0000;
                            border-color: #CD0000;
                            & > i.checked{
                                display: none;
                                opacity: 0
                            }
                            & > i.remove{
                                display: block;
                                opacity: 1;
                            }
                        }
                    }
                }
                .info_block{
                    &.active{
                        font-weight: 600;
                    }
                }
            }
        }        
    }
</style>