<template>
    <div class="profile_edit_content_block">
        <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
        <trashed-message v-if="project.deleted_at" class="mb-6" @restore="restore"> This Project has been deleted.
        </trashed-message>
        <div class="w-full bg-white rounded-md shadow overflow-hidden edit_form_block">
            <form @submit.prevent="update">
                <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 mt-3 p-4">
                    <text-input v-model="form.project_name" :error="form.errors.project_name" class="sm:col-span-2"
                        label="Project Name" required="true" placeholder="i.e. xyz" />
                    <text-input v-model="form.code" :error="form.errors.code" class="sm:col-span-2" label="Code"
                        required="true" placeholder="i.e " />
                    <select-input v-model="form.service_info_id" :error="form.errors.service_info_id"
                        class="sm:col-span-2" label="Service" required="true">
                        <option value=null>Select Service Info</option>
                        <option v-for="service_info in service_infos" :key="service_info.id" :value="service_info.id">
                            {{ service_info.service.service_name }} ( {{ service_info.service_name }} )
                        </option>
                    </select-input>
                    <select-input v-model="form.type" :error="form.errors.type" class="sm:col-span-2" label="Type"
                        required="true">
                        <option value=null>Select Type</option>
                        <option v-for="type in types" :key="type.id" :value="type.id">
                            {{ type.name }}
                        </option>
                    </select-input>
                    <select-input v-model="form.fiscal_year" :error="form.errors.fiscal_year" class="sm:col-span-2"
                        label="Fiscal year" required="true">
                        <option value=null>Select Fiscal year</option>
                        <option v-for="fiscal_year in fiscal_years" :key="fiscal_year.id" :value="fiscal_year.id">
                            {{ fiscal_year.name }}
                        </option>
                    </select-input>

                    <text-input v-model="form.mooe_amount" :error="form.errors.mooe_amount" class="sm:col-span-2"
                        label="Project MOOE Amount" placeholder="i.e. 100" type="number" min="0" disabled />

                    <text-input v-model="form.co_amount" :error="form.errors.co_amount" class="sm:col-span-2"
                        label="Project CO Amount" placeholder="i.e. 100" type="number" min="0" disabled />
                    <text-input v-model="form.project_amount" :error="form.errors.project_amount" class="sm:col-span-2"
                        label="Project Amount" placeholder="i.e. 100" type="text" disabled />

                    <select-input v-model="form.proposing_ministry" :error="form.errors.proposing_ministry"
                        class="sm:col-span-2" label="Proposing Ministry" required="true">
                        <option :value="null">Select Proposing Ministry</option>
                        <option v-for="ministry in ministries" :key="ministry.id" :value="ministry.id">
                            {{ ministry.ministry_name }}
                        </option>
                    </select-input>
                    <select-input v-model="form.implementing_ministry" :error="form.errors.implementing_ministry"
                        class="sm:col-span-2" label="Implementing Ministry" required="true">
                        <option :value="null">Select Implementing Ministry</option>
                        <option v-for="ministry in ministries" :key="ministry.id" :value="ministry.id">
                            {{ ministry.ministry_name }}
                        </option>
                    </select-input>
                    <textarea-input v-model="form.description" :error="form.errors.description" class="sm:col-span-2"
                        label="Project Description" required="true" placeholder="i.e " />
                    <div class="sm:col-span-3 grid grid-cols-2 gap-y-2 gap-x-1 mb-1">
                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">
                            Attachment <span class="custom-error">*</span>
                        </label>
                        <Button :name="'Add'" :icon="'far fa-plus-circle'" :method="open_attachment_popup"></Button>
                        <div class="col-span-2 grid grid-cols-2 gap-y-2 gap-x-1 mb-1 border-t border-gray-300 p-3">
                            <ul class="flex  flex-wrap gap-2 col-span-2">
                                <li class="flex items-center w-auto c-list px-2 py-1"
                                    v-for="(attachment, n_index) in form.attachments"
                                    v-if="form.attachments.length > 0">
                                    {{ attachment_title(attachment) }}
                                </li>
                                <li v-else class="w-full py-2 italic">
                                    <span class="far fa-circle-info"></span>
                                    No Attachment Selected
                                </li>
                            </ul>
                        </div>
                        <div class="col-span-2">
                            <attachment :attachments="form.attachments" :attachmentopen="attachmentopen"
                                @UpdateAttachmentPopup="update_attachment_popup" @UpdateAttachment="update_attachment"
                                :service_info_id="form.service_info_id">
                            </attachment>
                        </div>
                    </div>

                    <div class="sm:col-span-3 grid grid-cols-2 gap-y-2 gap-x-1 mb-1">
                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Designation</label>
                        <Button :name="'Add'" :icon="'far fa-plus-circle'" :method="open_designation_popup"></Button>
                        <div class="col-span-2 grid grid-cols-2 gap-y-2 gap-x-1 mb-1 border-t border-gray-300 p-3">
                            <ul class="flex  flex-wrap gap-2 col-span-2">
                                <li class="flex items-center w-auto c-list px-2 py-1"
                                    v-for="(designation, n_index) in form.designations"
                                    v-if="Object.keys(form.designations).length > 0">
                                    {{ designation_title(n_index) }}
                                </li>
                                <li v-else class="w-full py-2 italic">
                                    <span class="far fa-circle-info"></span>
                                    No Designation Selected
                                </li>
                            </ul>
                        </div>
                        <div class="col-span-2">
                            <designation :designations="form.designations" :designationopen="designationopen"
                                @UpdateDesignationPopup="update_designation_popup"
                                @UpdateDesignation="update_designation"
                                :implementing_ministry="form.implementing_ministry">
                            </designation>
                        </div>
                    </div>

                    <div class="sm:col-span-6 grid grid-cols-1 gap-y-2 gap-x-1 mb-1">
                        <div class="flex items-center col-span-2">
                            <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                Location <span class="custom-error">*</span>
                            </label>
                            <Button :name="'Add'" :icon="'far fa-plus-circle'" :method="open_location_popup"></Button>
                        </div>
                        <div class="col-span-2 grid grid-cols-2 gap-y-2 gap-x-1 my-1">
                            <ul class="grid grid-cols-4 gap-3 col-span-2">
                                <li class="w-auto rounded-md border border-gray-300"
                                    v-for="(location, n_index) in form.locations" v-if="form.locations.length > 0">
                                    <div class="flex align-middle item_block">
                                        <div class="w-full relative p-2">
                                            <i class="fa-duotone fa-trash right-0 text-red-700 top-1 absulate "
                                                @click="removeLocation(n_index)"></i>
                                            <p class="grid grid-cols-4">
                                                <template v-if="location.barangay_name != null">
                                                    <div class="container-alt flex items-center">
                                                        <BarangayIcon />
                                                    </div>
                                                    <div class="align-middle col-span-3 flex items-center">
                                                        {{ location.barangay_name }},
                                                    </div>
                                                </template>
                                                <template v-if="location.municipality_name != null">
                                                    <div class="container-alt flex items-center">
                                                        <MunicipalityIcon />
                                                    </div>
                                                    <div class="align-middle col-span-3 flex items-center">
                                                        {{ location.municipality_name }},
                                                    </div>
                                                </template>
                                                <template v-if="location.province_name != null">
                                                    <div class="container-alt flex items-center">
                                                        <ProvinceIcon />
                                                    </div>
                                                    <div class="align-middle col-span-3 flex items-center">
                                                        {{ location.province_name }},
                                                    </div>
                                                </template>
                                                <template v-if="location.region_name != null">
                                                    <div class="container-alt flex items-center">
                                                        <RegionIcon />
                                                    </div>
                                                    <div class="align-middle col-span-3 flex items-center">
                                                        {{ location.region_name }}
                                                    </div>
                                                </template>
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li v-else class="w-full border-t border-gray-300  p-4 col-span-4 italic">
                                    <span class="far fa-circle-info"></span>
                                    No location Selected
                                </li>
                            </ul>
                        </div>
                        <div class="col-span-2">
                            <location :locations="form.locations" :locationopen="locationopen"
                                @UpdateLocationPopup="update_location_popup" @UpdateLocation="update_location">
                            </location>
                        </div>
                    </div>
                    <status-toogle-button v-model="form.has_sub_project" :error="form.errors.has_sub_project"
                        label="Has Sub Project" class="sm:col-span-2 flex items-center mt-4"></status-toogle-button>
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Project Status"
                        class="sm:col-span-2 flex items-center mt-4"></status-toogle-button>
                </div>
                <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
                    <button v-if="!project.deleted_at && permission.edit > 0" class="text-red-600 hover:underline"
                        tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
                    <Button v-if="permission.edit > 0" :type="type" :name="head" :url="createUrl"
                        :loading="form.processing"></Button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { Head, Link, useForm } from "@inertiajs/inertia-vue3";
import { ref, toRefs, inject, onMounted, watch } from "vue";
import axios from "axios";
import Layout from "@/Shared/Layout";
import TextInput from "@/Shared/TextInput";
import TextareaInput from "@/Shared/TextareaInput";
import SelectInput from "@/Shared/SelectInput";
import LoadingButton from "@/Shared/LoadingButton";
import RegionIcon from "@/Shared/icon/RegionIcon";
import ProvinceIcon from "@/Shared/icon/ProvinceIcon";
import MunicipalityIcon from "@/Shared/icon/MunicipalityIcon";
import BarangayIcon from "@/Shared/icon/BarangayIcon";
import Breadcrumbs from "@/Shared/Breadcrumbs";
import StatusToogleButton from "@/Shared/StatusToogleButton";
import Button from "@/Shared/Button";
import Attachment from "./components/Attachment";
import Designation from "./components/Designation";
import Location from "./components/Location";

import ToolTip from "@/Shared/ToolTip";
export default {
    components: {
        Head,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        Breadcrumbs,
        StatusToogleButton,
        Button,
        TextareaInput,
        Attachment,
        Designation,
        Location,
        ToolTip,
        RegionIcon,
        ProvinceIcon,
        MunicipalityIcon,
        BarangayIcon
    },
    props: {
        service_infos: Array,
        types: Array,
        ministries: Array,
        permission: Array,
        fiscal_years: Array,
        user_role: Number,
        project: Object,
        designations: Array
    },
    layout: Layout,
    remember: "form",
    setup(props) {
        const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX);
        const { types, service_infos, ministries, user_role, permission, fiscal_years, project, designations } =
            toRefs(props);
        const form = useForm({
            project_name: project.value.project_name,
            code: project.value.code,
            service_info_id: project.value.service_info_id,
            description: project.value.description,
            project_amount: project.value.project_amount,
            type: project.value.type,
            proposing_ministry: project.value.proposing_ministry,
            implementing_ministry: project.value.implementing_ministry,
            fiscal_year: project.value.fiscal_year,
            designations: project.value.designations ? project.value.designations : {},
            attachments: project.value.attachments ? project.value.attachments : [],
            locations: project.value.locations ? project.value.locations : [],
            status: project.value.status,
            has_sub_project: project.value.has_sub_project,
            mooe_amount: project.value.mooe_amount ? project.value.mooe_amount : 0,
            co_amount: project.value.co_amount ? project.value.co_amount : 0,
        });
        const type = ref('edit')
        const title = ref(project.value.project_name)
        const head = ref('Project')
        const url = ref('/backend/projects')
        const createUrl = ref("");
        const designationopen = ref(false);
        const attachmentopen = ref(false);
        const locationopen = ref(false)

        const swal = inject("$swal");


        const update = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${project.value.id}`)
                    title.value = form.project_name
                    swal.fire(
                        'Updated!',
                        'Your infomation has been Updated.',
                        'success'
                    )
                }
            });
        }

        const destroy = () => {
            swal({
                title: 'Are you sure?',
                text: "Do you really want to delete this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.delete(`${url.value}/${role.value.id}`)
                    swal.fire(
                        'Deleted!',
                        'Your infomation has been deleted.',
                        'success'
                    )
                }
            });
        }
        const restore = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to restore this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Resore it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${role.value.id}/restore`)
                    swal.fire(
                        'Restored!',
                        'Your infomation has been Restored.',
                        'success'
                    )
                }
            });
        }

        const update_designation_popup = (val) => {
            designationopen.value = val;
        };

        const update_designation = (val) => {
            form.designations = val;
        };
        const open_designation_popup = () => {
            designationopen.value = true;
        };

        const open_attachment_popup = () => {
            attachmentopen.value = true;
        };
        const update_attachment_popup = (val) => {
            attachmentopen.value = val;
        };
        const update_attachment = (val) => {
            form.attachments = val;
        };
        const designation_list = ref(designations);

        const attachments_list = ref([]);
        const load_attachments = async () => {
            let data = {
                'id': form.service_info_id
            }
            if (form.service_info_id > 0) {
                await axios.post(`/${backendPrefix.value}/serviceinfo/attachments`, data).then(
                    function (response) {
                        attachments_list.value = response.data;
                    }.bind(this)
                );
            } else {
                attachments_list.value = []
            }
        };
        const designation_title = (value) => {
            console.log(value);
            if (value >= 0) {
                const index = designation_list.value.findIndex((p) => p.id == value);
                return designation_list.value[index].designation_name;
            }
        };

        const attachment_title = (value) => {
            const index = attachments_list.value.findIndex((p) => p.id == value);
            if (index >= 0) {
                return attachments_list.value[index].title;
            }
        };

        const removeLocation = (index) => {
            if (index !== -1) {
                form.locations.splice(index, 1);
            }
        };

        const update_location_popup = (val) => {
            locationopen.value = val
        }
        const update_location = (val) => {
            form.location_infos = val
        }

        const open_location_popup = () => {
            locationopen.value = true
        }

        const getFiscalYear = () => {
            let currentdate = new Date().getFullYear();
            form.fiscal_year = currentdate + 1;
        }



        watch(() => form.attachments, () => {
            load_attachments();
        }, { deep: true }
        );

        watch(() => form.service_info_id, (oldVal, newVal) => {
            load_attachments();
            if (oldVal != newVal) {
                form.attachments = []
            }
        }, { deep: true }
        );

        watch(() => form.implementing_ministry, (oldVal, newVal) => {

            if (oldVal != newVal) {
                form.designations = {}
            }
        }, { deep: true }
        );

        onMounted(() => {
            load_attachments();
            getFiscalYear();
        });


        return {
            form,
            type,
            types,
            service_infos,
            ministries,
            designations,
            title,
            url,
            createUrl,
            update,
            destroy,
            restore,
            permission,
            designationopen,
            open_designation_popup,
            update_designation_popup,
            update_designation,
            designation_list,
            designation_title,
            attachmentopen,
            open_attachment_popup,
            update_attachment_popup,
            update_attachment,
            attachments_list,
            load_attachments,
            backendPrefix,
            attachment_title,
            user_role,
            fiscal_years,
            locationopen,
            open_location_popup,
            update_location_popup,
            update_location,
            removeLocation,
            project,
            head
        };
    },
};
</script>
<style lang="scss" scoped>
$button_height: 36;

.profile_edit_content_block {
    display: block;

    .edit_form_block {
        background-color: #ffffff80;

        textarea,
        select {
            width: 100%;
            border: 1px solid #ddd;
            padding: 5px 10px;
            border-radius: 5px;
        }

        .item_block:hover>div i {
            display: flex !important;
        }

        i {
            width: 30px;
            text-align: center;
            transition: all 0.4s;
            position: absolute;
            margin-right: 0px !important;
            top: 5px;
            right: -14px;
            font-size: 10px;
            display: none;
            cursor: pointer;
            z-index: 999;

            &.active {
                display: flex !important;
            }

            &.del {
                position: absolute;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #fff;
                border-radius: 50%;
                box-shadow: 0 0 5px #999;
                border: 1px solid #ddd;
                cursor: pointer;
                top: -5px;
                right: -5px;
            }
        }

        .err_msg {
            border-color: #cd0000 !important;
        }

        .c-list {
            background: #666;
            color: #fff;
            border-radius: 15px;
            font-size: 11px;
            max-height: 24px;
        }
    }
}

.save_btn,
.cancel_btn {
    height: #{$button_height}px;
    background-color: #0f8050;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;

    &.cancel_btn {
        border: 1px solid #cd0000;
        color: #cd0000;
        background-color: #fff;
    }
}

.dark {
    .profile_edit_content_block {

        input,
        textarea,
        select {
            background-color: #222;
        }
    }
}

.password_block,
.confirm_password_block {
    p {
        align-items: baseline;
        color: #666;
        display: flex;
        gap: 5px;
        font-size: 10px !important;

        span {
            color: gold !important;
            font-size: 10px !important;
        }
    }
}
</style>
