<template>
    <div class="profile_edit_content_block">
        <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
        <trashed-message v-if="agent.deleted_at" class="mb-6" @restore="restore"> This agent has been
            deleted.
        </trashed-message>
        <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="update">
                <div class="grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-4 mt-3 p-4">
                    <text-input v-model="form.name" :error="form.errors.name" class="sm:col-span-2" label="Name"
                        required="true" placeholder="i.e." />
                    <text-input v-model="form.agent_no" :error="form.errors.agent_no" class="sm:col-span-2"
                        label="Agent ID" required="true" placeholder="i.e 1234" />
                    <text-input v-model="form.father_name" :error="form.errors.father_name" class="sm:col-span-2"
                        label="Father's Name" placeholder="i.e John Ivan" />
                    <text-input v-model="form.mother_name" :error="form.errors.mother_name" class="sm:col-span-2"
                        label="Mother's Name" placeholder="i.e Kate William" />
                    <text-date-picker v-model="form.dob" :error="form.errors.dob" class="sm:col-span-2"
                        label="Date of Birth" placeholder="i.e 01-01-2022" />

                    <text-input v-model="form.nid" :error="form.errors.nid" class="sm:col-span-2"
                        label="Government Issued ID" placeholder="i.e 1234567890123" required="true" />

                    <select-input v-model="form.gender_id" :error="form.errors.gender_id" class="sm:col-span-2"
                        label="Gender" required="true">
                        <option value=''>Select Gender</option>
                        <option v-for="gender in genders" :key="gender.id" :value='gender.id'>{{ gender.gender_name }}
                        </option>
                    </select-input>
                    <select-input v-model="form.religion_id" :error="form.errors.religion_id" class="sm:col-span-2"
                        label="Religion" required="true">
                        <option value=''>Select Religion</option>
                        <option v-for="religion in religions" :key="religion.id" :value='religion.id'>
                            {{ religion.name }}
                        </option>
                    </select-input>
                    <text-input-email v-model="form.email" :error="form.errors.email" :type="email"
                        class="sm:col-span-2" label="Email" required="true" placeholder="i.e example@test.com" />
                    <text-input v-model="form.password" :error="form.errors.password" class="sm:col-span-2"
                        label="Password" required="true" placeholder="i.e *****" />
                    <text-input v-model="form.mobile" :error="form.errors.mobile" :type="text" class="sm:col-span-2"
                        label="Mobile" required="true" placeholder="i.e +63-2-1234-5678" />
                    <select-input v-model="form.marital_status_id" :error="form.errors.marital_status_id"
                        class="sm:col-span-2" label="Marital Status">
                        <option value=''>Select Marital Status</option>
                        <option v-for="marital_status in marital_statuses" :key="marital_status.id"
                            :value='marital_status.id'>{{ marital_status.marital_statuse_name }}</option>
                    </select-input>
                    <text-date-picker v-model="form.date_of_joining" :error="form.errors.date_of_joining"
                        class="sm:col-span-2" label="Date of Joining" required="true" placeholder="i.e 01-01-2022" />
                    <select-input v-model="form.queue_office_id" :error="form.errors.queue_office_id"
                        class="sm:col-span-2" label="Office" required="true">
                        <option value=''>Select Office</option>
                        <option v-for="office in offices" :key="office.id" :value='office.id'>{{ office.office_name }}
                        </option>
                    </select-input>
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status"
                        class="sm:col-span-2 "></status-toogle-button>
                </div>
                <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
                    <Button v-if="permission.edit > 0" :type="type" :name="head" :url="createUrl"
                        :loading="form.processing"></Button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject, onMounted, watch } from 'vue'
import axios from 'axios';
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import TextareaInput from '@/Shared/TextareaInput'
import TextDatePicker from '@/Shared/TextDatePicker'
import TextInputEmail from '@/Shared/TextInputEmail'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import DeleteIcon from '@/Shared/DeleteIcon'
import Button from '@/Shared/Button'
export default {
    components: {
        Head,
        Icon,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        TrashedMessage,
        StatusToogleButton,
        Breadcrumbs,
        DeleteIcon,
        Button,
        TextareaInput,
        TextDatePicker,
        TextInputEmail
    },
    layout: Layout,
    props: {
        agent: Object,
        permission: Object,
        genders: Array,
        religions: Array,
        marital_statuses: Array,
        offices: Array,
    },
    remember: 'form',
    setup(props) {
        const { agent, permission, genders, religions, marital_statuses, offices } = toRefs(props)
        const type = ref('edit')
        const title = ref(agent.value.name)
        const head = ref('Agent')
        const url = ref('/backend/queue-agents')
        const createUrl = ref('')
        const swal = inject('$swal')
        const elm = ref(this)
        const form = useForm({
            name: agent.value.name,
            gender_id: agent.value.gender_id,
            date_of_joining: agent.value.date_of_joining,
            agent_no: agent.value.agent_no,
            father_name: agent.value.father_name,
            mother_name: agent.value.mother_name,
            nid: agent.value.nid,
            dob: agent.value.dob,
            religion_id: agent.value.religion_id,
            email: agent.value.email,
            mobile: agent.value.mobile,
            marital_status_id: agent.value.marital_status_id,
            status: agent.value.status,
            queue_office_id: agent.value.queue_office_id,
            password: null,
        })
        const update = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${agent.value.id}`)
                    title.value = form.name
                    swal.fire(
                        'Updated!',
                        'Your infomation has been Updated.',
                        'success'
                    )
                }
            });
        }
        const destroy = () => {
            swal({
                title: 'Are you sure?',
                text: "Do you really want to delete this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.delete(`${url.value}/${agent.value.id}`)
                    swal.fire(
                        'Deleted!',
                        'Your infomation has been deleted.',
                        'success'
                    )
                }
            });
        }
        const restore = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to restore this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Resore it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${agent.value.id}/restore`)
                    swal.fire(
                        'Restored!',
                        'Your infomation has been Restored.',
                        'success'
                    )
                }
            });
        }
        const set_photo = (val) => {
            form.icon_img = val
        }

        return {
            form,
            agent,
            type,
            title,
            head,
            url,
            createUrl,
            update,
            destroy,
            restore,
            swal,
            permission,
            set_photo,
            genders,
            religions,
            marital_statuses,
            offices
        }
    }
}
</script>
<style lang="scss" scoped>
$button_height: 36;

.profile_edit_content_block {
    display: block;

    .edit_form_block {
        background-color: #ffffff80;

        input,
        textarea,
        select {
            width: 100%;
            border: 1px solid #ddd;
            padding: 5px 10px;
            border-radius: 5px;
        }

        i {
            display: none;
            width: 30px;
            text-align: center;
            transition: all 0.4s;
            color: #888;
            position: absolute;
            margin-left: 265px !important;
            margin-top: -23px;

            &.active {
                display: block;
            }
        }

        .err_msg {
            border-color: #CD0000 !important
        }
    }
}

.save_btn,
.cancel_btn {
    height: #{$button_height}px;
    background-color: #0f8050;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;

    &.cancel_btn {
        border: 1px solid #CD0000;
        color: #CD0000;
        background-color: #fff;
    }
}


.dark {
    .profile_edit_content_block {

        input,
        textarea,
        select {
            background-color: #222;
        }
    }
}

.password_block,
.confirm_password_block {
    p {
        align-items: baseline;
        color: #666;
        display: flex;
        gap: 5px;
        font-size: 10px !important;

        span {
            color: gold !important;
            font-size: 10px !important;
        }
    }
}
</style>
