<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" />
      <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="store">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.amenity_title" :error="form.errors.amenity_title" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Amenity" required="true" placeholder="i.e Bathtub"/>
            <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Slug" required="true" placeholder="i.e bathtub"/>
            <select-input v-model="form.group_id" :error="form.errors.group_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Group" >
              <option value=null >Select Group</option>
              <option v-for="amenityGroup in amenityGroups" :key="amenityGroup.id" :value='amenityGroup.id'>{{ amenityGroup.group_title }}</option>
            </select-input>
            <text-input v-model="form.info" :error="form.errors.info" class="pb-8 pr-6 w-full lg:w-1/2" label="Info" placeholder="i.e 1"/>
            <text-input v-model="form.hierarchy" :error="form.errors.hierarchy" class="pb-8 pr-6 w-full lg:w-1/2" label="Hierarchy" placeholder="i.e 1" type="number" oninput="this.value = Math.abs(this.value)" min="0"/>
            <file-input v-model="form.image_path" :error="form.errors.image_path" class="pb-8 pr-6 w-full lg:w-1/2" type="file" accept="image/*" label="Image" />
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Amenity" class="pb-8 pr-6 w-full lg:w-1/2"></status-toogle-button>
          </div>
          <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
        </form>
      </div>
    </div>

  </template>

  <script>
  import axios from 'axios';
  import { ref, toRefs,inject } from 'vue'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Button from '@/Shared/Button'
  import FileInput from '@/Shared/FileInput'
  export default {
    props: {
      amenityGroups: Array,
    },
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      Breadcrumbs,
      StatusToogleButton,
      Button,
      FileInput
    },
    layout: Layout,
    remember: 'form',
    setup(props){
      const { amenityGroups } = toRefs(props)
      const form = useForm({
        pid: null,
        group_id: null,
        amenity_title: null,
        slug: null,
        hierarchy: null,
        info: null,
        status: true,
        image_path:null
      })
      // const regions = ref([])
      const type = ref('create')
      const title = ref('Amenity')
      const url = ref('/backend/amenities')
      const createUrl = ref('')
      const swal = inject('$swal')
      const store = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}`, {forceFormData: true,})
              swal.fire(
                'Added!',
                'Your infomation has been Added.',
                'success'
              )
            }
          });

      }
      return {
        form,
        amenityGroups,
        type,
        title,
        url,
        createUrl,
        store
      }
    }
  }
  </script>
