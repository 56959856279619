<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url" />
        <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="store">
                <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                    <text-input v-model="form.region_name" :error="form.errors.region_name"
                        class="pb-8 pr-6 w-full lg:w-1/1" label="Name Of Region" required="true" />
                    <text-input v-model="form.code" :error="form.errors.code" class="pb-8 pr-6 w-full lg:w-1/1"
                        label="Code Of Region" />
                    <file-input v-model="form.logo" :error="form.errors.logo" class="pb-8 pr-6 w-full lg:w-1/1"
                        type="file" accept="image/*" label="Logo" />
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status Of Region"
                        class="pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>
                </div>
                <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
                    <loading-button :loading="form.processing" class="btn-indigo" type="submit"><i
                            class="fa fa-check-circle"></i> Create Region</loading-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { ref, toRefs, inject } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Button from '@/Shared/Button'
import FileInput from '@/Shared/FileInput'
export default {
    props: {
        regions: Array,
    },
    components: {
        Head,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        Breadcrumbs,
        StatusToogleButton,
        Button,
        FileInput
    },
    layout: Layout,
    remember: 'form',
    setup(props) {
        const { regions } = toRefs(props)
        const form = useForm({
            region_name: null,
            code: null,
            status: true,
            logo: null
        })
        // const regions = ref([])
        const type = ref('create')
        const title = ref('Region')
        const url = ref('/backend/regions')
        const createUrl = ref('')
        const swal = inject('$swal')
        const store = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.post(`${url.value}`, { forceFormData: true, })
                    swal.fire(
                        'Added!',
                        'Your infomation has been Added.',
                        'success'
                    )
                }
            });

        }
        return {
            form,
            regions,
            type,
            title,
            url,
            createUrl,
            store
        }
    }
}
</script>
