<template>
    <div class="organogram_map_block">
        <ContentHeaderSection :title="'Office Organogram Setup'" :icon="'fa fa-cogs'" />
        <div class="p-5 organogram_setup_block">
            <template v-for="(content,s_index) in data">
                <!-- {{ content }} -->
                <OfficeSection :index="s_index" :content="content.section.name" @addSection="add_section" @updateSection="update_section" @delSection="del_section" />
                <div class="designation_tree ml-5">
                    <template v-for="(designation,d_index) in content.designations">
                        <OfficeDesignation :index="s_index" :_index="d_index" :section_id="content.section.id" :content="designation.name" @addDesignation="add_designation" @updateDesignation="update_designation" @delDesignation="del_designation" />
                    </template>
                    <OfficeDesignation :index="s_index" :section_id="content.section.id" :content="''" @addDesignation="add_designation" @updateDesignation="update_designation" />
                </div>
            </template>
            <OfficeSection :content="''" @addSection="add_section" @updateSection="update_section" />
        </div>
        <FloatingActionBtn v-if="(data.length > 0 || organogram.length > 0)" :loader="preloader" :icon="'fa fa-check'" @dataSubmit="submit_data" />
    </div>
</template>
<script>
    import { ref, toRefs } from "vue"
    import axios from "axios"    
    import OfficeSection from './OfficeSection'
    import OfficeDesignation from './OfficeDesignation'
    import ContentHeaderSection from "@/Shared/ContentHeaderSection"
    import FloatingActionBtn from "@/Shared/Buttons/FloatingActionBtn"
    export default {
        name: "OfficeOrganogram",
        props: {
            organogram: Array,
            office_id: Number
        },
        components: {            
            OfficeSection,
            OfficeDesignation,
            ContentHeaderSection,
            FloatingActionBtn
        },
        setup(props){
            const { organogram, office_id } = toRefs(props)
            const data = ref(organogram.value.length?organogram.value:[])            
            const preloader = ref(false)
            const add_section = (content) => {
                // //console.log('content', content)
                let obj = {
                    section: {
                        name: content.section_name,
                        id: content.id
                    },
                    designations: []
                }
                data.value.push(obj)
            }
            const update_section = (s_index,content) => {
                data.value[s_index].section = {
                    id: content.id,
                    name: content.section_name
                }                                        
            }
            const del_section = (s_index) => {
                // //console.log(s_index)
                data.value.splice(s_index, 1)
            }
            const add_designation = (s_index,content) => {
                let obj = {
                    id: content.id,
                    name: content.designation_name
                }
                data.value[s_index].designations.push(obj)
            }
            const del_designation = (s_index,d_index) => {
                data.value[s_index].designations.splice(d_index, 1)
            }
            const update_designation = (s_index,d_index,content) => {
                data.value[s_index].designations[d_index] = {
                    id: content.id,
                    name: content.designation_name
                }
            }
            const submit_data = async () => {
                //console.log('Office organogram data', data)
                const data_ids = ref({})
                await data.value.map( async v => {
                    data_ids.value[v.section.id] = {}
                    await v.designations.map(d => {
                        data_ids.value[v.section.id][d.id] = true
                    })                    
                })

                let formData = {
                    office_id: office_id.value,
                    organogram: data.value,
                    data_ids: data_ids.value
                }
                preloader.value = true
                axios.post('/backend/office-organogram-map', {
                    data: formData
                }).then( async (response) => {
                    //console.log(response)                    

                    preloader.value = false
                }).catch(error => {
                    preloader.value = false
                    //console.log(error.response.data.error)
                })
            }

            return {
                data,
                preloader,
                add_section,
                update_section,
                del_section,
                add_designation,
                update_designation,
                del_designation,
                submit_data
            }
        }        
    }
</script>
<style lang="scss" scoped>
    .organogram_map_block{
        display: block;
        position: relative;
        padding-bottom: 50px;
        .organogram_setup_block{
            display: block;
            min-height: 250px;
            .designation_tree{
                position: relative;
                margin-left: 9px;
                &::before{
                    position: absolute;
                    content: "";
                    width: 5px;
                    height: calc(100% - 8px);
                    border-left: 1px solid #999;
                    border-bottom: 1px solid #999;
                    border-radius: 0 0 0 5px;
                    left: 0;
                    top: -6px;
                }
                & > div{
                    position: relative;
                    padding-left: 18px;

                    &::before{
                        position: absolute;
                        content: "";
                        width: 18px;
                        height: 5px;                
                        border-bottom: 1px solid #999;
                        border-left: 1px solid #999;
                        border-radius: 0 0 0 5px;
                        left: 0px;
                        top: 11px;
                    }            
                }
            }            
        }        
    }
</style>