<template>
    <TransitionRoot as="template" :show="detailopen">
        <Dialog as="div" class="relative z-[9999]" @click="update_popup(false)">
            <div class="fixed inset-0" />
            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <TransitionChild as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full" enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-0" leave-to="translate-x-full">
                            <DialogPanel class="pointer-events-auto w-screen max-w-md">
                                <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                    <div class="h-0 flex-1 overflow-y-auto">
                                        <div class="bg-indigo-700 px-4 py-6 sm:px-6">
                                            <div class="flex items-center justify-between">
                                                <DialogTitle class="text-base font-semibold leading-6 text-white">
                                                    Details
                                                </DialogTitle>
                                                <div class="ml-3 flex h-7 items-center">
                                                    <button type="button"
                                                        class="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                        @click="update_popup(false)">
                                                        <span class="absolute -inset-2.5" />
                                                        <span class="sr-only">Close panel</span>
                                                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="mt-1">
                                                <p class="text-sm text-indigo-300">Add Details.</p>
                                            </div>
                                        </div>
                                        <div class="flex flex-1 flex-col justify-between">
                                            <div class="divide-y divide-gray-200 px-4 sm:px-6">
                                                <div class="space-y-6 pb-5 pt-6">
                                                    <div
                                                        class="mt-1 rounded-md border border-gray-300 p-3 max-h-[485px] overflow-y-auto">
                                                        <div v-if="message"
                                                            class="flex items-center gap-2 message_block error"><i
                                                                class="fa fa-check"></i> {{ message }}<i
                                                                class="fa fa-times close_btn" @click="remove_msg"></i>
                                                        </div>
                                                        <form @submit.prevent="data_submit">
                                                            <select-input v-model="form.variable_id"
                                                                :error="form.errors.variable_id"
                                                                class="pb-8 pr-6 w-full " label="Variable"
                                                                required="true">
                                                                <option value=null>Select Variable</option>
                                                                <option v-for="variable in variable_list"
                                                                    :key="variable.id" :value='variable.id'>{{
                                                                        variable.title }}
                                                                </option>
                                                            </select-input>
                                                            <select-input v-model="form.amount_type"
                                                                :error="form.errors.amount_type"
                                                                class="pb-8 pr-6 w-full " label="Amount Type"
                                                                required="true">
                                                                <option value=null>Select Amount Type
                                                                </option>
                                                                <option v-for="type in type_list" :key="type.id"
                                                                    :value='type.id'>{{
                                                                        type.title }}
                                                                </option>
                                                            </select-input>

                                                            <select-input v-model="form.recurring"
                                                                :error="form.errors.recurring" class="pb-8 pr-6 w-full "
                                                                label="Recurring" required="true">
                                                                <option value=null>Select Recurring</option>
                                                                <option v-for="recurring in recurring_list"
                                                                    :key="recurring.id" :value='recurring.id'>{{
                                                                        recurring.title }}
                                                                </option>
                                                            </select-input>
                                                            <template v-if="form.recurring == 'yes'">
                                                                <select-input v-model="form.recurring_type"
                                                                    :error="form.errors.recurring_type"
                                                                    class="pb-8 pr-6 w-full " label="Recurring Type"
                                                                    required="true">
                                                                    <option value=null>Select Recurring Type</option>
                                                                    <option v-for="recurring in recurring_type_list"
                                                                        :key="recurring.id" :value='recurring.id'>{{
                                                                            recurring.title }}
                                                                    </option>
                                                                </select-input>

                                                                <text-input v-model="form.recurring_count"
                                                                    :error="form.errors.recurring_count"
                                                                    class="pb-8 pr-6 w-full "
                                                                    label="Number Of Recurring" required="true"
                                                                    placeholder="i.e " /> <text-input
                                                                    v-model="form.recurring_amount"
                                                                    :error="form.errors.recurring_amount"
                                                                    class="pb-8 pr-6 w-full " label="Recurring Amount"
                                                                    required="true" placeholder="i.e " />
                                                            </template>
                                                            <template v-else>
                                                                <text-input v-model="form.amount"
                                                                    :error="form.errors.amount"
                                                                    class="pb-8 pr-6 w-full " label="One Time Amount"
                                                                    required="true" placeholder="i.e " />
                                                            </template>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-shrink-0 justify-end px-4 py-4">
                                        <button type="button" @click="saveDetailData()"
                                            class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
                                    </div>
                                </form>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>


import axios from 'axios';
import { ref, toRefs, inject, onMounted, watch } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import Button from '@/Shared/Button'

const props = defineProps(['detailopen', 'details']);

const details = ref(props.details)
const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
const edit = ref(false)
const swal = inject('$swal')

const preloader = ref(false)
const ispackage = ref(false)
const type_list = ref([
    {
        id: 'fixed',
        title: 'Fixed'
    },
    {
        id: 'percentage',
        title: 'Percentage'
    }
])
const recurring_list = ref([
    {
        id: 'yes',
        title: 'Yes'
    },
    {
        id: 'no',
        title: 'No'
    }
])

const recurring_type_list = ref([
    {
        id: 'day',
        title: 'Day'
    },
    {
        id: 'week',
        title: 'Week'
    },
    {
        id: 'month',
        title: 'Month'
    },
    {
        id: 'year',
        title: 'Year'
    }
])

const message = ref('');
const form = useForm({
    config_id: null,
    variable_id: null,
    recurring: null,
    recurring_type: null,
    recurring_count: null,
    amount_type: null,
    recurring_amount: null,
    amount: null,
    status: null
})

const emit = defineEmits(['UpdateDetailPopup ', 'UpdateDetail'])


const update_popup = (status) => {
    emit('UpdateDetailPopup', status)
}

const saveDetailData = () => {
    if (form.variable_id > 0) {

        if (form.variable_id <= 0) {
            message.value = 'Please fill variable filed';
            return;
        }
        if (form.recurring == null) {
            message.value = 'Please fill type filed';
            return;
        }
        if (form.recurring == 'no' && form.amount <= 0) {
            message.value = 'Please fill Total amount filed';
            return;
        }

        if (form.recurring == 'yes' && form.recurring_amount <= 0) {
            message.value = 'Please fill Total amount filed';
            return;
        }
        let obj = {
            id: null,
            config_id: form.config_id,
            variable_id: form.variable_id,
            recurring: form.recurring,
            recurring_type: form.recurring_type,
            recurring_count: form.recurring_count,
            recurring_amount: form.recurring_amount,
            type: form.amount_type,
            amount: form.amount,
            status: form.status,
        }
        emit('UpdateDetail', obj)
        emit('UpdateDetailPopup', false)
        form.config_id = null
        form.variable_id = null
        form.recurring = null
        form.recurring_type = null
        form.recurring_count = null
        form.recurring_amount = null
        form.amount = null
        form.status = null
        form.amount_type = null
    } else {
        message.value = 'Please fill mendatory filed'
        return
    }
}
const remove_msg = () => {
    message.value = ''
}
const variable_list = ref([])
const load_type = async () => {
    await axios.post(`/${backendPrefix.value}/payment-variables/activity-variables`)
        .then(function (response) {
            ////console.log(response);
            variable_list.value = response.data
        }.bind(this));
}
onMounted(() => {
    load_type()
})
</script>
<style lang="scss" scoped>
.message_block {
    position: fixed;
    z-index: 9999;
    left: -300px;
    bottom: 25px;
    color: #fff;
    height: 40px;
    padding: 0 15px;
    border-radius: 5px;
    transition: all 0.4s;

    &>.close_btn {
        position: absolute;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        background-color: #941212;
        border: 1px solid #fff;
        border-radius: 50%;
        cursor: pointer;
        right: 5px;
        top: -10px;
        font-size: 8px;
        z-index: 5;
    }

    &.error {
        left: auto;
        right: 10%;
        background-color: #fc4949;
    }
}
</style>
