<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="max-w-5xl bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="store">
                <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                    <text-input v-model="form.name" :error="form.errors.name" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Name of Template" required="true" placeholder="i.e Business Permit" />
                    <select-input-function v-model="form.service_id" :error="form.errors.service_id"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Service" required="true" :action="load_field">
                        <option value=null>Select Service</option>
                        <option v-for="service in services" :key="service.id" :value='service.id'>{{
            service.service_name }}</option>
                    </select-input-function>
                    <div class="pb-8 pr-6 w-full lg:w-1/1">
                        <label class="form-label" for="template">Content <span class="custom-error">*</span></label>
                        <label for="smsbody">Keyword:
                            <span v-for="(value, name, index) in selected_field" v-html="'{{' + name + '}}'"></span>
                        </label>
                        <br>
                        <div class="my-5 edit_form_block relative">
                                <i @click="openModal" class="fa-regular fa-pen-to-square right-0 text-green-700 top-1 absulate del"></i>
                            <div
                                class="mx-auto max-w-7xl px-0 sm:px-6 lg:px-8 min-h-[calc(60vh_-_10rem)] h-auto border overflow-y-auto p-4 rounded">
                                <div v-html="form.template"></div>
                            </div>
                        </div>
                        <Modal :data="form.template" :isOpen="isOpen"
                        @UpdatePopup="update_popup" @UpdateData="update_data" ></Modal>
                    </div>
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Template Status"
                        class="pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>
                </div>
                <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
            </form>
        </div>
    </div>
</template>

<script>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import axios from 'axios';
import { ref, toRefs, inject } from 'vue'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInputFunction from '@/Shared/SelectInputFunction'
import LoadingButton from '@/Shared/LoadingButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Button from '@/Shared/Button'
import TextareaInput from '@/Shared/TextareaInput'
import Modal from '@/Shared/Modal'

import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
} from '@headlessui/vue'
export default {
    components: {
        Head,
        Link,
        LoadingButton,
        SelectInputFunction,
        TextInput,
        Breadcrumbs,
        StatusToogleButton,
        Button,
        TextareaInput,
        TransitionRoot,
        TransitionChild,
        Modal,
        Dialog,
        DialogPanel,
        DialogTitle
    },
    layout: Layout,
    remember: 'form',
    props: {
        services: Array
    },
    setup(props) {
        const { services } = toRefs(props)
        const form = useForm({
            name: null,
            template: null,
            status: true,
            service_id: null
        })
        const type = ref('create')
        const title = ref('Certificate Template')
        const url = ref('/backend/certificate-templates')
        const createUrl = ref('')
        const inits = ref({
            selector: 'textarea#open-source-plugins',
            plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
            imagetools_cors_hosts: ['picsum.photos'],
            menubar: 'file edit view insert format tools table help',
            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
            toolbar_sticky: true,
            autosave_ask_before_unload: true,
            autosave_interval: '30s',
            autosave_prefix: '{path}{query}-{id}-',
            autosave_restore_when_empty: false,
            autosave_retention: '2m',
            image_advtab: true,

            importcss_append: true,
            file_picker_callback: function (callback, value, meta) {
                if (meta.filetype === 'file') {
                    callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
                }
                if (meta.filetype === 'image') {
                    callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
                }
                if (meta.filetype === 'media') {
                    callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
                }
            },
            templates: [
                { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
                { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
                { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
            ],
            template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
            template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
            height: 600,
            image_caption: true,
            quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
            noneditable_noneditable_class: 'mceNonEditable',
            toolbar_mode: 'sliding',
            contextmenu: 'link image imagetools table',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        })
        const selected_field = ref({})
        const swal = inject('$swal')
        const store = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.post(`${url.value}`, { forceFormData: true, })
                    swal.fire(
                        'Added!',
                        'Your infomation has been Added.',
                        'success'
                    )
                }
            });

        }
        const load_field = async (e) => {

            if (e.target.value > 0) {
                let data = {
                    service_id: e.target.value
                }
                await axios.post('../service/get_certificate_info', data)
                    .then(function (response) {
                        //console.log(response);
                        selected_field.value = response.data.data;
                    }.catch(err => {
                        //console.log(err);
                    }).bind(this));
            } else {
                selected_field.value = {};
            }
        }
        const isOpen = ref(false)
        const openModal = () => {
            isOpen.value = true
        }
        const update_popup = (val) => {
            isOpen.value = val
        }
        const update_data = (val) => {
            form.template = val
        }
        return {
            form,
            type,
            title,
            url,
            createUrl,
            store,
            services,
            inits,
            selected_field,
            load_field,
            isOpen,
            openModal,
            update_popup,
            update_data
        }
    }
}
</script>
<style>
     .edit_form_block i.del {
        position: absolute;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-radius: 50%;
        box-shadow: 0 0 5px #999;
        border: 1px solid #ddd;
        cursor: pointer;
        top: -5px;
        right: -5px;
    }
</style>
