<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="flex flex-wrap items-center justify-between mb-4 gap-2">
            <search-filter v-model="form.search" class="mr-4 w-full max-w-xl" @reset="reset">
                <template v-slot:filter>
                    <select v-model="form.trashed" class="form-select  w-28">
                        <option :value="null">Filter</option>
                        <option value="with">With Trashed</option>
                        <option value="only">Only Trashed</option>
                    </select>
                </template>
                <template v-slot:page>
                    <select v-model="form.length" class="form-select  w-20">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                    </select>
                </template>
                <template v-slot:status>
                    <select v-model="form.status" class="form-select w-32">
                        <option :value="null">Status</option>
                        <option value="4">Non Verified</option>
                        <option value="1">Pending</option>
                        <option value="2">Verified</option>
                        <option value="3">Reject</option>
                        <option value="6">Permanent Reject</option>
                    </select>
                    <select v-model="form.govt_employee_status" class="form-select w-32">
                        <option :value=null>Type</option>
                        <option value="1">Govt. Employee</option>
                        <option value="2">Citizen</option>
                    </select>
                    <select v-model="form.type" class="form-select w-32">
                        <option :value="null">Select One</option>
                        <option value="1">Only Email</option>
                        <option value="2">Only Mobile</option>
                        <option value="3">Only Username</option>
                    </select>
                </template>
                <template v-slot:stat>
                    <div class="flex flex-wrap gap-1 justify-center">
                        <span
                            class="px-2 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600 hover:shadow-lg focus:bg-blue-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center primary">
                            Total
                            <span
                                class="inline-block py-1 px-1.5 leading-none text-center whitespace-nowrap align-baseline font-bold primary-badge text-white rounded ml-2">{{
                                    stat.total
                                }}</span>
                        </span>
                        <button type="button"
                            class="px-2 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600 hover:shadow-lg focus:bg-blue-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center indigo">
                            Not Verified
                            <span
                                class="inline-block py-1 px-1.5 leading-none text-center whitespace-nowrap align-baseline font-bold indigo-badge text-white rounded ml-2">{{
                                    stat.NotVerified
                                }}</span>
                        </button>

                        <button type="button"
                            class=" px-2 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600 hover:shadow-lg focus:bg-blue-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center warning">
                            Pending
                            <span
                                class="inline-block py-1 px-1.5 leading-none text-center whitespace-nowrap align-baseline font-bold warning-badge text-white rounded ml-2">{{
                                    stat.Pending
                                }}</span>
                        </button>

                        <button type="button"
                            class="px-2 py-2.5 bg-lime-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-lime-600 hover:shadow-lg focus:bg-lime-600focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center success">
                            Verified
                            <span
                                class="inline-block py-1 px-1.5 leading-none text-center whitespace-nowrap align-baseline font-bold  text-white rounded ml-2 success-badge">{{
                                    stat.Verified
                                }}</span>
                        </button>

                        <button type="button"
                            class="px-2 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600 hover:shadow-lg focus:bg-blue-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center reject">
                            Rejected
                            <span
                                class="inline-block py-1 px-1.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-red-600 text-white rounded ml-2 reject-badge">{{
                                    stat.Rejected
                                }}</span>
                        </button>
                    </div>
                </template>
            </search-filter>
        </div>

        <TabGroup>
            <div class="border-b border-gray-200">
                <TabList class="-mb-px flex space-x-8" aria-label="Tabs">
                    <Tab as="template" v-slot="{ selected }">
                        <button
                            :class="[selected ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700', 'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium']">
                            Profile Verification
                            <span
                                :class="[selected ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-900', 'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block']">{{
                                    stat.Pending }}</span>
                        </button>
                    </Tab>

                    <Tab as="template" v-slot="{ selected }">
                        <button
                            :class="[selected ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700', 'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium']">
                            Employee Profile Update Request
                            <span
                                :class="[selected ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-900', 'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block']">{{
                                    empStat.updatetotal }}</span>
                        </button>
                    </Tab>
                </TabList>
            </div>
            <TabPanels>
                <TabPanel class="mb-2">
                    <div class="bg-white rounded-md shadow overflow-x-auto">
                        <table class="w-full whitespace-nowrap">
                            <thead>
                                <tr class="text-left font-bold">
                                    <th class="pb-2 pt-3 px-3 item-center text-center">Sl.</th>
                                    <th class="pb-2 pt-3 px-3">Info</th>
                                    <th class="pb-2 pt-3 px-3">Created By</th>
                                    <th class="pb-2 pt-3 px-3 item-center text-center">Request Date</th>
                                    <th class="pb-2 pt-3 px-3 item-center text-center">Approval Date</th>
                                    <th class="pb-2 pt-3 px-3 item-center ">Type</th>
                                    <th class="pb-2 pt-3 px-3 item-center text-center">Verify</th>
                                    <th class="pb-2 pt-3 px-3 item-center text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(citizen, index) in citizens.data" :key="citizen.id"
                                    class="hover:bg-gray-100 focus-within:bg-gray-100">
                                    <td class="border-t item-center text-center px-3">
                                        {{ (index + citizens.from) }}
                                    </td>
                                    <td class="border-t">
                                        <p class="bold flex font-14 font-bold items-center">
                                            {{ citizen.first_name }}
                                            {{ citizen.middle_name }}
                                            {{ citizen.last_name }}
                                            {{ citizen.surfix }}
                                        </p>
                                        <p class="flex items-center ">
                                            Mobile: {{ citizen.mobile }}
                                        </p>
                                        <p class="flex items-center ">
                                            Email: {{ citizen.email }}
                                        </p>
                                        <p class="flex items-center ">
                                            User Name: {{ citizen.username }}
                                        </p>
                                        <icon v-if="citizen.deleted_at" name="trash"
                                            class="flex-shrink-0 ml-2 w-3 h-3 fill-gray-400" />
                                    </td>
                                    <td class="border-t">
                                        <span v-if="citizen.username">
                                            {{ citizen.createby }}
                                        </span>
                                    </td>
                                    <td class="border-t  px-3 item-center text-center">
                                        {{ citizen.giiu_date }}
                                    </td>
                                    <td class="border-t  px-3 item-center text-center">
                                        {{ citizen.approval_date }}
                                    </td>
                                    <td class="border-t  px-3 item-center">
                                        <StatusLevelCitizen :status="citizen.govt_employee_status"></StatusLevelCitizen>
                                    </td>
                                    <td class="border-t  px-3 item-center text-center">
                                        <status-level
                                            v-if="citizen.govt_issue_id_status != 2 && citizen.govt_issue_id_status != 5"
                                            :status="citizen.govt_issue_id_status"></status-level>
                                        <span v-if="citizen.govt_issue_id_status == 2" class="fail"><i
                                                class="fa fa-times-circle"></i></span>
                                        <span v-if="citizen.govt_issue_id_status == 5" class="fail"><i
                                                class="fa fa-lock"></i></span>
                                    </td>
                                    <td class="w-px border-t  px-3 item-center text-center">
                                        <div class="flex items-center justify-center px-2"
                                            v-if="permission.edit > 0 && (citizen.govt_issue_id_number != null)">
                                            <Link class="px-4 py-2" :href="`/backend/citizens/${citizen.id}`"
                                                tabindex="-1">
                                            <i class="fa fa-edit"></i>
                                            </Link>
                                        </div>
                                        <div v-else>
                                            <p>No Govt. Id Number Provided</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="citizens.data.length === 0">
                                    <td class="px-6 py-4 border-t" colspan="4">No Citizens found.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <pagination class="mt-2 mb-2" :links="citizens.links" />
                </TabPanel>
                <TabPanel>
                    <div class="bg-white rounded-md shadow overflow-x-auto">
                        <table class="w-full whitespace-nowrap">
                            <thead>
                                <tr class="text-left font-bold">
                                    <th class="pb-2 pt-3 px-3 item-center text-center">Sl.</th>
                                    <th class="pb-2 pt-3 px-3">Info</th>
                                    <th class="pb-2 pt-3 px-3 item-center text-center">Request Date</th>
                                    <th class="pb-2 pt-3 px-3 item-center text-center">Approval Date</th>
                                    <th class="pb-2 pt-3 px-3 item-center text-center">Verify</th>
                                    <th class="pb-2 pt-3 px-3 item-center text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(citizen, index) in employees.data" :key="citizen.id"
                                    class="hover:bg-gray-100 focus-within:bg-gray-100">
                                    <td class="border-t item-center text-center px-3">
                                        {{ (index + employees.from) }}
                                    </td>
                                    <td class="border-t">
                                        <p class="bold flex font-14 font-bold items-center ">
                                            {{ citizen.first_name }} {{ citizen.middle_name }} {{ citizen.last_name }}
                                            {{ citizen.surfix }}
                                        </p>
                                        <p class="flex items-center ">
                                            Mobile: {{ citizen.mobile }}
                                        </p>
                                        <p class="flex items-center ">
                                            Email: {{ citizen.email }}
                                        </p>
                                        <p class="flex items-center ">
                                            User Name: {{ citizen.username }}
                                        </p>
                                        <icon v-if="citizen.deleted_at" name="trash"
                                            class="flex-shrink-0 ml-2 w-3 h-3 fill-gray-400" />
                                    </td>
                                    <td class="border-t  px-3 item-center text-center">
                                        {{ citizen.giiu_date }}
                                    </td>
                                    <td class="border-t  px-3 item-center text-center">
                                        {{ citizen.approval_date }}
                                    </td>


                                    <td class="border-t  px-3 item-center text-center">
                                        <status-level
                                            v-if="citizen.govt_issue_id_status != 2 && citizen.govt_issue_id_status != 5"
                                            :status="citizen.govt_issue_id_status"></status-level>
                                        <span v-if="citizen.govt_issue_id_status == 2" class="fail"><i
                                                class="fa fa-times-circle"></i></span>
                                        <span v-if="citizen.govt_issue_id_status == 5" class="fail"><i
                                                class="fa fa-lock"></i></span>
                                    </td>
                                    <td class="w-px border-t  px-3 item-center text-center">
                                        <div class="flex items-center px-2" v-if="permission.edit > 0">
                                            <Link class="px-4 py-2" :href="`/backend/citizens/${citizen.id}/edit`"
                                                tabindex="-1">
                                            <i class="fa fa-edit"></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="employees.data.length === 0">
                                    <td class="px-6 py-4 border-t" colspan="4">No Data found.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <pagination class="mt-2 mb-2" :links="employees.links" />
                </TabPanel>
            </TabPanels>
        </TabGroup>
    </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import SearchFilter from '@/Shared/SearchFilter'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import StatusLevel from '@/Shared/StatusLevel'
import StatusLevelCitizen from '@/Shared/StatusLevelCitizen'
import Button from '@/Shared/Button'
import { ref, toRefs, inject } from 'vue'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
export default {
    components: {
        Head,
        Icon,
        Link,
        Pagination,
        SearchFilter,
        Breadcrumbs,
        StatusLevel,
        StatusLevelCitizen,
        Button,
        TabGroup,
        TabList,
        Tab,
        TabPanels,
        TabPanel
    },
    layout: Layout,
    props: {
        filters: Object,
        citizens: Object,
        employees: Object,
        stat: Object,
        empStat: Object,
        permission: Object,
    },
    setup(props) {
        const { filters, citizens, stat, permission, employees, empStat } = toRefs(props)
        const form = ref({
            search: filters.value.search,
            trashed: filters.value.trashed,
            length: filters.value.length ? filters.value.length : 15,
            status: filters.value.status ? filters.value.status : 1,
            govt_employee_status: filters.value.govt_employee_status,
            type: filters.value.type,
        })

        const type = ref('index')
        const title = ref('Profile Verification ')
        const url = ref('')
        const createUrl = ref('/backend/citizens/create')
        const reset = () => {
            form.value = mapValues(form, () => null)
        }
        return {
            filters,
            citizens,
            stat,
            form,
            type,
            title,
            url,
            createUrl,
            reset,
            permission,
            employees, empStat
        }
    },
    watch: {
        form: {
            deep: true,
            handler: throttle(function () {
                this.$inertia.get('/backend/citizens', pickBy(this.form), { preserveState: true })
            }, 150),
        },
    },
    created() {
        // Set up a throttled method to handle changes
        this.$inertia.get('/backend/citizens', pickBy(this.form), { preserveState: true })
    },

}
</script>
<style lang="scss" scoped>
span {
    display: inline-block;

    &.fail>i {
        color: #c32700;
        font-size: 20px;
    }
}

.indigo {
    background: #4338ca;
}

.indigo-badge {
    background: #4f44c7;
}

.primary {
    background: #287795;
}

.primary-badge {
    background: #2eaee0;
}

.warning {
    background: #c19f02;
}

.warning-badge {
    background: #dcb700;
}

.success {
    background: #007a1b;
}

.success-badge {
    background: #019d23;
}

.reject {
    background: #cd0000;
}

.reject-badge {
    background-color: #ef6767;
}
</style>
