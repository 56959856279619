<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
      <div class="flex justify-start mb-8 max-w-3xl">
        <img v-if="office.logo" class="block ml-4 w-8 h-8 rounded-full" :src="office.logo" />
      </div>
      <trashed-message v-if="office.deleted_at" class="mb-6" @restore="restore"> This Office has been deleted.</trashed-message>
      <div class="container m-auto grid grid-cols-3">
        <div class="col-span-2 max-w-2xl">
          <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="update">
              <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                <text-input v-model="form.office_name" :error="form.errors.office_name" class="pb-8 pr-6 w-full lg:w-1/1" label="Name of Office" placeholder="i.e. LGU Butig" required="true"/>
                <text-input v-model="form.hierarchy" :error="form.errors.hierarchy" class="pb-8 pr-6 w-full lg:w-1/1" label="Hierarchy" type="number" placeholder="i.e. 1" oninput="this.value = Math.abs(this.value)"/>
                <file-input v-model="form.logo" :error="form.errors.logo" class="pb-8 pr-6 w-full lg:w-1/1" type="file" accept="image/*" label="Logo" />
                <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Office" class="pb-8 pr-6 w-full"></status-toogle-button>
              </div>
              <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
                <button v-if="!office.deleted_at && permission.edit>0" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
                <Button v-if="permission.edit>0" :type="type" :name="head" :url="createUrl" :loading="form.processing"></Button>
              </div>
            </form>
          </div>
          <!-- <div class="max-w-2xl bg-white rounded-md shadow mt-4">
            <OrganogramMap :organogram="organogram" :office_id="office_id" />
          </div> -->
        </div>
        <div class="max-w-2xl bg-white rounded-md shadow ml-4">
          <OfficeLayerMap :officelayers="officelayers" :layermap="layermap" :office_id="office_id" @getLayers="get_layers" />
          <MinistryOffice :officeMinistries="officeMinistries" :ministrymap="ministrymap" :office_id="office_id" @getMinistry="get_ministry" />
        </div>
      </div>
    </div>

  </template>

  <script>
  import axios from 'axios';
  import { ref, toRefs, onMounted, computed, inject } from 'vue'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import { Inertia } from "@inertiajs/inertia";
  import Icon from '@/Shared/Icon'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import TrashedMessage from '@/Shared/TrashedMessage'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import DeleteIcon from '@/Shared/DeleteIcon'
  import SelectInputFunction from '@/Shared/SelectInputFunction'
  import OrganogramMap from './components/v3/OrganogramMap'
  import OfficeLayerMap from './components/v3/OfficeLayerMap'
  import MinistryOffice from './components/v3/MinistryOffice'
  import Button from '@/Shared/Button'
  import FileInput from '@/Shared/FileInput'
  export default {
    components: {
      Head,
      Icon,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      TrashedMessage,
      StatusToogleButton,
      Breadcrumbs,
      DeleteIcon,
      SelectInputFunction,
      OrganogramMap,
      OfficeLayerMap,
      Button,
      FileInput,
      MinistryOffice
    },
    layout: Layout,
    props: {
      office: Object,
      offices: Array,
      officelayers: Array,
      officeMinistries: Array,
      organogram:Array,
      layermap:Array,
      ministrymap: Array,
      ldata:Array,
      mdata:Array,
      permission: Object,
    },
    remember: 'form',
    setup(props){
      const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
      const { office, offices, officelayers ,permission, ldata,officeMinistries ,mdata,ministrymap} = toRefs(props)
      const type = ref("edit")
      const title = ref(office.value.office_name)
      const head = ref('Office')
      const url = ref('/backend/offices')
      const createUrl = ref('')
      const swal = inject('$swal')
      const office_id = computed(() => office.value.id)

      onMounted(() => {
        statusCheck();
        layermap.value.forEach(function(value){
          //console.log(value);
          // if(value == 1)
          //   out.push(value);

          });
      })

      const form = useForm({
          office_name:  office.value.office_name,
          superior_office:  office.value.superior_office,
          hierarchy: office.value.hierarchy,
          status: office.value.status,
          office_layers: ldata.value,
          office_ministries: mdata.value,
          logo:null,
      })

      const get_layers = (obj) => {
        //console.log(obj)
        form.office_layers = obj
      }

      const statusCheck = () => {
        if(office.value.status){
          form.status=true
          return true
        }else{
          form.status=false
          return false
        }
      }
      const update = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}/${office.value.id}`, {forceFormData: true,})
              title.value = form.office_name
              swal.fire(
                'Updated!',
                'Your infomation has been Updated.',
                'success'
              )
            }
          });
      }

      const update_des = () => {
        this.form.put(`/${backendPrefix.value}/offices/${office.value.id}`)
      }

      const destroy = () => {
        swal({
            title: 'Are you sure?',
            text: "Do you really want to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.delete(`${url.value}/${office.value.id}`)
              swal.fire(
                'Deleted!',
                'Your infomation has been deleted.',
                'success'
              )
            }
          });
      }
      const restore = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to restore this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Resore it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${office.value.id}/restore`)
              swal.fire(
                'Restored!',
                'Your infomation has been Restored.',
                'success'
              )
            }
          });
      }

      const get_ministry = (obj) => {
        form.office_ministries = obj
      }

      return {
        office,
        offices,
        officelayers,
        type,
        title,
        head,
        url,
        createUrl,
        form,
        office_id,
        get_layers,
        statusCheck,
        update,
        update_des,
        destroy,
        restore,
        swal,
        permission,
        ldata,
        officeMinistries ,
        mdata,
        get_ministry,
        ministrymap
      }
    }
  }
  </script>
