<template>
  <div>
    <Breadcrumbs :type="type" :header="title" :url="url" :head="head" />
    <trashed-message v-if="room_accessory.deleted_at" class="mb-6" @restore="restore"> This room_accessory has been
      deleted. </trashed-message>
    <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <text-input v-model="form.bed_title" :error="form.errors.bed_title" class="pb-8 pr-6 w-full lg:w-1/2"
            label="Name of Room AccessorIes" required="true" placeholder="i.e " />
          <select-input v-model="form.room_id" :error="form.errors.room_id" class="pb-8 pr-6 w-full lg:w-1/2"
            label="Name of Room">
            <option value=null>Select Room</option>
            <option v-for="room in rooms" :key="room.id" :value='room.id'>{{ room.room_title }}</option>
          </select-input>
          <div class="pb-8 pr-6 w-full lg:w-1/2 grid grid-cols-12">
            <text-input v-model="form.icon" :error="form.errors.icon" class="col-span-11" label="Icon"
              placeholder="i.e 1" disabled />
            <vue3-icon-picker v-model="form.icon" class="mt-5" />
          </div>
          <status-toogle-button v-model="form.accessories_type" :error="form.errors.accessories_type"
            label="Is Use as bed" class="pb-8 pr-6 w-full lg:w-1/2 mt-5"></status-toogle-button>
          <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Room AccessorIes"
            class="pb-8 pr-6 w-full lg:w-1/2 mt-5"></status-toogle-button>
        </div>

        <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
          <button v-if="!room_accessory.deleted_at && permission.delete > 0" class="text-red-600 hover:underline"
            tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
          <Button v-if="permission.edit > 0" :type="type" :name="head" :url="createUrl"
            :loading="form.processing"></Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, toRefs, inject } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import DeleteIcon from '@/Shared/DeleteIcon'
import Button from '@/Shared/Button'
import FileInput from '@/Shared/FileInput'
import Vue3IconPicker from '@/Shared/components/Vue3IconPicker'
export default {
  components: {
    Head,
    Icon,
    Link,
    LoadingButton,
    SelectInput,
    TextInput,
    TrashedMessage,
    Breadcrumbs,
    DeleteIcon,
    StatusToogleButton,
    Button,
    FileInput,
    Vue3IconPicker
  },
  layout: Layout,
  props: {
    room_accessory: Object,
    permission: Object,
    rooms: Array
  },
  remember: 'form',
  setup(props) {
    const { room_accessory, rooms, permission } = toRefs(props)
    const type = ref('edit')
    const title = ref(room_accessory.value.bed_title)
    const head = ref('Room Accessories')
    const url = ref('/backend/room-accessories')
    const createUrl = ref('')
    const elm = ref(this)
    const swal = inject('$swal')

    const form = useForm({
      bed_title: room_accessory.value.bed_title,
      icon: room_accessory.value.icon ? room_accessory.value.icon : 'fas fa-upload',
      accessories_type: room_accessory.value.accessories_type,
      room_id: room_accessory.value.room_id,
      status: room_accessory.value.status,
    })
    const update = () => {
      swal({
        title: 'Are you sure?',
        text: "You want to proceed with this action",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          form.post(`${url.value}/${room_accessory.value.id}`, { forceFormData: true, })
          title.value = form.bed_title
          swal.fire(
            'Updated!',
            'Your infomation has been Updated.',
            'success'
          )
        }
      });
    }
    const destroy = () => {
      swal({
        title: 'Are you sure?',
        text: "Do you really want to delete this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          form.delete(`${url.value}/${room_accessory.value.id}`)
          swal.fire(
            'Deleted!',
            'Your infomation has been deleted.',
            'success'
          )
        }
      });
    }
    const restore = () => {
      swal({
        title: 'Are you sure?',
        text: "You want to restore this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Resore it!'
      }).then((result) => {
        if (result.isConfirmed) {
          form.put(`${url.value}/${room_accessory.value.id}/restore`)
          swal.fire(
            'Restored!',
            'Your infomation has been Restored.',
            'success'
          )
        }
      });
    }
    return {
      form,
      room_accessory,
      type,
      title,
      head,
      url,
      createUrl,
      update,
      destroy,
      restore,
      swal,
      permission,
      rooms
    }
  }
}
</script>
