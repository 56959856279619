<template>
    <TransitionRoot as="template" :show="activitytypeopen">
        <Dialog as="div" class="relative z-[9999]" @click="update_popup(false)">
            <div class="fixed inset-0" />
            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <TransitionChild as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full" enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-0" leave-to="translate-x-full">
                            <DialogPanel class="pointer-events-auto w-screen max-w-md">
                                <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                    <div class="h-0 flex-1 overflow-y-auto">
                                        <div class="bg-indigo-700 px-4 py-6 sm:px-6">
                                            <div class="flex items-center justify-between">
                                                <DialogTitle class="text-base font-semibold leading-6 text-white">
                                                    Property Stay Activity Type
                                                </DialogTitle>
                                                <div class="ml-3 flex h-7 items-center">
                                                    <button type="button"
                                                        class="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                        @click="update_popup(false)">
                                                        <span class="absolute -inset-2.5" />
                                                        <span class="sr-only">Close panel</span>
                                                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="mt-1">
                                                <p class="text-sm text-indigo-300">Add Property Stay Activity Type for
                                                    this post.</p>
                                            </div>
                                        </div>
                                        <div class="flex flex-1 flex-col justify-between">
                                            <div class="divide-y divide-gray-200 px-4 sm:px-6">
                                                <div class="space-y-6 pb-5 pt-6">
                                                    <div
                                                        class="mt-1 rounded-md border border-gray-300 p-3 max-h-[485px] overflow-y-auto">
                                                        <div v-if="message"
                                                            class="flex items-center gap-2 message_block error"><i
                                                                class="fa fa-check"></i> {{ message }}<i
                                                                class="fa fa-times close_btn" @click="remove_msg"></i>
                                                        </div>
                                                        <form @submit.prevent="data_submit">
                                                            <select-input v-model="form.activity_type_id"
                                                                :error="form.errors.activity_type_id"
                                                                class="pb-8 pr-6 w-full " label="Name of Type"
                                                                required="true">
                                                                <option value=null>Select Type</option>
                                                                <option v-for="type in type_list" :key="type.id"
                                                                    :value='type.id'>{{ type.title }}
                                                                </option>
                                                            </select-input>
                                                            <text-input v-model="form.name" :error="form.errors.name"
                                                                class="pb-8 pr-6 w-full " label="Name" required="true"
                                                                placeholder="i.e " />
                                                            <text-input :type="'time'" v-model="form.checkin_time"
                                                                :error="form.errors.checkin_time"
                                                                class="pb-8 pr-6 w-full " label="Check In Time"
                                                                required="true" placeholder="i.e " />

                                                            <text-input :type="'time'" v-model="form.checkout_time"
                                                                :error="form.errors.checkout_time"
                                                                class="pb-8 pr-6 w-full " label="Check Out Time"
                                                                required="true" placeholder="i.e " />

                                                            <template v-if="form.activity_type_id == 1">
                                                                <text-input :type="'number'"
                                                                    v-model="form.number_of_day"
                                                                    :error="form.errors.number_of_day"
                                                                    class="pb-8 pr-6 w-full " label="Number Of Day"
                                                                    required="true" placeholder="i.e "
                                                                    oninput="this.value = Math.abs(this.value)"
                                                                    min="0" />
                                                            </template>
                                                            <template v-if="form.activity_type_id == 2">
                                                                <text-input :type="'number'"
                                                                    v-model="form.number_of_night"
                                                                    :error="form.errors.number_of_night"
                                                                    class="pb-8 pr-6 w-full " label="Number Of Night"
                                                                    required="true" placeholder="i.e "
                                                                    oninput="this.value = Math.abs(this.value)"
                                                                    min="0" />
                                                            </template>
                                                            <template v-if="form.activity_type_id == 3">
                                                                <text-input :type="'number'" v-model="form.total_days"
                                                                    :error="form.errors.number_of_total_daysnight"
                                                                    class="pb-8 pr-6 w-full " label="Total Days"
                                                                    required="true" placeholder="i.e "
                                                                    oninput="this.value = Math.abs(this.value)"
                                                                    min="0" />
                                                            </template>

                                                            <text-input :type="'number'" v-model="form.price"
                                                                :error="form.errors.price" class="pb-8 pr-6 w-full "
                                                                label="Regular Price" required="true" placeholder="i.e "
                                                                oninput="this.value = Math.abs(this.value)" min="0" />

                                                            <text-input :type="'number'" v-model="form.discount_price"
                                                                :error="''" class="pb-8 pr-6 w-full "
                                                                label="Promotional Price (After Discount)"
                                                                placeholder="i.e "
                                                                oninput="this.value = Math.abs(this.value)" min="0" />
                                                            <text-input :type="'number'" v-model="form.pwd_price"
                                                                :error="''" class="pb-8 pr-6 w-full "
                                                                label="PWD / Senior Citizen Price" placeholder="i.e "
                                                                oninput="this.value = Math.abs(this.value)" min="0" />

                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-shrink-0 justify-end px-4 py-4" v-if="edit == false">
                                        <button type="button" @click="saveActivityTypeData()"
                                            class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
                                    </div>
                                </form>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>


import axios from 'axios';
import { ref, toRefs, inject, onMounted, watch } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import Button from '@/Shared/Button'
const props = defineProps(['activity_types', 'activitytypeopen'])


const activity_type_data = ref(props.activity_types)
const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
const edit = ref(false)
const swal = inject('$swal')

const preloader = ref(false)
const ispackage = ref(false)

const message = ref('');
const form = useForm({
    activity_type_id: null,
    checkin_time: null,
    checkout_time: null,
    number_of_day: 0,
    number_of_night: 0,
    total_days: 0,
    price: null,
    discount_price: null,
    pwd_price: null,
    name: null,
})

const emit = defineEmits(['UpdateActivityPopup ', 'UpdateActivity'])


const update_popup = (status) => {
    emit('UpdateActivityPopup', status)
}

const saveActivityTypeData = (val) => {
    if (form.activity_type_id != null && form.checkin_time != null && form.checkout_time != null && form.price != null) {

        if (form.activity_type_id == 1 && form.number_of_day <= 0) {
            message.value = 'Please fill Number Of day filed';
            retun;
        }
        if (form.activity_type_id == 2 && form.number_of_night <= 0) {
            message.value = 'Please fill Number Of night filed';
            retun;
        }
        if ((form.activity_type_id == 3 || form.activity_type_id == 4) && form.total_days <= 0) {
            message.value = 'Please fill Total day filed';
            retun;
        }

        if (form.price <= 0) {
            message.value = 'Price Must be greater than zero';
            retun;
        }
        if (form.activity_type_id == 2) {
            form.total_days = form.number_of_night
        } else if (form.activity_type_id == 1) {
            form.total_days = form.number_of_day
        }
        let obj = {
            id: null,
            post_id: null,
            activity_type_id: form.activity_type_id,
            checkin_time: form.checkin_time,
            checkout_time: form.checkout_time,
            number_of_day: form.number_of_day,
            number_of_night: form.number_of_night,
            total_days: form.total_days,
            price: form.price,
            name: form.name,
            discount: form.discount_price,
            pwd_price: form.pwd_price
        }
        emit('UpdateActivity', obj)
        emit('UpdateActivityPopup', false)
        form.activity_type_id = null
        form.checkin_time = null
        form.checkout_time = null
        form.number_of_day = null
        form.number_of_night = null
        form.total_days = null
        form.price = null
        form.discount_price = null
        form.pwd_price = null
        form.name = null
    } else {
        message.value = 'Please fill mendatory filed'
    }

}

const remove_msg = () => {
    message.value = ''
}

const type_list = ref([])
const load_type = async () => {
    await axios.post(`/${backendPrefix.value}/stay-activity-types/activity-type`)
        .then(function (response) {
            ////console.log(response);
            type_list.value = response.data
        }.bind(this));
}

const updateTotalDays = async () => {
    //form.total_days = (form.number_of_day?parseInt(form.number_of_day):0) + (form.number_of_night?parseInt(form.number_of_night):0)
    ////console.log('update_total_days',form.number_of_day,form.number_of_night,form.total_days)
}

onMounted(() => {
    load_type()
})

// watch(() => [form.number_of_day,form.number_of_night], () => {
//   updateTotalDays();
// }, { deep: true })

</script>
<style lang="scss" scoped>
.message_block {
    position: fixed;
    z-index: 9999;
    left: -300px;
    bottom: 25px;
    color: #fff;
    height: 40px;
    padding: 0 15px;
    border-radius: 5px;
    transition: all 0.4s;

    &>.close_btn {
        position: absolute;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        background-color: #941212;
        border: 1px solid #fff;
        border-radius: 50%;
        cursor: pointer;
        right: 5px;
        top: -10px;
        font-size: 8px;
        z-index: 5;
    }

    &.error {
        left: auto;
        right: 10%;
        background-color: #fc4949;
    }
}
</style>
