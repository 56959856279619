<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
      <trashed-message v-if="page.deleted_at" class="mb-6" @restore="restore"> This News has been deleted. </trashed-message>
      <div class="max-w-5xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="update">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.title" :error="form.errors.title" class="pb-8 pr-6 w-full lg:w-1/1" label="Name of Page" required="true" placeholder="i.e About"/>
            <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/1" label="Slug of Page" required="true" placeholder="i.e about"/>
            <select-input v-model="form.menu_position" :error="form.errors.menu_position" class="pb-8 pr-6 w-full lg:w-1/1" label="Menu Position" >
              <option value=null >Select Menu Position</option>
              <option value='1' >Top</option>
              <option value='2' >Bottom</option>
              <option value='3' >All</option>
            </select-input>
            <select-input v-model="form.linktype" :error="form.errors.linktype" class="pb-8 pr-6 w-full lg:w-1/1" label="Link type" >
              <option value=null >Select Link type</option>
              <option value='Internal' >Internal</option>
              <option value='External' >External</option>
            </select-input>
            <select-input v-model="form.type" :error="form.errors.type" class="pb-8 pr-6 w-full lg:w-1/1" label="Type" >
              <option value=null >Select type</option>
              <option value='1' >Ministry</option>
              <option value='2' >Agency</option>
              <option value='3' >Others</option>
            </select-input>
            <template v-if="form.linktype=='External'">
              <text-input v-model="form.url" :error="form.errors.url" class="pb-8 pr-6 w-full lg:w-1/1" label="External Url " required="true" placeholder="i.e "/>
            </template>
            <template v-if="form.linktype=='Internal'">
              <div class="pb-8 pr-6 w-full lg:w-1/1">
                <label class="form-label" for="content">Content <span class="custom-error">*</span></label>
                <vue-editor  v-model="form.content" />
              </div>
            </template>

            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Page Status" class="pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>
          </div>
          <div class="w-full mt-3 px-6">
            <div class="flex flex-row items-center justify-center gap-2 bg-gray-400 text-white h-8 rounded-md cursor-pointer" @click="content_builder_status(true)">
              <i class="fa fa-cogs"></i>
              <span>Content Builder</span>
            </div>
          </div>
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
            <button v-if="!page.deleted_at && permission.edit>0" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
            <Button  v-if="permission.edit>0" :type="type" :name="head" :url="createUrl" :loading="form.processing"></Button>
          </div>
        </form>
      </div>
      <div v-if="content_builder_show" class="fixed left-0 top-0 w-full h-full bg-[#00000080] overflow-y-auto z-[9999]">
        <div class="relative w-[80%] h-auto left-[10%] top-10 bg-white rounded-md shadow-md">
          <span class="absolute flex items-center justify-center -right-[10px] -top-[10px] w-[30px] h-[30px] rounded-full bg-white text-red-400 border border-b-[#ccc] cursor-pointer z-10" @click="content_builder_status(false)">x</span>
          <ContentBuilder :content="form.page_layout_contents" @setContent="set_content" />
        </div>
      </div>
      <!-- <pre>{{ form }}</pre>
      <pre>{{ page }}</pre> -->
    </div>
  </template>

  <script>
  import { ref, toRefs, inject } from 'vue'
  import ContentBuilder from '@/Pages/Admin/ContentBuilder/Index'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import Icon from '@/Shared/Icon'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import TrashedMessage from '@/Shared/TrashedMessage'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import DeleteIcon from '@/Shared/DeleteIcon'
  import Button from '@/Shared/Button'
  import TextareaInput from '@/Shared/TextareaInput'
  import { VueEditor, Quill } from "vue3-editor";
  export default {
    components: {
      Head,
      Icon,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      TrashedMessage,
      StatusToogleButton,
      Breadcrumbs,
      DeleteIcon,
      Button,
      TextareaInput,
      VueEditor,
      Quill,
      ContentBuilder
    },
    layout: Layout,
    props: {
      page: Object,
      permission: Object,
    },
    remember: 'form',
    setup(props){
      const { page, permission} = toRefs(props)
      const type = ref('edit')
      const title = ref(page.value.title)
      const head = ref('Page')
      const url = ref('/backend/pages')
      const createUrl = ref('')
      const content_builder_show = ref(false)
      const content_builder_status = (status) => {
        content_builder_show.value = status
        document.getElementsByTagName('html')[0].style.overflow = status?"hidden":"auto"
      }
      const swal = inject('$swal')
      const elm = ref(this)
      const form = useForm({
        title:page.value.title,
        slug:page.value.slug,
        content: page.value.content,
        menu_position: page.value.menu_position,
        linktype: page.value.linktype,
        url: page.value.url,
        status: page.value.status,
        type: page.value.type,
        page_layout_contents: page.value.page_layout_contents
      })
      const set_content = (data) => {
        form.page_layout_contents = data
      }
      const update = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${page.value.id}`)
              title.value = form.title
              swal.fire(
                'Updated!',
                'Your infomation has been Updated.',
                'success'
              )
            }
          });
      }

      const destroy = () => {
        swal({
            title: 'Are you sure?',
            text: "Do you really want to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.delete(`${url.value}/${page.value.id}`)
              swal.fire(
                'Deleted!',
                'Your infomation has been deleted.',
                'success'
              )
            }
          });
      }
      const restore = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to restore this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Resore it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${page.value.id}/restore`)
              swal.fire(
                'Restored!',
                'Your infomation has been Restored.',
                'success'
              )
            }
          });
      }
      const statusCheck= () => {
        if(page.value.status==1){
          form.status=true;
          return true;
        }else{
          form.status=false;
          return false;
        }
      }
      return {
        form,
        page,
        type,
        title,
        head,
        url,
        createUrl,
        content_builder_show,
        set_content,
        content_builder_status,
        update,
        destroy,
        restore,
        statusCheck,
        swal,
        permission
      }
    },
    created: function(){
      this.statusCheck();
    }

  }
  </script>
 <style>

 .ql-toolbar .ql-formats .ql-image {
   display: none;
 }
</style>
