<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
            <ContentHeaderSection :title="'Basic Info'" :icon="'fa fa-cogs'" />
            <form @submit.prevent="store" enctype="multipart/form-data">
                <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                    <text-input v-model="form.service_name" :error="form.errors.service_name"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Service Name" required="true"
                        placeholder="i.e Certificate of Live Birth" />
                    <select-input v-model="form.scat_id" :error="form.errors.scat_id" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Service Category" required="true">
                        <option value=null>Select Service Category</option>
                        <option v-for="servicecategory in servicecategories" :key="servicecategory.id"
                            :value='servicecategory.id'>{{ servicecategory.cat_title }}</option>
                    </select-input>
                    <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Service Slug" required="true" placeholder="i.e live-birth" />
                    <text-input type="color" v-model="form.color" :error="form.errors.color"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Dashboard Service Color" required="true"
                        placeholder="i.e #ffffff" />
                    <file-input v-model="form.logo" :error="form.errors.logo" class="pb-8 pr-6 w-full lg:w-1/2"
                        type="file" accept="image/*" label="Logo" />
                    <status-toogle-button v-model="form.status" :error="form.errors.status"
                        label="Service Status &nbsp;&nbsp;&nbsp;&nbsp;"
                        class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button>
                    <status-toogle-button v-model="form.available_for_queue" :error="form.errors.available_for_queue"
                        label="Available for Queue" class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button>
                </div>
                <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>

            </form>
        </div>
    </div>
</template>

<script>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, onMounted, watchEffect } from 'vue'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import ContentHeaderSection from "@/Shared/ContentHeaderSection"
import InputTag from '@/Shared/InputTag'

import Button from '@/Shared/Button'
import FileInput from '@/Shared/FileInput'
export default {
    components: {
        Head,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        Breadcrumbs,
        StatusToogleButton,
        Button,
        ContentHeaderSection,
        Button,
        FileInput
    },
    layout: Layout,
    remember: 'form',
    props: {
        servicecategories: Array,

    },

    setup(props) {
        const { servicecategories } = toRefs(props)
        const form = useForm({
            service_name: null,
            status: true,
            available_for_queue: false,
            scat_id: null,
            logo: null,
            slug: null,
            color: null
        })
        const type = ref('create')
        const title = ref('Services')
        const url = ref('/backend/services')
        const createUrl = ref('')
        const store = () => {
            form.post(`${url.value}`, { forceFormData: true, })
        }
        return {
            servicecategories,
            form,
            type,
            title,
            url,
            createUrl,
            store
        }
    },
}
</script>
<style></style>
