<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="flex items-center justify-between mb-6">
            <search-filter v-model="form.search" class="mr-4 w-full max-w-xl" @reset="reset">
                <template v-slot:filter>
                    <select v-model="form.trashed" class="form-select  w-28">
                        <option :value="null">Filter</option>
                        <option value="with">With Trashed</option>
                        <option value="only">Only Trashed</option>
                    </select>
                </template>
                <template v-slot:page>
                    <select v-model="form.length" class="form-select  w-20">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                    </select>
                </template>
                <template v-slot:status>
                    <select v-model="form.status" class="form-select w-32">
                        <option :value="null">Status</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                    </select>
                </template>
            </search-filter>
            <Button :type="type" :name="title" :url="createUrl" v-if="permission.add > 0"></Button>

        </div>
        <div class="bg-white rounded-md shadow overflow-x-auto">
            <table class="w-full whitespace-nowrap">
                <thead>
                    <tr class="text-left font-bold">
                        <th class="pb-2 pt-3 px-3">Name</th>
                        <th class="pb-2 pt-3 px-3">Decision Type</th>
                        <th class="pb-2 pt-3 px-3 text-center">Status</th>
                        <th class="pb-2 pt-3 px-3">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="decisionstep in decisionsteps.data" :key="decisionstep.id"
                        class="hover:bg-gray-100 focus-within:bg-gray-100">
                        <td class="border-t">
                            <div class="flex items-center px-3 py-1 focus:text-indigo-500">
                                {{ decisionstep.decision_title }}
                                <icon v-if="decisionstep.deleted_at" name="trash"
                                    class="flex-shrink-0 ml-2 w-3 h-3 fill-gray-400" />
                            </div>
                        </td>
                        <td class="border-t">
                            <div class="flex items-center px-3 py-1 focus:text-indigo-500">
                                {{ decisionstep.type == 1 ? 'Forward' : '' }}
                                {{ decisionstep.type == 2 ? 'Return' : '' }}
                                {{ decisionstep.type == 3 ? 'Reject' : '' }}
                                {{ decisionstep.type == 4 ? 'Complete' : '' }}
                                {{ decisionstep.type == 5 ? 'Edit' : '' }}
                                {{ decisionstep.type == 6 ? 'Print' : '' }}
                                {{ decisionstep.type == 7 ? 'Payment Request' : '' }}
                                {{ decisionstep.type == 8 ? 'Payment Success' : '' }}
                                {{ decisionstep.type == 9 ? 'Payment Resubmit' : '' }}
                                {{ decisionstep.type == 10 ? 'Received' : '' }}
                                {{ decisionstep.type == 11 ? 'In-Progress' : '' }}
                                {{ decisionstep.type == 12 ? 'Generate Certificate' : '' }}
                                {{ decisionstep.type == 13 ? 'Cancel' : '' }}
                                {{ decisionstep.type == 15 ? 'Document Request' : '' }}
                                {{ decisionstep.type == 16 ? 'Document Received' : '' }}
                                {{ decisionstep.type == 17 ? 'Document Success' : '' }}
                                {{ decisionstep.type == 18 ? 'Document Resubmit' : '' }}
                                {{ decisionstep.type == 21 ? 'Reappointment' : '' }}
                            </div>
                        </td>
                        <td class="border-t">
                            <div class="flex items-center px-3 py-1" tabindex="-1">
                                <status-level :status="decisionstep.status"></status-level>
                            </div>
                        </td>
                        <td class="w-px border-t">
                            <div class="flex items-center px-2" v-if="permission.edit > 0">
                                <Link class="px-4 py-2" :href="`/backend/decisionsteps/${decisionstep.id}/edit`"
                                    tabindex="-1">
                                <i class="fa fa-edit"></i>
                                </Link>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="decisionsteps.data.length === 0">
                        <td class="px-3 py-1 border-t" colspan="4">No decisionstep found.</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination class="mt-6" :links="decisionsteps.links" />
    </div>
</template>

<script>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import SearchFilter from '@/Shared/SearchFilter'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import StatusLevel from '@/Shared/StatusLevel'
import Button from '@/Shared/Button'
export default {
    components: {
        Head,
        Icon,
        Link,
        Pagination,
        SearchFilter,
        Breadcrumbs,
        StatusLevel,
        Button
    },
    layout: Layout,
    props: {
        filters: Object,
        decisionsteps: Object,
        permission: Object,
    },
    setup(props) {
        const { filters, decisionsteps, permission } = toRefs(props)
        const form = ref({
            search: filters.value.search,
            trashed: filters.value.trashed,
            length: filters.value.length ? filters.value.length : 15,
            status: filters.value.status,
        })
        const type = ref('index')
        const title = ref('Decision')
        const url = ref('')
        const createUrl = ref('/backend/decisionsteps/create')
        const reset = () => {
            form.value = mapValues(form, () => null)
        }
        return {
            filters,
            decisionsteps,
            form,
            type,
            title,
            url,
            createUrl,
            reset,
            permission
        }
    },
    watch: {
        form: {
            deep: true,
            handler: throttle(function () {
                this.$inertia.get('/backend/decisionsteps', pickBy(this.form), { preserveState: true })
            }, 150),
        },
    },
}
</script>
