<template>
    <div class="profile_edit_content_block">
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="w-full bg-white rounded-md shadow overflow-hidden edit_form_block">
            <form @submit.prevent="store">
                <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 mt-3 p-4">
                    <div class="sm:col-span-3">
                        <div class="mt-1 flex items-center h-[225px]">
                            <div
                                class="mt-1 flex flex-wrap justify-center rounded-md border-2 border-dashed border-gray-300 dark:border-black dark:bg-white dark:text-black px-6 pt-5 pb-6">
                                <div class="space-y-1 text-center">
                                    <PhotoBlock :getPhoto="form.image_path" @SetPhoto="set_photo" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sm:col-span-3"></div>
                    <text-input v-model="form.title" :error="form.errors.title" class="sm:col-span-2" label="Title"
                        required="true" placeholder="i.e. Anitya Cave House" @keyup="slugBuild" />
                    <text-input v-model="form.slug" :error="form.errors.slug" class="sm:col-span-2" label="Slug"
                        required="true" placeholder="i.e anitya-cave-house" />
                    <textarea-input v-model="form.desc" :error="form.errors.desc" class="sm:col-span-2"
                        label="Resident Description" required="true"
                        placeholder="i.e In the heart of Cappadocia, Ortahisar, a cavehouse of 80 square meter, fully equipped kitchen with a scenic terrace is offered for a relaxing and enjoyable vacation." />
                    <text-input v-model="form.longitude" :error="form.errors.longitude" class="sm:col-span-2"
                        label="Longitude" placeholder="i.e. 120.9842195" />
                    <text-input v-model="form.latitude" :error="form.errors.latitude" class="sm:col-span-2"
                        label="Latitude" placeholder="i.e. 14.5995124" />
                    <text-input v-model="form.google_address" :error="form.errors.google_address" class="sm:col-span-2"
                        label="Google Address" placeholder="i.e. Butig Proper, Butig, Lanao del Sur, Philippines" />

                    <TextareaInput v-model="form.map_embedded" :error="form.errors.map_embedded" class="sm:col-span-2"
                        label="Google Map" placeholder="i.e. https://goo.gl/maps/mjShJjfPKppUQBJs7" />

                    <template v-if="host_selected == false">
                        <select-input v-model="form.host_id" :error="form.errors.host_id" class="sm:col-span-2"
                            label="Host" required="true">
                            <option :value=null>Select Host</option>
                            <option v-for="host in hosts" :key="host.id" :value='host.id'>{{ host.full_name }}</option>
                        </select-input>
                    </template>
                    <text-input v-model="form.email" :error="form.errors.email" class="sm:col-span-2" label="Email"
                        :type="email" required="true" placeholder="i.e example@test.com" />
                    <text-input v-model="form.mobile" :error="form.errors.mobile" class="sm:col-span-2" label="Mobile"
                        placeholder="i.e +63-2-1234-5678" />
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Resident Status"
                        class="sm:col-span-2 flex items-center mt-4"></status-toogle-button>
                    <div class="sm:col-span-6">
                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Address <span
                                class="custom-error">*</span></label>
                        <div class="mt-1 rounded-md border border-gray-300 p-3">
                            <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                <div class="sm:col-span-2">
                                    <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose
                                        Office
                                        Layer <span class="custom-error">*</span></label>
                                    <div class="mt-1">
                                        <!-- <pre>{{ form }}</pre> -->
                                        <select v-model="form.office_layer_id"
                                            class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                            required>
                                            <option value=null>Choose One</option>
                                            <option v-for="office_layer in office_layers" :value="office_layer.id">{{
                                                office_layer.office_layer_name }}
                                            </option>
                                        </select>
                                        <div v-if="form.errors.office_layer_id" class="form-error">{{
                                            form.errors.office_layer_id }}</div>
                                    </div>
                                </div>

                                <div class="sm:col-span-2" v-if="form.office_layer_id == 1">
                                    <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose
                                        Ministry <span class="custom-error">*</span></label>
                                    <div class="mt-1">
                                        <!-- <pre>{{ form }}</pre> -->
                                        <select v-model="form.ministry_id"
                                            class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                            required>
                                            <option value=null>Choose One</option>
                                            <option v-for="ministry in ministries" :value="ministry.id">{{
                                                ministry.ministry_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="sm:col-span-2" v-if="form.office_layer_id > 1">
                                    <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose
                                        Region<span class="custom-error">*</span></label>
                                    <div class="mt-1">
                                        <!-- <pre>{{ form }}</pre> -->
                                        <select v-model="form.region_id"
                                            class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                            @change="load_province(1)" required>
                                            <option value=null>Choose One</option>
                                            <option v-for="region in regions" :value="region.id">{{ region.region_name
                                                }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="sm:col-span-2" v-if="form.office_layer_id > 2">
                                    <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose
                                        Province <span class="custom-error">*</span></label>
                                    <div class="mt-1">
                                        <!-- <pre>{{ form }}</pre> -->
                                        <select v-model="form.province_id"
                                            class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                            @change="load_municipality(1)" required>
                                            <option value=null>Choose One</option>
                                            <option v-for="province in pre_province_list" :value="province.id">{{
                                                province.provience_name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="sm:col-span-2" v-if="form.office_layer_id > 3">
                                    <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose
                                        Municipality <span class="custom-error">*</span></label>
                                    <div class="mt-1">
                                        <select v-model="form.municipality_id"
                                            class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                            @change="load_barangay(1)" required>
                                            <option value=null>Choose One</option>
                                            <option v-for="municipality in pre_municipality_list"
                                                :value="municipality.id">
                                                {{ municipality.municipality_name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="sm:col-span-2" v-if="form.office_layer_id > 4">
                                    <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose
                                        Barangay <span class="custom-error">*</span></label>
                                    <div class="mt-1">
                                        <select v-model="form.barangay_id"
                                            class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                            required>
                                            <option value=null>Choose One</option>
                                            <option v-for="barangay in pre_barangay_list" :value="barangay.id">{{
                                                barangay.barangay_name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="sm:col-span-2" v-if="office_selected == true">
                                    <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose
                                        Office <span class="custom-error">*</span></label>
                                    <div class="mt-1">
                                        <select v-model="form.office_id"
                                            class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                            required>
                                            <option value=null>Select Office</option>
                                            <option v-for="office in offices" :value='office.id'>{{ office.office_name
                                                }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sm:col-span-3 grid grid-cols-2 gap-y-2 gap-x-1 mb-1">
                        <div class="flex items-center col-span-2">
                            <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Gallery <span
                                    class="custom-error">*</span></label>
                            <Button :name="'Add'" :icon="'far fa-plus-circle'" :method="open_gallery_popup"></Button>
                        </div>
                        <div class="col-span-2 gap-y-2 gap-x-1 my-1">
                            <gallery-view :galleries="form.galleries" @UpdateGalleryList="update_gallery_list" />
                        </div>
                        <div class="col-span-2">
                            <gallery :galleries="form.galleries" :galleryopen="galleryopen"
                                @UpdateGalleryPopup="update_gallery_popup" @UpdateGallery="update_gallery">
                            </gallery>
                        </div>
                        <div v-if="form.errors.galleries" class="form-error">Gallery required.</div>
                    </div>
                </div>
                <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
            </form>
        </div>

    </div>
</template>

<script>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject, onMounted, watch } from 'vue'
import axios from 'axios';
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import TextareaInput from '@/Shared/TextareaInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Button from '@/Shared/Button'
import Gallery from './components/Gallery';
import GalleryView from './components/GalleryView';
import PhotoBlock from './Photo';
import ToolTip from '@/Shared/ToolTip'
export default {
    components: {
        Head,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        Breadcrumbs,
        StatusToogleButton,
        Button,
        TextareaInput,
        PhotoBlock,
        ToolTip,
        Gallery,
        GalleryView
    },
    props: {
        regions: Array,
        hosts: Array,
        ministries: Array,
        office_layers: Array,
        permission: Array,
        host_id: Number,
        user_role: Number,
    },
    layout: Layout,
    remember: 'form',
    setup(props) {
        const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
        const { regions, hosts, permission, ministries, office_layers, activity_types, host_id, user_role } = toRefs(props)
        const form = useForm({
            title: null,
            slug: null,
            desc: null,
            status: null,
            longitude: null,
            latitude: null,
            google_address: null,
            host_id: null,
            region_id: null,
            province_id: null,
            municipality_id: null,
            barangay_id: null,
            map_embedded: null,
            restricted: false,
            office_layer_id: null,
            office_id: null,
            ministry_id: null,
            note: null,
            email: null,
            mobile: null,
            image_path: null,
            galleries: [],
        })
        const type = ref('create')
        const title = ref('Resident')
        const url = ref('/backend/tourist-residents')
        const createUrl = ref('')

        const offices = ref([])
        const office_selected = ref(false)
        const host_selected = ref(false)
        const galleryopen = ref(false)
        const swal = inject('$swal')
        const store = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.post(`${url.value}`, { forceFormData: true, })
                    swal.fire(
                        'Added!',
                        'Your infomation has been Added.',
                        'success'
                    )
                }
            });

        }

        const pre_province_list = ref([])

        const load_province = async (req_for) => {
            let data = {
                geo_region_id: req_for == 2 ? form.region_id : form.region_id
            }
            await axios.post('../provinces/activeprovinces', data)
                .then(function (response) {
                    pre_province_list.value = response.data
                    form.province_id = null
                    form.municipality_id = null
                    form.barangay_id = null
                    if (form.province_id) load_municipality(2)
                }.bind(this));
        }
        const pre_municipality_list = ref([])
        const load_municipality = async (req_for) => {
            let data = {
                geo_provience_id: req_for == 2 ? form.province_id : form.province_id
            }
            await axios.post('../municipalities/activemunicipalities', data)
                .then(function (response) {
                    form.municipality_id = null
                    form.barangay_id = null
                    pre_municipality_list.value = response.data
                    if (form.municipality_id) load_barangay(2)
                }.bind(this));
        }

        const pre_barangay_list = ref([])
        const load_barangay = async (req_for) => {
            let data = {
                geo_municipality_id: req_for == 2 ? form.municipality_id : form.municipality_id
            }
            await axios.post('../barangays/activebarangays', data)
                .then(function (response) {
                    form.barangay_id = null
                    pre_barangay_list.value = response.data
                }.bind(this));
        }

        const get_office_data = () => {
            let data1 = {
                office_layer_id: form.office_layer_id,
                ministry_id: form.ministry_id,
                geo_region_id: form.region_id,
                geo_province_id: form.province_id,
                geo_municipality_id: form.municipality_id,
                geo_barangay_id: form.barangay_id,
            }
            axios.post(`/${backendPrefix.value}/geomapping/load-offices`, { data: data1 })
                .then(function (response) {
                    offices.value = response.data;
                    office_selected.value = true;
                })
        }

        const setHostData = () => {
            if (user_role.value != 1 && user_role.value != 10) {
                ////console.log(host_id.value);
                form.host_id = host_id.value;
                host_selected.value = true
            }
        }

        const slugBuild = () => {
            form.slug = form.title.replace(/\s/g, '-').toLowerCase()
        }

        const set_photo = (val) => {
            form.image_path = val
        }
        const update_gallery_popup = (val) => {
            galleryopen.value = val
        }
        const update_gallery = (val) => {
            form.gallery = val
        }
        const open_gallery_popup = () => {
            galleryopen.value = true
        }
        watch(() => [form.office_layer_id, form.ministry_id, form.region_id, form.province_id, form.municipality_id, form.barangay_id], () => {
            get_office_data();
        }, { deep: true })

        onMounted(() => {
            load_province(1)
            setHostData()
        })

        return {
            form,
            type,
            title,
            url,
            createUrl,
            store,
            regions,
            hosts,
            permission,
            pre_province_list,
            pre_municipality_list,
            pre_barangay_list,
            load_municipality,
            load_province,
            load_barangay,
            offices,
            ministries,
            office_layers,
            activity_types,
            get_office_data,
            office_selected,
            slugBuild,
            set_photo,
            setHostData,
            host_selected,
            galleryopen, open_gallery_popup, update_gallery_popup, update_gallery,
        }
    }
}
</script>
<style lang="scss" scoped>
$button_height: 36;

.profile_edit_content_block {
    display: block;

    .edit_form_block {
        background-color: #ffffff80;

        textarea,
        select {
            width: 100%;
            border: 1px solid #ddd;
            padding: 5px 10px;
            border-radius: 5px;
        }

        .item_block:hover>div i {
            display: flex !important;
        }

        i {
            width: 30px;
            text-align: center;
            transition: all 0.4s;
            position: absolute;
            margin-right: 0px !important;
            top: 15px;
            font-size: 10px;
            display: none;
            cursor: pointer;

            &.active {
                display: flex !important;
            }

            &.del {
                position: absolute;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #fff;
                border-radius: 50%;
                box-shadow: 0 0 5px #999;
                border: 1px solid #ddd;
                cursor: pointer;
                top: -5px;
                right: -5px;
            }
        }



        .err_msg {
            border-color: #CD0000 !important
        }

        .c-list {
            background: #666;
            color: #fff;
            border-radius: 15px;
            font-size: 11px;
            max-height: 24px;
        }
    }
}

.save_btn,
.cancel_btn {
    height: #{$button_height}px;
    background-color: #0f8050;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;

    &.cancel_btn {
        border: 1px solid #CD0000;
        color: #CD0000;
        background-color: #fff;
    }
}

.dark {
    .profile_edit_content_block {

        input,
        textarea,
        select {
            background-color: #222;
        }
    }
}

.password_block,
.confirm_password_block {
    p {
        align-items: baseline;
        color: #666;
        display: flex;
        gap: 5px;
        font-size: 10px !important;

        span {
            color: gold !important;
            font-size: 10px !important;
        }
    }

}
</style>
