<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
      <div class="max-w-lg bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="store">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.d_title" :error="form.errors.d_title" class="pb-8 pr-6 w-full lg:w-1/1" label="Decision Step Title"  required="true" placeholder="i.e Approved" />

            <div class="pb-8 pr-6 w-full lg:w-1/1">
              <Multiselect
              v-model="form.decision_step"
              mode="tags"
              placeholder="Select Decisions"
              :options="desionStep"
              :limit="20"
              :search="true"
              />
             </div>

            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Decision Step" class="mt-10 pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>
          </div>
          <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
        </form>
      </div>
    </div>
  </template>

  <script>
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import { ref, toRefs, onMounted,inject } from 'vue'
  import axios from 'axios';
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Button from '@/Shared/Button'
  import Multiselect from '@vueform/multiselect'
  export default {
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      Breadcrumbs,
      StatusToogleButton,
      Button,
      Multiselect
    },
    layout: Layout,
    remember: 'form',
    setup(){
      const form = useForm({
        d_title: null,
        d_type: null,
        decision_step:[],
        status: true
      })
      const type = ref('create')
      const title = ref('Decision Step')
      const url = ref('/backend/decisioninfos')
      const createUrl = ref('')
      const desionStep = ref([])
      const swal = inject('$swal')
      const store = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}`, {forceFormData: true,})
              swal.fire(
                'Added!',
                'Your infomation has been Added.',
                'success'
              )
            }
          });

      }

      const loadStep= async () =>{
          let data={

          }
          await axios.post('../decisionsteps/allsteps', data)
          .then(function (response) {
            //console.log(response.data);
            response.data.forEach(element => {
                let obj={
                    value: element.id, label: element.decision_title
                }
                desionStep.value.push(obj);
            });
            //desionStep.value = response.data;
          }.bind(this));
      }
      onMounted(() => {
        loadStep();
      })
      return {
        form,
        type,
        title,
        url,
        createUrl,
        desionStep,
        store,
        loadStep
      }
    }
  }
  </script>
<style scoped>
  .multiselect__content-wrapper /*, .multiselect__element */ {
    width: fit-content;
  }
</style>
